import { FormAnswers, VainuData, LTNews, NewsLeadsTarget, RestoreState,AdminUser, IndustryStatistics, Finances } from "../types/state";

type IdentityUpdate = {
    shareString: string | null,
    id: string,
    email: string,
};

export type CustomInput = {
    id: 'turnOver' | 'netIncome',
    value: number,
};

export type Action = {
    type: "SET_FORM",
    payload: FormAnswers,
} | {
    type: 'SET_VAINU_DATA',
    payload: VainuData,
} | {
    type: 'UPDATE_IDENTITY',
    payload: IdentityUpdate,
} | {
    type: 'SET_AREA_COMPANY',
    payload: { value: number | string },
} | {
    type: 'FORM_FINISHED',
    payload: { value: boolean },
} | {
    type: 'FORM_CURRENT_PAGE',
    payload: { value: number },
} | {
    type: 'LT_NEWS_FULFILLED',
    payload: LTNews[],
} | {
    type: 'GET_TARGET_GROUP_FULFILLED',
    payload: NewsLeadsTarget,
} | {
    type: 'GET_TARGET_GROUP_LEADS_FULFILLED',
    payload: any,
} | {
    type: 'PAGE_CHANGE',
    payload: { value: string },
} | {
    type: 'GET_SHARE_DATA_FULFILLED',
    payload: any,
} | {
    type: 'USE_SHORT_FORM',
} | {
    type: 'SET_TESTING',
    payload: { value: boolean },
} | {
    type: 'RESTORE_FORM_STATE',
    payload: RestoreState,
} | {
    type: 'SET_ERROR',
    payload: {value: string},
} | {
    type: 'CLEAR_ERROR'
    payload: undefined
} | {
    type: 'SET_USER'
    payload: {value: AdminUser}
} | {
    type: 'LOGOUT'
    payload: {value: undefined}
} | {
    type: 'SET_DIGITALIZATION'
    payload: {
        digitality: number | null;
        sociality: number | null;
        marketability: number | null;
    }
} | {
        type: 'SET_FINANCES'
        payload: Finances
} | {
    type: 'SET_CURRENT_YEAR'
    payload: {
        year: number
    }
} | {
    type: 'SET_MISSING_LAST_YEAR'
    payload: boolean
} | {
    type: 'SET_LAST_YEAR_PARTIAL'
    payload: boolean
}
/* | {
    type: 'UPDATE_INPUT',
    payload: CustomInput,
} */;

export const updateForm = (form: FormAnswers): Action => ({ type: 'SET_FORM', payload: form });

export const updateVainuData = (vainuData: VainuData): Action => ({ type: 'SET_VAINU_DATA', payload: vainuData });

export const updateIdentity = (identityUpdate: IdentityUpdate): Action => ({ type: 'UPDATE_IDENTITY', payload: identityUpdate });

export const setAreaCompany = (id: number | string): Action => ({ type: 'SET_AREA_COMPANY', payload: { value: id } });

export const formFinished = (isFinished: boolean): Action => ({ type: 'FORM_FINISHED', payload: { value: isFinished } });

export const setCurrentPage = (page: number): Action => ({ type: 'FORM_CURRENT_PAGE', payload: { value: page } });

export const setLTNews = (news: LTNews[]): Action => ({ type: 'LT_NEWS_FULFILLED', payload: news });

export const setTargetGroup = (target: NewsLeadsTarget): Action => ({ type: 'GET_TARGET_GROUP_FULFILLED', payload: target });

export const setTargetGroupLeads = (leads: any): Action => ({ type: 'GET_TARGET_GROUP_LEADS_FULFILLED', payload: leads });

export const pageChange = (value: string): Action => ({ type: 'PAGE_CHANGE', payload: { value: value } });

export const setShareData = (data: any | null): Action => ({ type: 'GET_SHARE_DATA_FULFILLED', payload: data });

export const setShortForm = (): Action => ({ type: 'USE_SHORT_FORM' });

export const setTesting = (value: boolean): Action => ({ type: 'SET_TESTING', payload: { value } });

export const setError = (message: string):Action => ({type : 'SET_ERROR', payload: {value : message}})

export const clearError = ():Action => ({type: 'CLEAR_ERROR', payload: undefined})

export const setUser = (_id: string, username: string, areaCompanyId: number):Action =>({type: 'SET_USER', payload:{value: {_id,username, areaCompanyId }}})

export const logout = ()=>({type: 'LOGOUT', payload: {value: undefined}})
// export const updateInput = (customInput: CustomInput): Action => ({ type: 'UPDATE_INPUT', payload: customInput });
export const restoreFormState = (data: RestoreState): Action => ({ type: 'RESTORE_FORM_STATE', payload: data });

export const setStats= (data: Finances): Action=>({ type: 'SET_FINANCES', payload: data });

export const setCurrentYear =  (year: number) =>  ({ type: 'SET_CURRENT_YEAR', payload: { year }});

export const setMissingLastYearStats = (missing: boolean) => ({ type: 'SET_MISSING_LAST_YEAR', payload: missing });

export const setMissingLastYearStatsPartial = (missing: boolean) => ({ type: 'SET_LAST_YEAR_PARTIA', payload: missing });
