import { useEffect, useState } from 'react'
import { useStateValue } from '../state/state'
import useFetchVainuData from './useFetchVainuData'
import useInitState from './useInitState'

const useNews = () => {
  const [state] = useStateValue()
  const { fetchTargetGroupLeads } = useFetchVainuData()
  const { getLTNews } = useInitState()
  const [loadingNews, setLoadingNews] = useState(false)
  const [loadingNewsLeads, setLoadingNewsLeads] = useState(false)

  const updateNews = () => {
    setLoadingNews(true)
    getLTNews()
      .then(() => setLoadingNews(false))
      .then(() => {
        if (state.identity.industryCode) {
          setLoadingNewsLeads(true)
          fetchTargetGroupLeads(state.identity.industryCode)
            .catch(console.log)
            .finally(() => setLoadingNewsLeads(false))
        }
      })
      .catch(console.log)
      .finally(() => setLoadingNews(false))
  }

  useEffect(() => {
    updateNews()
  }, [])

  return { loadingNews, loadingNewsLeads }
}

export default useNews
