import React from "react";
import { DuetFooter } from "@duetds/react";

const items = [
  /*{ label: "Hae korvausta", href: "https://www.lahitapiola.fi/henkilo/hae-korvausta/hae-korvausta", icon: "navigation-make-claim" },
  { label: "Osta vakuutus", href: "#", icon: "action-buy-insurance" },
  { label: "Yhteystiedot", href: "https://www.lahitapiola.fi/yritys/asiakaspalvelu/yhteystiedot", icon: "form-tel" },*/
  {label: 'Tutustu tarkemmin', href: "https://www.lahitapiola.fi/yritys", icon: ""}
];

const menu = [
  { label: "Turvallisuus ja käyttöehdot", href: "https://www.lahitapiola.fi/henkilo/asiakaspalvelu/asioi-verkossa/hyva-tietaa/verkkoturvallisuus" },
  { label: "Evästeet", href: "https://www.lahitapiola.fi/henkilo/asiakaspalvelu/asioi-verkossa/evasteet" },
  { label: "Henkilötietojen käsittely", href: "https://www.lahitapiola.fi/henkilo/asiakaspalvelu/asioi-verkossa/hyva-tietaa/henkilotietojen-kasittely" },
];
/*
https://www.lahitapiola.fi/henkilo/hae-korvausta/hae-korvausta
https://www.lahitapiola.fi/yritys
*/
export default function Footer() {
  return <DuetFooter className="footer" logoHref="https://www.lahitapiola.fi/yritys" language="fi" items={items} menu={menu}></DuetFooter>
}
