import React from "react";
import { DuetGrid, DuetGridItem, DuetHeading, DuetLayout, DuetSpacer } from "@duetds/react";
import { useStateValue } from "../../state/state";
import useForm from "../../hooks/useForm";
import useChartData from "../../hooks/useChartData";
import useWindowSize from "../../hooks/useWindowSize";
import { interestingEvents, risksQuestions } from "../../utils/questions";
import SalesAndProfit from "./SalesAndProfit";
import { DuetButton, DuetParagraph } from "@duetds/react/commonjs";
import Selection from './Selection';
import Contact from "./Contact";
import Staff from "./Staff";
import Footer from "../Footer";
import SalesAndProfitInput from "./SalesAndProfitInput";
import ErrorMessage from "../ErrorMessage";
import moment from "moment";

export default function Questionnaire() {
  const [state] = useStateValue();
  const { setForm, setInputEmail, setMissingVainuData, errors, handleSubmit } = useForm();
  const { turnOverDataQuestionnaire, profitDataQuestionnaire } = useChartData();
  const { isMobile } = useWindowSize();
  const currentYear = parseInt(moment().format('YYYY'))
  const lastYearMissing = state.formAnswers.currentYearMissing;
  return (
    <>
      <DuetLayout className="duet-bg-gradient" margin="none">
        <div slot="main">
          <div className="questionnaire-title-container">
            <DuetSpacer size="xx-large" />
            <DuetGrid direction="vertical" alignment="center">
              <DuetGridItem>
                <DuetHeading level="h2">
                  {state.identity.name}
                </DuetHeading>
              </DuetGridItem>
              <DuetGridItem>
                <DuetParagraph margin="none">
                  Vastaamalla alla oleviin kysymyksiin saat arvokasta tietoa yrityksestäsi.
                </DuetParagraph>
              </DuetGridItem>
            </DuetGrid>
          </div>
          <DuetSpacer size="small" />
          <div className="grid">
            {  false && (
              <SalesAndProfitInput
                title="Liikevaihto"
                value={state.formAnswers.turnOver.value ?? 0}
                setValue={(value) => {
                  setMissingVainuData({ id: 'turnOver', value });
                }}
                
                errors={errors}
              />)}
            {!state.finances.latest.netIncome && false &&(
              <SalesAndProfitInput
                title="Liiketulos"
                value={state.formAnswers.netIncome.value ?? 0}
                setValue={(value) => {
                  setMissingVainuData({ id: 'netIncome', value });
                }}
                errors={errors}
              />
            )}
          </div>
          <DuetSpacer size="large" />
          <div className="grid">
            <SalesAndProfit
              lastYearMissing={lastYearMissing}
              title="Liikevaihdon kehitys"
              currentYear={currentYear}
              series1Name="Liikevaihto"
              series2Name="Liikevaihdon kehitys"
              questionPrevious="Yrityksesi toteutunut liikevaihto edellisenä vuotena"
              question="Arvioi yrityksesi liikevaihtoa kuluvana vuonna"

              value={state.formAnswers.expectedTurnOver.calculated ?? 0}
              previousValue={state.formAnswers.lastFinances?.turnOver ?? 0}
              yaxisTitle="Liikevaihto €"
              setValue={(value) => {
                if(!value) return
                // const calculated = (1 + value / 100) * (state.finances.latest.turnOver || (state.formAnswers.turnOver.value ?? 0));
                // const changed = value //calculated !== state.finances.latest.turnOver ?? 0;
                setForm('expectedTurnOver', { percentage: 1, calculated: value, changed: true });
               
              }}
              setPreviousValue={(value)=>{
                if(!value) return
                setForm('lastFinances', {...state.formAnswers?.lastFinances, turnOver: value  })
                // setForm('lastFinances',{...state.formAnswers?.lastFinances, turnOver: value })
              }}
              data={turnOverDataQuestionnaire}
            />
            <SalesAndProfit
              lastYearMissing={lastYearMissing}
              title="Liiketuloksen kehitys"
              currentYear={currentYear}
              series1Name="Liiketulos"
              series2Name="Liiketuloksen kehitys"
              questionPrevious="Yrityksesi toteutunut liiketulos edellisenä vuotena"
              question="Arvioi yrityksesi liiketulosta kuluvana vuonna"
              value={state.formAnswers.expectedProfit.calculated ?? 0}
              previousValue={state.formAnswers.lastFinances?.netIncome ?? 0}

              yaxisTitle="Liiketulos €"
              setValue={(value) => {
                if(!value) return
                let calculated:number
                if(lastYearMissing){
                  // calculated = value // (1 + value / 100) * (state.formAnswers.lastFinances?.netIncome ?? 0);
                }else {
                  // calculated = value//(1 + value / 100) * (state.finances.latest.netIncome || (state.formAnswers.netIncome.value ?? 0));
                }
                const changed = true // calculated !== (state.finances.latest.netIncome ?? 0);
                setForm('expectedProfit', { percentage: 1, calculated: value, changed });
              }}

              setPreviousValue={(value)=>{
                if(!value) return
                
                setForm('lastFinances',{...state.formAnswers?.lastFinances, netIncome: value ?? 0})
              }}
              data={profitDataQuestionnaire}
            />
            <Staff
              lastYearMissing={lastYearMissing}
              lastYearValue={state.formAnswers.lastFinances?.staff ?? 0}
              setLastYearValue={(value)=>{
                setForm('lastFinances',{...state.formAnswers?.lastFinances, staff: value ?? 0})
              }}
              value={state.formAnswers.staff.amount ?? 0}
              setValue={(value) => {
                const changed = state.finances.latest.staff !== value;
                setForm('staff', { amount: value, changed })
              }}
            />
            <Selection
              title="Liiketoiminnan riskit"
              question="Mitä seuraavista pidät suurimpina riskeinä yritystoiminnallesi?"
              options={risksQuestions}
              checked={state.formAnswers.risks}
              setChecked={(value) => setForm('risks', value)}
              errors={errors}
            />
            <Selection
              title="Mitkä tilaisuudet kiinnostavat sinua?"
              question="LähiTapiola on mukana tilaisuuksissa, joihin kutsumme myös asiakkaita. Millaisiin tilaisuuksiin sinä toivoisit saavasi kutsun?"
              options={interestingEvents}
              checked={state.formAnswers.events}
              setChecked={(value) => setForm('events', value)}
              contactOptionChecked={state.formAnswers.wishesContact}
              // setContactOptionChecked={(value) => setForm('wishesContact', value)}
              errors={errors}
            />
            <Selection
               title="Yhteydenottopyyntö?"
               question=""
               options={[]}
               setChecked={(value) => setForm('events', value)}
               checked={state.formAnswers.events}
               contactOptionChecked={state.formAnswers.wishesContact}
               setContactOptionChecked={(value) => setForm('wishesContact', value)}
               errors={errors}
            />
            {!state.shortForm && <Contact
              email={state.identity.email ?? ''}
              setEmail={setInputEmail}
              errors={errors}
            />}
          </div>
          {errors && <>
            {!isMobile && <DuetSpacer size="medium" />}
            <ErrorMessage message="Tarkista punaisella merkityt kohdat" />
          </>}
          <DuetSpacer size="x-large" />
          <DuetButton onClick={handleSubmit} variation="primary">Näytä raportti</DuetButton>
        
        </div>
      </DuetLayout>
      <Footer />
      
    </>
  );
}