import { useStateValue } from "../state/state";
import { PieTable } from "../types/report";
import { IndustryStatistics } from "../types/state";
import { PieTitle } from "../utils/Descriptions";

const usePieData = () => {
    const [state] = useStateValue();

    const digitalizationImage = {
        desktop: {
            src: require('../images/pie-box/digitaalinen_valmius_desktop_318.png'),
            width: '318px',
        },
        mobile: {
            src: require('../images/pie-box/digitaalinen_valmius_mobiili_260.png'),
            width: '216px'
        },
        alt: 'tarinaa..',
    }

    const pieData: PieTable = {
        value: {
            fin: 'Arvo',
            value: state.finances.stats?.value ?? 0,
            unit: '€',
        },
        returnOnInvestment: {
            fin: 'Sijoitetun pääoman tuotto',
            percentage: state.finances.stats?.returnOnInvestment ?? 0,
            unit: '%',
            image: {
                desktop: {
                    src: require('../images/pie-box/sijoitetun_paaoman_tuotto_desktop_273.png'),
                    width: '273px',
                },
                mobile: {
                    src: require('../images/pie-box/sijoitetun_paaoman_tuotto_mobiili_231.png'),
                    width: '231px'
                },
                alt: 'tarinaa..',
            },
        },
        returnOnEquity: {
            fin: 'Oman pääoman tuotto',
            percentage: state.finances.stats?.returnOnEquity ?? 0,
            unit: '%',
            image: {
                desktop: {
                    src: require('../images/pie-box/oman_paaoman_tuotto_desktop_304.png'),
                    width: '304px',
                },
                mobile: {
                    src: require('../images/pie-box/oman_paaoman_tuotto_mobiili_255.png'),
                    width: '255px'
                },
                alt: 'tarinaa..',
            },
        },
        equityToAssetsRatio: {
            fin: 'Omavaraisuusaste',
            percentage: state.finances.stats?.equityToAssetsRatio ?? 0,
            unit: '%',
            image: {
                desktop: {
                    src: require('../images/pie-box/omavaraisuusaste_desktop_419.png'),
                    width: '419px',
                },
                mobile: {
                    src: require('../images/pie-box/omavaraisuusaste_mobiili_351.png'),
                    width: '280px',
                },
                alt: 'tarinaa..',
            },
        },
        liquidity: {
            fin: 'Maksuvalmius',
            percentage: state.finances.stats?.liquidity ?? 0,
            unit: '%',
        },
        digitality: {
            fin: 'Digitalisaatio',
            percentage: state.digitalization.digitality ? state.digitalization.digitality * 100 : 0,
            image: digitalizationImage,
        },
        marketability: {
            fin: 'Sisältömarkkinointi',
            percentage: state.digitalization.marketability ? state.digitalization.marketability * 100 : 0,
            image: digitalizationImage,
        },
        sociality: {
            fin: 'Sosiaalinen media',
            percentage: state.digitalization.sociality ? state.digitalization.sociality * 100 : 0,
            image: digitalizationImage,
        },
    };

    const getPieData = (title: PieTitle) => pieData[title];

    const pieTitles = Object.keys(pieData) as PieTitle[] 
    const pieTitleGroupA = pieTitles.slice(0, 5) as (keyof IndustryStatistics)[];

    const getValidPieTitlesGroupA = () => pieTitleGroupA.filter((title) => state.finances.stats && !!state.finances.stats[title]);


    return { getPieData, pieTitles, getValidPieTitlesGroupA };
};

export default usePieData;

