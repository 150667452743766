import { DuetCard, DuetLink, DuetList, DuetListItem } from '@duetds/react/commonjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';


type Props = {
    details: [label: string,value: string][]
    id: string;
    businessId: string;
}

export default function CompanyDetails({ details, businessId }:Props){

    const navigate = useNavigate();

    const  handleClick=(event:any)=>{
        event.preventDefault()
        navigate(`/admin/company/${businessId}/vainu`);
    }

    return(<DuetCard
        heading="Yrityksen tiedot"
    >
        <DuetList variation="striped">
                {details.map(([label,value]) =>(
                    <DuetListItem key={label}>
                    <span slot="label">
                    {label}
                    </span>
                    <span slot="value">
                        {value}
                    </span>
                    </DuetListItem>
                ))}
        </DuetList>
        {false && <DuetLink  onClick={handleClick} url={`/company/vainu/${businessId}`}>
            Lisätietoja yrityksestä Vainu-näkymässä
        </DuetLink>}
    </DuetCard>)
}