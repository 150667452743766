// import { colorGray, colorData04, colorWarning, colorSuccess } from "@duetds/tokens";
import _ from "lodash";
import { PieTitle } from "./Descriptions";

type Fields = {
    [key in PieTitle]: {
        min: number;
        max: number;
        color: string;
    }[] | null;
};

const digitalization = [
    { min: 0, max: 20, color: '#F68686' },
    { min: 20, max: 40, color: '#F2D17D' },
    { min: 40, max: 60, color: '#74CBE8' },
    { min: 60, max: 80, color: '#8DDFC9' },
    { min: 80, max: Number.MAX_VALUE, color: '#42BF85' }
];

const fields: Fields = {
    value: null,
    returnOnEquity: [
        { min: 0, max: 5, color: '#F68686' },
        { min: 5, max: 10, color: '#F2D17D' },
        { min: 10, max: 15, color: '#74CBE8' },
        { min: 15, max: 20, color: '#8DDFC9' },
        { min: 20, max: Number.MAX_VALUE, color: '#42BF85' }
    ],
    returnOnInvestment: [
        { min: 0, max: 3, color: '#F68686' },
        { min: 3, max: 6, color: '#F2D17D' },
        { min: 6, max: 10, color: '#74CBE8' },
        { min: 10, max: 15, color: '#8DDFC9' },
        { min: 15, max: Number.MAX_VALUE, color: '#42BF85' }
    ],
    equityToAssetsRatio: [
        { min: 0, max: 15, color: '#F68686' },
        { min: 15, max: 25, color: '#F2D17D' },
        { min: 25, max: 35, color: '#74CBE8' },
        { min: 35, max: 50, color: '#8DDFC9' },
        { min: 50, max: Number.MAX_VALUE, color: '#42BF85' }
    ],
    liquidity: [
        { min: 0, max: 30, color: '#F68686' },
        { min: 30, max: 50, color: '#F2D17D' },
        { min: 50, max: 100, color: '#74CBE8' },
        { min: 100, max: 150, color: '#8DDFC9' },
        { min: 150, max: Number.MAX_VALUE, color: '#42BF85' }
    ],
    digitality: digitalization,
    sociality: digitalization,
    marketability: digitalization,
};

export const getPieColor = (field: PieTitle, value?: number) => {
    if (!value || !field) return 'lightblue';
    if (value < 0) return 'red';
    const rule = _.find(fields[field], (rule) => rule.min <= value && value < rule.max);
    return rule?.color ?? 'lightblue';
};