import React from "react";
import { DuetCard, DuetSpacer } from "@duetds/react";
import { DuetCheckbox, DuetParagraph } from "@duetds/react/commonjs";
import ErrorMessage from "../ErrorMessage";

type Option = {
  id: string;
  label: string;
}

type Props = {
  title: string;
  question: string;
  options: Option[];
  checked: string[];
  setChecked: (arr: string[]) => void;
  contactOptionChecked?: boolean,
  setContactOptionChecked?: (checked: boolean) => void;
  errors: boolean;
}

export default function Selection({
  title, question, options, checked, setChecked,
  contactOptionChecked, setContactOptionChecked,
  errors,
}: Props) {
  const handleDuetChange = (label: string) => (event: CustomEvent<any>) => {
    if (event.detail.checked) {
      setChecked([...checked, label]);
    } else {
      setChecked(checked.filter((item) => item !== label));
    }
  };

  return (
    <DuetCard padding="large" className="questionnaire-card" margin="none" heading={title} headingLevel="h5">
      <DuetParagraph>{question}</DuetParagraph>
      {options.map(({ id, label }) => (
        <DuetCheckbox
          key={id}
          label={label}
          onDuetChange={handleDuetChange(label)}
          checked={checked.includes(label)}
        />
      ))}
      {setContactOptionChecked && (
        <div>
          <DuetSpacer size="large" />
          <DuetCheckbox
            label="Haluan, että minuun ollaan yhteydessä"
            onDuetChange={(event) => setContactOptionChecked(event.detail.checked)}
            checked={contactOptionChecked}
          />
        </div>
      )}
      {errors && checked.length === 0 && <ErrorMessage message="Valitse ainakin yksi vaihtoehto" />}
    </DuetCard>
  );
};