import React from "react";
import { DuetHeader } from "@duetds/react/commonjs";
import {useNavigate} from 'react-router-dom';
import { useStateValue } from "../../state/state";
import { logout} from "../../state/actions";
import axios from "axios";

const contactItems = [
    { label: "Lähetä viesti", href: "#" },
    { label: "Avaa chat", href: "#" },
    { label: "Yhteystiedot", href: "#" },
];

// Set label and href for session link
const session = {
    label: "Kirjaudu ulos",
    href: "/?logout",
    type: "logout",
}

  // Set label and href for user profile link

const items=[
    { label: "Yrityshaku", href: "/admin/search", },
    { label: "Suodata vastauksia", href: "/admin/filter" },
    { label: "Raportti", href: "/admin/report" },
    { label: "Kyselyn lähetys", href:"/admin/send"}
]

type Props = {
    contact?: string;
    region?: string;
}

export default function Header(props: Props) {
    const navigate = useNavigate();
    const [state, dispatch]  = useStateValue();

    const  { username } = state.user!;


    const  onDuetItemClick=(event:any)=>{
        event.detail.originalEvent.preventDefault() 
        navigate(event.detail.data.href);

    }
    const onDuetUserClick=(event:CustomEvent<any>)=>{
        event.detail.originalEvent.preventDefault() 
       
    }
    const onDuetSessionClick=(event:CustomEvent<any>)=>{
        event.detail.originalEvent.preventDefault()
        axios.get('/api/admin/logout')
        .then(()=>dispatch({type: 'LOGOUT', payload: {value: undefined}}))
    }

    const onDuetLogoCLick=(event:any ) => {
        event.detail.originalEvent.preventDefault()
    }


    return (
    <DuetHeader
        contactItems={contactItems}
        region="Syke"
        session={session}
        user={{label: username, href:''}}
        items={items}
        onDuetItemClick={onDuetItemClick}
        onDuetUserClick={onDuetUserClick}
        onDuetSessionClick={onDuetSessionClick}

        logoHref="https://lahitapiola.fi/yritys"
        {...props}
    />
    );
}