import { DuetCheckbox, DuetGrid, DuetHeading, DuetInput, DuetLink, DuetSpacer } from '@duetds/react';
import { DuetButton, DuetCard,DuetLayout, DuetParagraph, DuetSelect, DuetUpload} from '@duetds/react/commonjs';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv'
import { isValidBusinessId } from '../../utils/businessId';

import areaCompanies  from '../../utils/areaCompanies';
import axios from 'axios';
import Footer from '../Footer';
/*
const ITEMS = [

        { label: "Helsinki", value: "1"},
        { label: "Tampere", value: "2" },
        { label: "Vantaa", value: "3" },
        { label: "Espoo", value: "4" },
    ]
*/

const createToken = (email:string, businessId:string, areaCompanyId: string)=>axios.post('/api/admin/generateToken',{email, businessId, areaCompanyId, areaCompany: areaCompanyId},{withCredentials: true})
const sendLink = (email:string, businessId:string, areaCompanyId: string)=>axios.post('/api/admin/sendLinkToEmail',{email, businessId, areaCompanyId, areaCompany: areaCompanyId},{withCredentials: true})
const ITEMS = areaCompanies.map(({id, name}) =>({label: name, value: id.toString()}));

type FormState={
    email: string;
    businessId: string;
    areaCompanyId: string;
}


export default function Invitation() {
    const [files, setFile]=useState<any>(undefined)

    const [csvdata, setCsvData] = useState<string|null>(null);
    // const [csvHeaders, setCsvHeaders] = useState<Array<string[]>|null>(null);

    const [formState,setFormState]=useState<FormState>({
        email: '',
        businessId: '',
        areaCompanyId: ''
    })
    const [email,setEmail] = useState<string>('');
    const [businessId, setBusinessId]= useState<string>('');
    const [areaCompanyId, setAreaCompanyId] = useState<string>('0');

    const [fileUploadAreaCompanyId, setFileUploadAreaCompanyId] = useState<string>('0');

    const [businessIdError,setBusinessIdError]= useState<undefined|string>(undefined);


    const setInvalidBusinessIdError = ()=> setBusinessIdError('Virheellinen y-tunnus');
    const clearBusinessIdError = () =>setBusinessIdError(undefined);

    const [invitationLink, setInvitationLink] = useState<undefined|string>(undefined);

    const createLink=()=>{
        console.log('CreateLink')
        createToken(email,businessId,areaCompanyId)
            .then((result)=>{
                setInvitationLink(result.data.tokenString);
            })
        
    }
    const onFormChange=(event:any)=>{

    }

    const onEmailChange=(event:CustomEvent<any>)=>{
        setEmail(event.detail.value);
    }
    const onBusinessIdChange=(event:CustomEvent<any>)=>{
        setBusinessId(event.detail.value);
        setBusinessIdError(undefined)
    }

    const onAreaCompanyChange=(event:any)=>{
        setAreaCompanyId(event.detail.value);
    }

    const sendEmail=(event:any)=>{
        event.preventDefault()
        sendLink(email,businessId,areaCompanyId)
            .then((result)=>{
               // TODO
                console.log('email send')}
            )
            .catch((err)=>console.log('virhe', err));
    }

    const handleUpload=(event: CustomEvent<any>)=>{
        setFile(event.detail?.data?.files)
    }

    const generateLinks=async ()=>{
        const formData = new FormData();
        if(!files) return

    
       
        const k = files as Map<string,any>
       
        //Updte the formData object
       formData.append(
         'file',
         Array.from(k.values())[0].item,
        
        );

        formData.append(
            'areaCompany',
            fileUploadAreaCompanyId ?? '0'
        )
        // Request made to the backend api
        // Send formData object
         axios.post("/api/admin/generateTokens", formData, {withCredentials: true}).then(result=>{
             setCsvData(result.data);
         })
    }
    return(
        <>
        <DuetLayout className="duet-bg-gradient" margin="none">
            <div slot="main">
                <DuetHeading className="center-text"> Kyselyn lähetys</DuetHeading>
                <DuetSpacer size="large" />
                <DuetParagraph className="center-text">
                    Voit lähettää kyselyn yksittäiselle vastaanottajalle tai luoda jakolinkit massapostitusta varten
                </DuetParagraph>
                <DuetSpacer size="xxx-large" />
                <div className="grid">
                        <DuetCard heading='Lähetä kysely yksittäiselle vastaanottajalle'>
                            <form onSubmit={sendEmail}>
                                <DuetInput
                                    name="businessId"
                                    label='Y-tunnus'
                                    expand
                                    onDuetChange={onBusinessIdChange}
                                    error={businessIdError}
                                />
                                <DuetInput
                                    name="email"
                                    label="Sähköpostiosoite"
                                    type="email"
                                    expand
                                    value={email}
                                    onDuetChange={onEmailChange}
                                    
                                />
                                <DuetSelect
                                    label="Alueyhtiö"
                                    items={ITEMS} expand
                                    placeholder='Ei määritelty'
                                    onDuetChange={onAreaCompanyChange}
                                />
                                <DuetHeading level='h6' >
                                    Valitse alla oleva vaihtoehto, jos haluat testata kyselyä ennen sen
                                    lähettämistä asiakkaalle
                                </DuetHeading>
                                <DuetCheckbox name="testLink" label="Luo testilinkki" />
                                <DuetSpacer size="xxxx-large" />
                                <DuetButton
                                    variation="primary"
                                    submit
                                    >
                                        Lähetä kysely sähköpostilla
                                </DuetButton>
                                <DuetButton onClick={createLink} submit value="link" >Luo jakolinkki</DuetButton>
                            </form>
                            {invitationLink ? (
                            <>
                                <DuetSpacer size="xxxx-large" />
                                {false && <DuetInput label="Generoitu linkki" value={`http://localhost:3000/?t=${invitationLink}`} />}
                                <DuetGrid distribution="space-between">
                                <DuetLink url={`/?t=${invitationLink}`}>Generoitu linkki</DuetLink>
                                <DuetButton 
                                    onClick={() => {navigator.clipboard.writeText(`http://localhost:3000/?t=${invitationLink}`)}}
                                >Kopio Linkki</DuetButton>
                                </DuetGrid>
                            </>
                            ) : null}
                        </DuetCard>
                        <DuetCard heading='Luo jakolinkit massapostitusta varten'>
                            <form>
                            <DuetSelect
                                label="Alueyhtiö"
                                items={ITEMS}
                                expand
                                placeholder='Ei määritelty'
                                onDuetChange={(event)=>setFileUploadAreaCompanyId(event.detail.value)}
                            />
                            <DuetHeading level="h6">Lataa vastaanottajien tiedot sisältävä tiedosto</DuetHeading>
                            <DuetParagraph>Tiedoston tulee olla Excel-tiedostomuodossa</DuetParagraph>
                             <DuetUpload
                                maxFiles={1}
                                onDuetChange={handleUpload}
                                required
                                limit-selection
                                allowed-extensions="xlsx"
                                allowedMimetypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                external
                                files={files}
                                onDuetUpload={handleUpload}
                                actions={[
                                   {
                                   icon: "action-delete",
                                   color: "color-danger",
                                   size: "x-small", background: "gray-lightest",
                                   name: "delete",
                                   map: ["success", "failure"],
                                   label: { fi: "Poista tiedosto", en: "Poista tiedosto", sv: "Ta bort filen", }
                                   }]
                                }
                                descriptionDefaults={{
                                    fi:'Voit ladata xmls -muotoisen tiedoston',
                                    en:"Voit ladata xmls -muotoisen tiedoston",
                                    sv:'Voit ladata xmls -muotoisen tiedoston',
                                }}

                            >
                                <div slot="">{files? '' : 'Valitse tiedosto' }</div>
                                <div slot="header"> </div>
                                <div slot="fileheader">{files ? (Array.from(files.keys())[0]) as string : ''}</div>
                                <div slot="filefooter"></div>  
                                <DuetButton fixed id="cancel"> Cancel </DuetButton>
                            </DuetUpload>
                            {false && <DuetButton onClick={()=>setFile(new Map())}>
                                    poista
                                </DuetButton>}
                            {csvdata ?
                            <CSVLink data={csvdata}>
                            Lataa csv
                        </CSVLink>
                           


                            :
                            <DuetButton
                                disabled={!files}
                                variation="primary"
                                onClick={generateLinks}
                            >
                                    Luo jakolinkit
                            </DuetButton>
                            }
                            </form>
                        </DuetCard>
                </div>
            </div>
        </DuetLayout>
        <Footer />
        </>
    )
}
