import React from "react";
import { DuetLayout, DuetSpacer } from "@duetds/react";
import { useStateValue } from "../../state/state";
import Footer from "../Footer";
import PieCharts from "./PieCharts";
import NewsSection from "./NewsSection";
import Banner from "./Banner";
import ColumnCharts from "./ColumnCharts";
import Risks from "./Risks";
import Heading from "./Heading";

export default function Report() {
  const [state] = useStateValue();
  return (
    <>
      <DuetLayout className="duet-bg-gradient" margin="none">
        <div slot="main">
          <DuetSpacer size="xx-large" />
          <Banner />
          <Heading />
          <ColumnCharts />
          <PieCharts />
          <Risks />
          <NewsSection />
        </div>
      </DuetLayout>
      <Footer />  
    </>
  );
}