import React, { useEffect, useRef } from "react";
import { DuetCard, DuetNumberInput, DuetRangeSlider } from "@duetds/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { colorData12,colorPrimary,colorPrimaryLight,colorSecondary, fontSizeMedium, colorPrimaryDark, fontFamilyText} from "@duetds/tokens";
import { DuetRangeChangeEvent } from "@duetds/components/lib/types/components/duet-range-slider/duet-range-slider";
import { DuetParagraph } from "@duetds/react/commonjs";

type Data = {
  year: number,
  value: number,
}

type Props = {
  title: string;
  question: string;
  questionPrevious: string;
  setValue: (value: number) => void;
  setPreviousValue: (value: number) =>void;
  value: number;
  previousValue: number;
  yaxisTitle: string,
  series1Name:string,
  series2Name:string,
  data: Data[] | null;
  loading?: boolean;
  error?: string;
  currentYear: number;
  lastYearMissing: boolean
}

export default function SalesAndProfit({ title, question,questionPrevious, value,previousValue, setValue,setPreviousValue, data, yaxisTitle,series1Name, series2Name, currentYear, lastYearMissing}: Props) {
  //const currentYear = data ? data[1]?.year ? data[1].year + 1 : parseInt(moment().format('YYYY')) + 1 : '';

  const years = data ? [...data.map(({ year }) => String(year)), String(currentYear)] : [];
  const columnData = (data && data.length > 0) ? [...data.map((datum) => datum.value), value] : [];

  const options: Highcharts.Options = {
    credits:{
      enabled: false,
    },
    plotOptions:{
      column: {
        grouping: false,
        shadow: false
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: years,
      title:{
        text:'Vuosi',
        style: {
          color: colorSecondary,
          fontSize: fontSizeMedium,
          fontFamily: fontFamilyText,
        }
      },
      labels:{
        style:{
          fontFamily: fontFamilyText,
          color: colorSecondary,
          fontSize: fontSizeMedium
        }
      }
    },
    legend:{
      enabled: true,
      align: 'left',
    },
    // TODO: yAxis titles needed / style?
    yAxis: {
      showEmpty: true,
      softMax: 1 * Math.abs(Math.min(...columnData.slice(0,2))),
      opposite: false,
      title: {
        text: yaxisTitle,
        style: {
          fontSize: fontSizeMedium,
          fontFamily: fontFamilyText,
          color: colorSecondary,
        }
      },
      labels:{
        style:{
          fontFamily: fontFamilyText,
          fontSize: fontSizeMedium,
          color: colorSecondary,
        }
      }
    },
    series: [{
      type: 'column',
      name: series1Name,
      color: colorPrimary,
      data: columnData.slice(0,2),
    },
    {
      type: 'column',
      name: `${series1Name} (arvio)`,
      color: colorData12,
      data: [null,null, columnData[2]],
    },
    {
      type: 'column',
      name: series2Name,
      color: colorPrimaryDark,
      data:[ ...(lastYearMissing ? [null,null, null] : [null, null]), Math.round(columnData.slice(-1)[0])],
      showInLegend: false,
      
    }],
  };

  const options2: Highcharts.Options = {
    title: {
      text: 'Liiketulos  €',
    },
    xAxis: {
      categories: [...years],
      labels:{
        style:{
          fontFamily: fontFamilyText,
          fontSize: fontSizeMedium,
          color: colorSecondary,
        }
      }
    },
    series: [{
      type: 'column',
      name: title,
      color: colorPrimary,
      data: [10000, 10000, 20000],
    }]
  };

  const ref = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    window.customElements.whenDefined('duet-card').then(() => {
      //  console.log('duet-card is defined')
      document.querySelector('duet-card')?.componentOnReady()?.then(() => {
        if (ref !== null) {
          ref.current?.chart.reflow();
        };
      })
    })
  }, []);
  
  return (
  
    <DuetCard padding="small" className={`questionnaire-card ${data === null ? 'skeletor' : ''}`} margin="none" heading={title} headingLevel="h5">
      <HighchartsReact
        highcharts={Highcharts}
        options={data && data.length > 0 ? options : options2}
        ref={ref}
      />
    
      {lastYearMissing && <DuetParagraph size="small">{questionPrevious}</DuetParagraph>}
      {false  && <DuetRangeSlider
        expand
        label={title}
        value={value}
        onDuetChange={(event: CustomEvent<DuetRangeChangeEvent>) =>{
         setValue(event.detail.value)
        }}
        
        step={1}
        unit="€"
      //debounce={500}
      />}
      {lastYearMissing && <DuetNumberInput
        labelHidden
        expand
        unit="€"
        value={String(Number.isInteger(previousValue) ? previousValue : 0)}
        step={1}
        max={10E9}
        min={-10E9} 
        onDuetChange={(event) => {
        if(event.detail.value === null) return
          setPreviousValue(parseInt(event.detail.value, 10))
        }}
      />}

      <DuetParagraph size="small">{question}</DuetParagraph>
      {false  && <DuetRangeSlider
        expand
        label={title}
        value={value}
        onDuetChange={(event: CustomEvent<DuetRangeChangeEvent>) =>{
          if(event.detail.value === null) return
           setValue(event.detail.value)
        }}
        
        step={1}
        unit="€"
      //debounce={500}
      />}
      <DuetNumberInput
        labelHidden
        expand
        unit="€"
        max={10E9}
        min={-10E9}
        value={String(value)}
        step={1}
        onDuetChange={(event) => {
          setValue(parseInt(event.detail.value, 10))
        }}
      />
    </DuetCard>
  )
}
