export default [
    ['94999', 'Muualla luokittelemattomat muut j\u00e4rjest\u00f6t'],
    ['41200', 'Asuin- ja muiden rakennusten rakentaminen'],
    [
        '45192',
        'Matkailuvaunujen ja muualla luokittelemattomien moottoriajoneuvojen v\u00e4hitt\u00e4iskauppa'
    ],
    ['66120', 'Arvopaperien ja raaka-ainesopimusten v\u00e4litt\u00e4minen'],
    ['73111', 'Mainostoimistot'],
    ['49410', 'Tieliikenteen tavarankuljetus'],
    ['71125', 'S\u00e4hk\u00f6tekninen suunnittelu'],
    ['43342', 'Lasitus'],
    [
        '46692',
        'Teollisuudessa k\u00e4ytett\u00e4vien muiden koneiden tukkukauppa'
    ],
    ['68202', 'Asuntojen ja asuinkiinteist\u00f6jen hallinta'],
    ['27900', 'Muiden s\u00e4hk\u00f6laitteiden valmistus'],
    ['68320', 'Kiinteist\u00f6jen is\u00e4nn\u00f6inti'],
    ['55101', 'Hotellit'],
    ['68209', 'Muiden kiinteist\u00f6jen vuokraus ja hallinta'],
    ['47919', 'Muu postimyynti ja verkkokauppa'],
    [
        '66190',
        'Muu rahoitusta palveleva toiminta  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    ['46210', 'Viljan, raakatupakan, siementen ja el\u00e4inrehujen tukkukauppa'],
    ['68310', 'Kiinteist\u00f6nv\u00e4litys'],
    [
        '16290',
        'Muiden puutuotteiden valmistus; korkki-, olki- ja punontatuotteiden valmistus'
    ],
    ['32300', 'Urheiluv\u00e4lineiden valmistus'],
    ['23620', 'Kipsituotteiden valmistus rakennustarkoituksiin'],
    ['56102', 'Kahvila-ravintolat'],
    ['35120', 'S\u00e4hk\u00f6n siirto'],
    ['43120', 'Rakennuspaikan valmisteluty\u00f6t'],
    ['46499', 'Muu kotitaloustavaroiden tukkukauppa'],
    ['20120', 'V\u00e4rien ja pigmenttien valmistus'],
    ['70220', 'Muu liikkeenjohdon konsultointi'],
    ['49320', 'Taksiliikenne'],
    ['25110', 'Metallirakenteiden ja niiden osien valmistus'],
    ['62020', 'Atk-laitteisto- ja ohjelmistokonsultointi'],
    ['56101', 'Ravintolat'],
    ['25620', 'Metallien ty\u00f6st\u00f6'],
    ['01491', 'Turkistarhaus'],
    ['84110', 'Julkinen yleishallinto'],
    ['24510', 'Raudan valu'],
    ['69201', 'Kirjanpito- ja tilinp\u00e4\u00e4t\u00f6spalvelu'],
    ['45201', 'Moottoriajoneuvojen huolto ja korjaus  pl. renkaat)'],
    ['64190', 'Muu pankkitoiminta'],
    ['73200', 'Markkina- ja mielipidetutkimukset'],
    ['96021', 'Parturit ja kampaamot'],
    ['49420', 'Muuttopalvelut'],
    ['68201', 'Asuntojen vuokraus'],
    ['72192', 'Muu luonnontieteellinen tutkimus ja kehitt\u00e4minen'],
    ['25990', 'Muiden metallituotteiden valmistus'],
    ['20410', 'Saippuan, pesu-, puhdistus- ja kiillotusaineiden valmistus'],
    ['74901', 'Ohjelmatoimistot ja manageripalvelut'],
    ['71123', 'Rakennetekninen palvelu'],
    [
        '64990',
        'Muualla luokittelemattomat rahoituspalvelut  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    ['71124', 'LVI-tekninen suunnittelu'],
    ['86909', 'Muu terveyspalvelu'],
    ['74201', 'Valokuvaamot ja muu kuvaustoiminta'],
    ['36000', 'Veden otto, puhdistus ja jakelu'],
    ['02200', 'Puunkorjuu'],
    ['23120', 'Tasolasin muotoilu ja muokkaus'],
    ['81300', 'Maisemanhoitopalvelut'],
    ['93190', 'Muu urheilutoiminta'],
    ['77320', 'Rakennuskoneiden ja -laitteiden vuokraus ja leasing'],
    ['01450', 'Lampaiden ja vuohien kasvatus'],
    [
        '47410',
        'Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen v\u00e4hitt\u00e4iskauppa'
    ],
    ['47789', 'Muiden uusien tavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['63990', 'Muualla luokittelematon tietopalvelutoiminta'],
    [
        '47641',
        'Urheiluv\u00e4lineiden ja polkupy\u00f6rien v\u00e4hitt\u00e4iskauppa'
    ],
    ['33122', 'Maa- ja mets\u00e4talouskoneiden korjaus ja huolto'],
    ['85593', 'Kielikoulut ja -opistot'],
    ['46421', 'Vaatteiden tukkukauppa'],
    ['66300', 'Omaisuudenhoitotoiminta'],
    ['96022', 'Kauneudenhoitopalvelut'],
    ['30120', 'Huvi- ja urheiluveneiden rakentaminen'],
    ['43320', 'Rakennuspuusep\u00e4n asennusty\u00f6t'],
    ['93120', 'Urheiluseurojen toiminta'],
    ['43991', 'Rakennuskonevuokraus k\u00e4ytt\u00e4jineen'],
    ['55109', 'Motellit, matkustajakodit ja vastaavat majoitusliikkeet'],
    ['96012', 'Pesulapalvelut kotitalouksille'],
    ['81210', 'Kiinteist\u00f6jen siivous'],
    ['43341', 'Maalaus'],
    ['52219', 'Muu maaliikennett\u00e4 palveleva toiminta'],
    ['46699', 'Muualla luokittelemattomien koneiden ja laitteiden tukkukauppa'],
    ['43220', 'L\u00e4mp\u00f6-, vesijohto- ja ilmastointiasennus'],
    [
        '95220',
        'Kotitalouskoneiden sek\u00e4 kodin ja puutarhan laitteiden korjaus'
    ],
    ['78200', 'Ty\u00f6voiman vuokraus'],
    [
        '63110',
        'Tietojenk\u00e4sittely, palvelintilan vuokraus ja niihin liittyv\u00e4t palvelut'
    ],
    ['85530', 'Kuljettajakoulutus'],
    ['46462', 'Laboratorio- ja sairaanhoitov\u00e4lineiden tukkukauppa'],
    ['43330', 'Lattianp\u00e4\u00e4llystys ja seinien verhoilu'],
    ['46630', 'Kaivos- ja rakennuskoneiden tukkukauppa'],
    ['71110', 'Arkkitehtipalvelut'],
    ['46901', 'Yleistukkukauppa'],
    ['08920', 'Turpeen nosto'],
    ['20160', 'Muoviaineiden valmistus'],
    ['43999', 'Muu muualla luokittelematon erikoistunut rakennustoiminta'],
    [
        '62030',
        'Tietojenk\u00e4sittelyn ja laitteistojen k\u00e4ytt\u00f6- ja hallintapalvelut'
    ],
    ['46130', 'Puutavaran ja rakennusmateriaalien agentuuritoiminta'],
    ['33190', 'Muiden laitteiden korjaus ja huolto'],
    ['94910', 'Seurakunnat ja uskonnolliset j\u00e4rjest\u00f6t'],
    ['46711', 'Nestem\u00e4isten ja kaasumaisten polttoaineiden tukkukauppa'],
    ['47783', 'Optisen alan v\u00e4hitt\u00e4iskauppa'],
    ['72110', 'Biotekninen tutkimus ja kehitt\u00e4minen'],
    ['86101', 'Varsinaiset sairaalapalvelut'],
    ['69202', 'Tilintarkastuspalvelu'],
    ['88911', 'Lasten p\u00e4iv\u00e4kodit'],
    ['46493', 'Urheilualan tukkukauppa'],
    ['90030', 'Taiteellinen luominen'],
    ['43210', 'S\u00e4hk\u00f6asennus'],
    [
        '45112',
        'Henkil\u00f6autojen ja kevyiden moottoriajoneuvojen v\u00e4hitt\u00e4iskauppa'
    ],
    ['87901', 'Lasten ja nuorten laitokset ja ammatillinen perhehoito'],
    ['86230', 'Hammasl\u00e4\u00e4k\u00e4ripalvelut'],
    ['87204', 'P\u00e4ihdeongelmaisten asumispalvelut'],
    ['64920', 'Muu luotonanto'],
    ['85510', 'Urheilu- ja liikuntakoulutus'],
    ['46739', 'Rakennustarvikkeiden yleistukkukauppa'],
    [
        '13922',
        'Tavaranpeitteiden, purjeiden ja muiden sovitettujen tekstiilituotteiden valmistus'
    ],
    ['46189', 'Muualla luokittelematon erikoistunut agentuuritoiminta'],
    ['47596', 'Lukkosepp\u00e4- ja avainliikkeet'],
    ['81100', 'Kiinteist\u00f6nhoito'],
    ['50300', 'Sis\u00e4vesiliikenteen henkil\u00f6kuljetus'],
    ['33150', 'Laivojen ja veneiden korjaus ja huolto'],
    ['74300', 'K\u00e4\u00e4nt\u00e4minen ja tulkkaus'],
    ['56290', 'Henkil\u00f6st\u00f6- ja laitosruokalat'],
    ['46432', 'Viihde-elektroniikan tukkukauppa'],
    [
        '46640',
        'Tekstiiliteollisuuden koneiden sek\u00e4 ompelu- ja kutomakoneiden tukkukauppa'
    ],
    [
        '59200',
        '\u00c4\u00e4nitysstudiot; \u00e4\u00e4nitteiden ja musiikin kustantaminen'
    ],
    ['47595', 'Soittimien ja musiikkitarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    ['59110', 'Elokuvien, videoiden ja televisio-ohjelmien tuotanto'],
    [
        '45321',
        'Moottoriajoneuvojen osien ja varusteiden v\u00e4hitt\u00e4iskauppa  pl. renkaat)'
    ],
    ['16100', 'Puun sahaus, h\u00f6yl\u00e4ys ja kyll\u00e4stys'],
    ['03120', 'Sis\u00e4vesikalastus'],
    ['62010', 'Ohjelmistojen suunnittelu ja valmistus'],
    ['86901', 'Fysioterapia'],
    ['23700', 'Kiven leikkaaminen, muotoilu ja viimeistely'],
    ['47621', 'Paperi- ja toimistotarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    ['33200', 'Teollisuuden koneiden ja laitteiden ym. asennus'],
    ['18130', 'Painamista ja julkaisemista edelt\u00e4v\u00e4t palvelut'],
    ['73119', 'Muu mainospalvelu'],
    ['86902', 'Laboratoriotutkimukset'],
    ['26400', 'Viihde-elektroniikan valmistus'],
    ['47591', 'Huonekalujen v\u00e4hitt\u00e4iskauppa'],
    ['82200', 'Puhelinpalvelukeskusten toiminta'],
    ['46390', 'Elintarvikkeiden, juomien ja tupakan yleistukkukauppa'],
    ['46733', 'Metalli- ja mineraalituotteiden tukkukauppa'],
    ['56103', 'Ruokakioskit'],
    ['01290', 'Muu monivuotisten kasvien viljely'],
    ['74909', 'Muu ammatillinen, tieteellinen ja tekninen toiminta'],
    ['79900', 'Varauspalvelut, matkaoppaiden palvelut ym.'],
    ['63120', 'Verkkoportaalit'],
    ['46521', 'Viestint\u00e4laitteiden tukkukauppa'],
    ['46140', 'Koneiden ja laitteiden agentuuritoiminta'],
    ['49392', 'Linja-autojen tilausliikenne'],
    [
        '47764',
        'Lemmikkiel\u00e4inten, niiden ruokien ja tarvikkeiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['13990', 'Muualla luokittelematon tekstiilituotteiden valmistus'],
    ['96030', 'Hautaustoimistojen palvelut'],
    ['28140', 'Muiden hanojen ja venttiilien valmistus'],
    ['74109', 'Teollinen muotoilu ym.'],
    [
        '32501',
        'L\u00e4\u00e4kint\u00e4- ja hammasl\u00e4\u00e4kint\u00e4instrumenttien ja -tarvikkeiden valmistus  pl. hammasproteesit)'
    ],
    ['87301', 'Ik\u00e4\u00e4ntyneiden palveluasuminen'],
    ['47730', 'Apteekit'],
    ['43910', 'Kattorakenteiden asennus ja kattaminen'],
    ['35140', 'S\u00e4hk\u00f6n kauppa'],
    ['46741', 'Ty\u00f6kalu- ja tarviketukkukauppa'],
    [
        '47512',
        'Lankojen ja k\u00e4sity\u00f6tarvikkeiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['00000', 'Toimiala tuntematon'],
    ['47719', 'Vaatteiden yleisv\u00e4hitt\u00e4iskauppa'],
    ['85599', 'Muut koulutusta antavat yksik\u00f6t'],
    ['02100', 'Mets\u00e4nhoito'],
    ['93110', 'Urheilulaitosten toiminta'],
    ['46442', 'Puhdistusaineiden tukkukauppa'],
    ['47521', 'Rauta- ja rakennustarvikkeiden yleisv\u00e4hitt\u00e4iskauppa'],
    [
        '86220',
        'L\u00e4\u00e4k\u00e4riasemat, yksityisl\u00e4\u00e4k\u00e4rit ja vastaavat erikoisl\u00e4\u00e4k\u00e4ripalvelut'
    ],
    ['32502', 'Hammasproteesien, keinohampaiden ym. valmistus'],
    [
        '47785',
        'Lahjatavaroiden ja askartelutarvikkeiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['88999', 'Muut muualla luokittelemattomat sosiaalihuollon avopalvelut'],
    ['53200', 'Muu posti-, jakelu- ja kuriiritoiminta'],
    [
        '47990',
        'Muu v\u00e4hitt\u00e4iskauppa muualla kuin myym\u00e4l\u00f6iss\u00e4'
    ],
    ['24100', 'Raudan, ter\u00e4ksen ja rautaseosten valmistus'],
    ['58130', 'Sanomalehtien kustantaminen'],
    ['85591', 'Kansanopistot, kansalaisopistot, ty\u00f6v\u00e4enopistot yms.'],
    ['84130', 'Ty\u00f6voima- ja elinkeinoasiain hallinto'],
    ['22290', 'Muiden muovituotteiden valmistus'],
    ['71126', 'Muu rakennustekninen palvelu'],
    ['80100', 'Yksityiset turvallisuuspalvelut'],
    [
        '43291',
        'L\u00e4mp\u00f6-, \u00e4\u00e4ni- ja t\u00e4rin\u00e4eristeiden asennus'
    ],
    ['20590', 'Muualla luokittelematon kemiallisten tuotteiden valmistus'],
    ['02400', 'Mets\u00e4taloutta palveleva toiminta'],
    ['94991', 'Tutkimusta ja kulttuuria palvelevat j\u00e4rjest\u00f6t'],
    ['93130', 'Kuntokeskukset'],
    [
        '2821',
        'Teollisuusuunien, l\u00e4mmitysj\u00e4rjestelmien ja tulipes\u00e4polttimien valmistus'
    ],
    ['28300', 'Maa- ja mets\u00e4talouskoneiden valmistus'],
    ['94200', 'Ammattiyhdistysten toiminta'],
    ['49100', 'Rautateiden henkil\u00f6liikenne, kaukoliikenne'],
    ['87202', 'Mielenterveysongelmaisten asumispalvelut'],
    ['73120', 'Mainostilan vuokraus ja myynti'],
    ['71201', 'Autokatsastus'],
    ['17220', 'Paperisten talous- ja hygieniatarvikkeiden valmistus'],
    ['71129', 'Muu tekninen palvelu'],
    [
        '47113',
        'Valintamyym\u00e4l\u00e4t  yli 100 m\u00b2, enint\u00e4\u00e4n 400 m\u00b2)'
    ],
    ['47191', 'Itsepalvelutavaratalot  yli 2500 m\u00b2)'],
    ['56302', 'Kahvilat ja kahvibaarit'],
    [
        '46160',
        'Tekstiilien, vaatteiden, jalkineiden ja nahkavalmisteiden agentuuritoiminta'
    ],
    ['85520', 'Taiteen ja musiikin koulutus'],
    ['33129', 'Muiden erikoiskoneiden korjaus ja huolto'],
    ['46412', 'Tekstiilivalmisteiden tukkukauppa'],
    ['46510', 'Tietokoneiden, oheislaitteiden ja ohjelmistojen tukkukauppa'],
    ['60100', 'Radio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen'],
    ['45111', 'Henkil\u00f6autojen ja kevyiden moottoriajoneuvojen tukkukauppa'],
    ['47740', 'Terveydenhoitotarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    [
        '47114',
        'Elintarvike-, makeis- ym. kioskit  enint\u00e4\u00e4n 100 m\u00b2)'
    ],
    ['71127', 'Kone- ja prosessisuunnittelu'],
    ['46332', 'Munatukkukauppa'],
    ['41100', 'Rakennuttaminen ja rakennushankkeiden kehitt\u00e4minen'],
    ['01191', 'Koristekasvien viljely'],
    [
        '7490',
        'Muualla luokittelemattomat erikoistuneet palvelut liike-el\u00e4m\u00e4lle'
    ],
    ['01410', 'Lypsykarjan kasvatus'],
    ['82990', 'Muut palvelut liike-el\u00e4m\u00e4lle'],
    ['38210', 'Tavanomaisen j\u00e4tteen k\u00e4sittely ja loppusijoitus'],
    ['96090', 'Muualla luokittelemattomat henkil\u00f6kohtaiset palvelut'],
    ['79120', 'Matkanj\u00e4rjest\u00e4jien toiminta'],
    [
        '46610',
        'Maa- ja mets\u00e4talouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit'
    ],
    [
        '46742',
        'L\u00e4mp\u00f6-, vesi- ja ilmastointilaitteiden ja -tarvikkeiden tukkukauppa'
    ],
    ['35111', 'S\u00e4hk\u00f6n tuotanto vesi- ja tuulivoimalla'],
    [
        '45191',
        'Kuorma-autojen ja muiden raskaiden moottoriajoneuvojen tukkukauppa'
    ],
    ['93210', 'Huvi- ja teemapuistojen toiminta'],
    ['42220', 'S\u00e4hk\u00f6- ja tietoliikenneverkkojen rakentaminen'],
    ['01500', 'Yhdistetty kasvinviljely ja kotiel\u00e4intalous  sekatilat)'],
    ['01471', 'Kananmunien tuotanto'],
    ['88102', 'Ik\u00e4\u00e4ntyneiden p\u00e4iv\u00e4toiminta'],
    [
        '01110',
        'Viljakasvien  pl. riisin), palkokasvien ja \u00f6ljysiemenkasvien viljely'
    ],
    ['01131', 'Vihannesten viljely avomaalla  pl. peruna ja sokerijuurikas)'],
    ['68100', 'Omien kiinteist\u00f6jen kauppa'],
    ['47913', 'Laajan valikoiman postimyynti ja verkkokauppa'],
    ['46770', 'J\u00e4tteen ja romun tukkukauppa'],
    ['18120', 'Muu painaminen'],
    [
        '47810',
        'Elintarvikkeiden, juomien ja tupakkatuotteiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'
    ],
    [
        '35115',
        'Teollisuutta palveleva s\u00e4hk\u00f6n ja l\u00e4mm\u00f6n tuotanto'
    ],
    ['88101', 'Kotipalvelut ik\u00e4\u00e4ntyneille ja vammaisille'],
    ['69102', 'Lakiasiaintoimistot'],
    ['01132', 'Vihannesten viljely kasvihuoneessa'],
    ['59120', 'Elokuvien, video- ja televisio-ohjelmien j\u00e4lkituotanto'],
    [
        '28290',
        'Muualla luokittelematon yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen koneiden valmistus'
    ],
    [
        '47592',
        'S\u00e4hk\u00f6tarvikkeiden ja valaisimien v\u00e4hitt\u00e4iskauppa'
    ],
    ['16231', 'Puutalojen valmistus'],
    ['25610', 'Metallien k\u00e4sittely ja p\u00e4\u00e4llyst\u00e4minen'],
    ['58110', 'Kirjojen kustantaminen'],
    ['86904', 'Sairaankuljetuspalvelut'],
    ['47531', 'Mattojen ja verhojen v\u00e4hitt\u00e4iskauppa'],
    ['84250', 'Palo- ja pelastustoimi'],
    ['71121', 'Yhdyskuntasuunnittelu'],
    ['17212', 'Aaltopahvin sek\u00e4 paperi- ja kartonkipakkausten valmistus'],
    ['56210', 'Pitopalvelu'],
    ['01472', 'Broilerien tuotanto'],
    ['66220', 'Vakuutusasiamiesten ja -v\u00e4litt\u00e4jien toiminta'],
    ['75000', 'El\u00e4inl\u00e4\u00e4kint\u00e4palvelut'],
    ['03220', 'Kalanviljely sis\u00e4vesiss\u00e4'],
    [
        '29200',
        'Moottoriajoneuvojen korien valmistus; per\u00e4vaunujen ja puoliper\u00e4vaunujen valmistus'
    ],
    ['95210', 'Viihde-elektroniikan korjaus'],
    ['47762', 'Kukkakioskit'],
    ['82910', 'Perint\u00e4- ja luottotietopalvelut'],
    ['81291', 'Katujen ja teiden puhtaanapito'],
    ['58190', 'Muu kustannustoiminta'],
    ['46909', 'Muualla luokittelematon tukkukauppa'],
    ['25290', 'Muiden metallis\u00e4ili\u00f6iden ja -altaiden yms. valmistus'],
    ['55903', 'Lomam\u00f6kkien vuokraus'],
    ['46496', 'Lelujen ja pelien tukkukauppa'],
    ['52100', 'Varastointi'],
    ['46190', 'Yleisagentuuritoiminta'],
    ['35301', 'Kaukol\u00e4mm\u00f6n ja -kylm\u00e4n erillistuotanto ja jakelu'],
    [
        '47523',
        'Keitti\u00f6- ja saniteettitilojen kalusteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['69209', 'Muu laskentatoimen palvelu'],
    [
        '45311',
        'Moottoriajoneuvojen osien ja varusteiden tukkukauppa  pl. renkaat)'
    ],
    ['46620', 'Ty\u00f6st\u00f6koneiden tukkukauppa'],
    ['27510', 'S\u00e4hk\u00f6isten kodinkoneiden valmistus'],
    ['28990', 'Muualla luokittelematon erikoiskoneiden valmistus'],
    ['10850', 'Einesten ja valmisruokien valmistus'],
    ['46389', 'Muualla luokittelematon elintarvikkeiden tukkukauppa'],
    ['77310', 'Maatalouskoneiden ja -laitteiden vuokraus ja leasing'],
    ['64200', 'Rahoitusalan holdingyhti\u00f6iden toiminta'],
    ['69101', 'Asianajotoimistot'],
    [
        '72200',
        'Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehitt\u00e4minen'
    ],
    ['45322', 'Renkaiden v\u00e4hitt\u00e4iskauppa'],
    ['78300', 'Muut henkil\u00f6st\u00f6n hankintapalvelut'],
    ['82300', 'Messujen ja kongressien j\u00e4rjest\u00e4minen'],
    [
        '47911',
        'Kirjojen, musiikki- ja videotallenteiden postimyynti ja verkkokauppa'
    ],
    ['90010', 'Esitt\u00e4v\u00e4t taiteet'],
    ['6810', 'Omien kiinteist\u00f6jen kauppa'],
    ['47912', 'Vaatteiden postimyynti ja verkkokauppa'],
    ['46340', 'Alkoholi- ja muiden juomien tukkukauppa'],
    ['42110', 'Teiden ja moottoriteiden rakentaminen'],
    ['33110', 'Metallituotteiden korjaus ja huolto'],
    ['47301', 'Huoltamotoiminta'],
    ['90020', 'Esitt\u00e4vi\u00e4 taiteita palveleva toiminta'],
    ['47799', 'Muiden k\u00e4ytettyjen tavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['25730', 'Ty\u00f6kalujen valmistus'],
    [
        '33121',
        'Yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen koneiden korjaus ja huolto'
    ],
    [
        '10200',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    ['43299', 'Muualla luokittelematon rakennusasennus'],
    ['46660', 'Muiden konttorikoneiden ja -laitteiden tukkukauppa'],
    ['45403', 'Moottoripy\u00f6rien huolto ja korjaus'],
    ['27400', 'S\u00e4hk\u00f6lamppujen ja valaisimien valmistus'],
    ['46170', 'Elintarvikkeiden, juomien ja tupakan agentuuritoiminta'],
    ['70100', 'P\u00e4\u00e4konttorien toiminta'],
    ['47291', 'J\u00e4\u00e4tel\u00f6kioskit'],
    ['74102', 'Sisustussuunnittelu'],
    ['70210', 'Suhdetoiminta ja viestint\u00e4'],
    [
        '10710',
        'Leiv\u00e4n valmistus; tuoreiden leivonnaisten ja kakkujen valmistus'
    ],
    ['42130', 'Siltojen ja tunneleiden rakentaminen'],
    ['74101', 'Graafinen muotoilu'],
    ['37000', 'Viem\u00e4ri- ja j\u00e4tevesihuolto'],
    ['10910', 'Kotiel\u00e4inten rehujen valmistus'],
    ['46470', 'Huonekalujen, mattojen ja valaisimien tukkukauppa'],
    ['47529', 'Muu rauta- ja rakennusalan v\u00e4hitt\u00e4iskauppa'],
    ['01620', 'Kotiel\u00e4intaloutta palveleva toiminta'],
    ['27330', 'Kytkent\u00e4laitteiden valmistus'],
    [
        '77290',
        'Muiden henkil\u00f6kohtaisten ja kotitaloustavaroiden vuokraus ja leasing'
    ],
    ['72193', 'Tekniikan tutkimus ja kehitt\u00e4minen'],
    ['93299', 'Muualla luokittelematon huvi- ja virkistystoiminta'],
    ['22230', 'Rakennusmuovien valmistus'],
    ['38110', 'Tavanomaisen j\u00e4tteen keruu'],
    ['32999', 'Muu muualla luokittelemattomien tuotteiden valmistus'],
    ['47511', 'Kankaiden v\u00e4hitt\u00e4iskauppa'],
    ['47750', 'Kosmetiikka- ja hygieniatuotteiden v\u00e4hitt\u00e4iskauppa'],
    ['46422', 'Jalkineiden tukkukauppa'],
    [
        '88992',
        'P\u00e4iv\u00e4- ja ty\u00f6toiminta muille kuin ik\u00e4\u00e4ntyneille ja vammaisille'
    ],
    ['32120', 'Jalokivikorujen ja muiden kultasep\u00e4ntuotteiden valmistus'],
    ['49310', 'Paikallisliikenne'],
    ['77390', 'Muiden koneiden ja laitteiden vuokraus ja leasing'],
    [
        '11070',
        'Virvoitusjuomien valmistus; kivenn\u00e4isvesien ja muiden pullotettujen vesien tuotanto'
    ],
    ['47761', 'Kukkien v\u00e4hitt\u00e4iskauppa'],
    [
        '47299',
        'Muu p\u00e4ivitt\u00e4istavaroiden erikoisv\u00e4hitt\u00e4iskauppa'
    ],
    ['13300', 'Tekstiilien viimeistely'],
    [
        '42210',
        'Yleisten jakeluverkkojen rakentaminen nestem\u00e4isi\u00e4 ja kaasumaisia aineita varten'
    ],
    ['73112', 'Suora- ja ulkomainonta'],
    [
        '47599',
        'Muualla luokittelemattomien kotitaloustarvikkeiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['23610', 'Betonituotteiden valmistus rakennustarkoituksiin'],
    ['31020', 'Keitti\u00f6kalusteiden valmistus'],
    ['47770', 'Kultasep\u00e4nteosten ja kellojen v\u00e4hitt\u00e4iskauppa'],
    ['71202', 'Muu tekninen testaus ja analysointi'],
    ['46491', 'Paperi- ja toimistotarvikkeiden tukkukauppa'],
    ['47540', 'S\u00e4hk\u00f6isten kodinkoneiden v\u00e4hitt\u00e4iskauppa'],
    ['16239', 'Muu rakennuspuusep\u00e4ntuotteiden valmistus'],
    ['52240', 'Lastink\u00e4sittely'],
    [
        '46150',
        'Huonekalujen, taloustavaroiden ja rautakauppatavaroiden agentuuritoiminta'
    ],
    ['14130', 'Muu takkien, pukujen, housujen, hameiden yms. valmistus'],
    ['47711', 'Naisten vaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['10510', 'Maitotaloustuotteiden ja juuston valmistus'],
    ['15200', 'Jalkineiden valmistus'],
    ['22190', 'Muiden kumituotteiden valmistus'],
    ['01430', 'Hevosten ja muiden hevosel\u00e4inten kasvatus'],
    ['52299', 'Muu kuljetusv\u00e4litys'],
    ['79110', 'Matkatoimistojen toiminta'],
    [
        '28210',
        'Teollisuusuunien, l\u00e4mmitysj\u00e4rjestelmien ja tulipes\u00e4polttimien valmistus'
    ],
    ['08120', 'Soran, hiekan, saven ja kaoliinin otto'],
    ['4641', 'Tekstiilien tukkukauppa'],
    ['47722', 'Laukkujen v\u00e4hitt\u00e4iskauppa'],
    ['47781', 'Taideliikkeet'],
    ['25120', 'Metalliovien ja -ikkunoiden valmistus'],
    [
        '94110',
        'Elinkeinoel\u00e4m\u00e4n ja ty\u00f6nantajaj\u00e4rjest\u00f6jen toiminta'
    ],
    [
        '29310',
        'S\u00e4hk\u00f6- ja elektroniikkalaitteiden valmistus moottoriajoneuvoihin'
    ],
    ['01611', 'Kasvinviljelyn tukipalvelut'],
    ['46760', 'Muiden v\u00e4lituotteiden tukkukauppa'],
    ['46522', 'Elektronisten komponenttien tukkukauppa'],
    ['52291', 'Huolinta ja rahtaus'],
    ['10110', 'Teurastus ja lihan s\u00e4ilyvyysk\u00e4sittely  pl. siipikarja)'],
    ['92000', 'Rahapeli- ja vedonly\u00f6ntipalvelut'],
    ['26110', 'Elektronisten komponenttien valmistus'],
    ['18200', '\u00c4\u00e4ni-, kuva- ja atk-tallenteiden tuotanto'],
    ['91020', 'Museoiden toiminta'],
    ['77220', 'Videofilmien vuokraus'],
    ['4773', 'Apteekit'],
    [
        '26510',
        'Mittaus-, testaus- ja navigointiv\u00e4lineiden ja -laitteiden valmistus'
    ],
    ['81299', 'Muualla luokittelemattomat siivouspalvelut'],
    ['32200', 'Soitinten valmistus'],
    ['47292', 'Luontaistuotteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '46120',
        'Polttoaineiden, malmien, metallien ja teollisuuskemikaalien agentuuritoiminta'
    ],
    ['86903', 'Kuvantamistutkimukset'],
    ['71122', 'Maa- ja vesirakentamisen tekninen palvelu'],
    ['46750', 'Peruskemikaalien, lannoitteiden yms. tukkukauppa'],
    ['46732', 'Puutavaratuotetukkukauppa'],
    ['46433', 'Valokuvausv\u00e4lineiden ja -tarvikkeiden tukkukauppa'],
    ['47430', 'Viihde-elektroniikan v\u00e4hitt\u00e4iskauppa'],
    ['95290', 'Muiden henkil\u00f6kohtaisten ja kotitaloustavaroiden korjaus'],
    ['99000', 'Kansainv\u00e4listen organisaatioiden ja toimielinten toiminta'],
    ['82110', 'Yhdistetyt toimistopalvelut'],
    [
        '10390',
        'Muu hedelmien, marjojen ja kasvisten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    ['46441', 'Taloustavaroiden ja -tarvikkeiden tukkukauppa'],
    ['46381', 'Kalatukkukauppa'],
    ['2512', 'Metalliovien ja -ikkunoiden valmistus'],
    ['25210', 'Keskusl\u00e4mmityspatterien ja kuumavesivaraajien valmistus'],
    ['96011', 'Pesulapalvelut yrityksille'],
    [
        '47112',
        'Pienet supermarketit  yli 400 m\u00b2, enint\u00e4\u00e4n 1000 m\u00b2)'
    ],
    ['42999', 'Muu muualla luokittelematon maa- ja vesirakentaminen'],
    ['88109', 'Muut vanhusten ja vammaisten avopalvelut'],
    ['10130', 'Liha- ja siipikarjatuotteiden valmistus'],
    ['49391', 'S\u00e4\u00e4nn\u00f6llinen linja-autojen kaukoliikenne'],
    ['47721', 'Jalkineiden v\u00e4hitt\u00e4iskauppa'],
    ['55909', 'Muualla luokittelematon majoitustoiminta'],
    ['58142', 'Aikakauslehtien kustantaminen'],
    ['51102', 'Tilauslentoliikenne'],
    ['52229', 'Muu vesiliikennett\u00e4 palveleva toiminta'],
    [
        '42991',
        'Maa- ja vesirakennushankkeiden kehitt\u00e4minen ja rakennuttaminen'
    ],
    [
        '15120',
        'Matka-, k\u00e4si- ym. laukkujen, satuloiden ja valjaiden valmistus'
    ],
    ['9412', 'Ammattialaj\u00e4rjest\u00f6jen toiminta'],
    ['01700', 'Mets\u00e4stys ja sit\u00e4 palveleva toiminta'],
    ['29100', 'Moottoriajoneuvojen valmistus'],
    ['13921', 'Sisustustekstiilien valmistus'],
    ['46691', 'S\u00e4hk\u00f6tarviketukkukauppa'],
    ['82191', 'Sihteeri- ja muu toimistopalvelu'],
    ['61100', 'Langallisen verkon hallinta ja palvelut'],
    ['46734', 'Kylpyhuonekalusteiden ja -tarvikkeiden tukkukauppa'],
    ['55209', 'Lomakyl\u00e4t yms. majoitus'],
    ['50201', 'Meriliikenteen tavarankuljetus'],
    ['47791', 'Antiikkiliikkeet'],
    ['77350', 'Ilmaliikennev\u00e4lineiden vuokraus ja leasing'],
    ['77110', 'Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing'],
    ['32400', 'Pelien ja leikkikalujen valmistus'],
    ['28490', 'Muiden konety\u00f6kalujen valmistus'],
    ['52213', 'Maksullinen pys\u00e4k\u00f6inti'],
    ['26700', 'Optisten instrumenttien ja valokuvausv\u00e4lineiden valmistus'],
    ['38320', 'Lajiteltujen materiaalien kierr\u00e4tys'],
    [
        '23990',
        'Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus'
    ],
    ['47642', 'Veneiden ja veneilytarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    ['47420', 'Televiestint\u00e4laitteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '47532',
        'Tapettien ja lattianp\u00e4\u00e4llysteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['95230', 'Jalkineiden ja nahkatavaroiden korjaus'],
    ['47594', 'Taloustavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['47522', 'Maalien v\u00e4hitt\u00e4iskauppa'],
    ['46720', 'Raakametallien ja metallimalmien tukkukauppa'],
    ['69103', 'Patenttitoimistot'],
    ['14310', 'Sukkien ja sukkahousujen valmistus'],
    ['31090', 'Muiden huonekalujen valmistus'],
    ['33140', 'S\u00e4hk\u00f6laitteiden korjaus ja huolto'],
    ['47610', 'Kirjojen v\u00e4hitt\u00e4iskauppa'],
    ['61200', 'Langattoman verkon hallinta ja palvelut'],
    ['30110', 'Laivojen ja kelluvien rakenteiden rakentaminen'],
    ['47713', 'Lastenvaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['10610', 'Myllytuotteiden valmistus'],
    ['46731', 'Raakapuutukkukauppa'],
    ['43390', 'Muu rakennusten viimeistely'],
    ['45312', 'Renkaiden tukkukauppa'],
    ['85600', 'Koulutusta palveleva toiminta'],
    ['72191', 'L\u00e4\u00e4ketieteellinen tutkimus ja kehitt\u00e4minen'],
    ['8129', 'Muu siivoustoiminta'],
    [
        '46331',
        'Maitotaloustuotteiden, ravinto\u00f6ljyjen ja -rasvojen tukkukauppa'
    ],
    ['23650', 'Kuitusementin valmistus'],
    ['24440', 'Kuparin valmistus'],
    ['46461', 'L\u00e4\u00e4ketukkukauppa'],
    ['94120', 'Ammattialaj\u00e4rjest\u00f6jen toiminta'],
    ['16240', 'Puupakkausten valmistus'],
    ['87203', 'P\u00e4ihdeongelmaisten laitokset'],
    ['47242', 'Makeisten v\u00e4hitt\u00e4iskauppa'],
    ['27120', 'S\u00e4hk\u00f6njakelu- ja valvontalaitteiden valmistus'],
    ['46735', 'Lattianp\u00e4\u00e4llysteiden ja tapettien tukkukauppa'],
    [
        '35302',
        'Teollisuutta palveleva l\u00e4mm\u00f6n ja kylm\u00e4n erillistuotanto'
    ],
    ['62090', 'Muu laitteisto- ja tietotekninen palvelutoiminta'],
    ['64910', 'Rahoitusleasing'],
    ['47241', 'Leipomotuotteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '32130',
        'J\u00e4ljitelm\u00e4korujen ja muiden vastaavien tuotteiden valmistus'
    ],
    ['94920', 'Poliittiset j\u00e4rjest\u00f6t'],
    ['46450', 'Hajuvesien ja kosmetiikan tukkukauppa'],
    ['46320', 'Lihan ja lihatuotteiden tukkukauppa'],
    ['95110', 'Tietokoneiden ja niiden oheislaitteiden korjaus'],
    ['23410', 'Keraamisten talous- ja koriste-esineiden valmistus'],
    ['22220', 'Muovipakkausten valmistus'],
    ['80200', 'Turvallisuusj\u00e4rjestelm\u00e4t'],
    ['77340', 'Vesiliikennev\u00e4lineiden vuokraus ja leasing'],
    [
        '39000',
        'Maaper\u00e4n ja vesist\u00f6jen kunnostus ja muut ymp\u00e4rist\u00f6nhuoltopalvelut'
    ],
    ['23630', 'Valmisbetonin valmistus'],
    ['55902', 'Maatilamatkailu, bed & breakfast'],
    ['28920', 'Kaivos-, louhinta- ja rakennuskoneiden valmistus'],
    ['33160', 'Ilma- ja avaruusalusten korjaus ja huolto'],
    ['42910', 'Vesirakentaminen'],
    ['25710', 'Ruokailu- ja leikkuuv\u00e4lineiden yms. valmistus'],
    ['28960', 'Muovi- ja kumiteollisuuden koneiden valmistus'],
    ['87101', 'Ik\u00e4\u00e4ntyneiden hoitolaitokset'],
    ['46411', 'Kangas- ja lankatukkukauppa'],
    ['47111', 'Isot supermarketit  yli 1000 m\u00b2)'],
    ['46495', 'Veneiden ja veneilytarvikkeiden tukkukauppa'],
    ['85320', 'Keskiasteen ammatillinen koulutus'],
    ['01300', 'Taimien kasvatus ja muu kasvien lis\u00e4\u00e4minen'],
    ['95240', 'Huonekalujen ja kodin kalusteiden korjaus'],
    ['35113', 'S\u00e4hk\u00f6n ja kaukol\u00e4mm\u00f6n yhteistuotanto'],
    ['03110', 'Merikalastus'],
    ['46310', 'Juures-, vihannes- marja- ja hedelm\u00e4tukkukauppa'],
    ['56301', 'Olut- ja drinkkibaarit'],
    ['81220', 'Muu rakennus- ja teollisuussiivous'],
    ['66290', 'Muu vakuutus- ja el\u00e4kevakuutustoimintaa avustava toiminta'],
    ['47220', 'Lihan ja lihatuotteiden v\u00e4hitt\u00e4iskauppa'],
    ['01499', 'Muu el\u00e4inten hoito'],
    ['47782', 'Valokuvausalan v\u00e4hitt\u00e4iskauppa'],
    ['64300', 'Rahastotoiminta'],
    ['45202', 'Renkaiden korjaus'],
    [
        '45402',
        'Moottoripy\u00f6rien sek\u00e4 niiden osien ja varusteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['13100', 'Tekstiilikuitujen valmistelu ja kehruu'],
    ['84122', 'Terveydenhuollon ja sosiaaliturvan hallinto'],
    ['55300', 'Leirint\u00e4alueet, asuntovaunu- ja matkailuvaunualueet'],
    ['47714', 'Turkisten ja nahkavaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['46240', 'Turkisten ja nahkojen tukkukauppa'],
    ['46181', 'Paperialan agentuuritoiminta'],
    ['49399', 'Muualla luokittelematon muu maaliikenteen henkil\u00f6liikenne'],
    ['28910', 'Metallinjalostuskoneiden valmistus'],
    ['43110', 'Rakennusten ja rakennelmien purku'],
    ['52230', 'Ilmaliikennett\u00e4 palveleva toiminta'],
    [
        '28250',
        'Muuhun kuin kotitalousk\u00e4ytt\u00f6\u00f6n tarkoitettujen j\u00e4\u00e4hdytys- ja tuuletuslaitteiden valmistus'
    ],
    ['77210', 'Vapaa-ajan ja urheiluv\u00e4lineiden vuokraus ja leasing'],
    [
        '35',
        'S\u00e4hk\u00f6-, kaasu- ja l\u00e4mp\u00f6huolto, j\u00e4\u00e4hdytysliiketoiminta'
    ],
    ['47784', 'Lastenvaunujen ja -tarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    ['50400', 'Sis\u00e4vesiliikenteen tavarankuljetus'],
    ['47763', 'Puutarha-alan v\u00e4hitt\u00e4iskauppa'],
    ['84309', 'Muu pakollinen sosiaalivakuutustoiminta'],
    ['22110', 'Renkaiden valmistus ja uudelleenpinnoitus'],
    ['65129', 'Muu vahinkovakuutus'],
    ['46220', 'Kukkien ja taimien tukkukauppa'],
    ['46360', 'Sokerin, suklaan, makeisten ja leipomotuotteiden tukkukauppa'],
    ['63910', 'Uutistoimistot'],
    [
        '27110',
        'S\u00e4hk\u00f6moottorien, generaattorien ja muuntajien valmistus'
    ],
    ['85592', 'Koulutuskeskukset'],
    ['03210', 'Kalanviljely meress\u00e4'],
    [
        '2825',
        'Muuhun kuin kotitalousk\u00e4ytt\u00f6\u00f6n tarkoitettujen j\u00e4\u00e4hdytys- ja tuuletuslaitteiden valmistus'
    ],
    ['28930', 'Elintarvike-, juoma- ja tupakkateollisuuden koneiden valmistus'],
    ['10890', 'Muualla luokittelematon elintarvikkeiden valmistus'],
    ['78100', 'Ty\u00f6nv\u00e4litystoiminta'],
    ['2813', 'Pumppujen ja kompressoreiden valmistus'],
    ['28130', 'Pumppujen ja kompressoreiden valmistus'],
    ['28950', 'Paperi-, kartonki- ja pahviteollisuuden koneiden valmistus'],
    ['4663', 'Kaivos- ja rakennuskoneiden tukkukauppa'],
    ['08112', 'Kalkkikiven, kipsin, liidun ja dolomiitin louhinta'],
    ['82920', 'Pakkauspalvelut'],
    ['01462', 'Lihasikojen kasvatus'],
    [
        '01250',
        'Marjojen, p\u00e4hkin\u00f6iden ja muiden puissa ja pensaissa kasvavien hedelmien viljely'
    ],
    ['47630', 'Musiikki- ja videotallenteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '97000',
        'Kotitalouksien toiminta kotitalousty\u00f6ntekij\u00f6iden ty\u00f6nantajina'
    ],
    ['01640', 'Siementen k\u00e4sittely kasvinviljely\u00e4 varten'],
    [
        '77120',
        'Kuorma-autojen ja muiden raskaiden ajoneuvojen vuokraus ja leasing'
    ],
    ['14190', 'Muiden vaatteiden ja asusteiden valmistus'],
    ['10920', 'Lemmikkiel\u00e4inten ruokien valmistus'],
    ['85312', 'Lukiokoulutus'],
    ['65110', 'Henkivakuutustoiminta'],
    ['01612', 'Maatalousmaan pit\u00e4minen viljelykelpoisena'],
    ['88103', 'Vammaisten p\u00e4iv\u00e4- ja ty\u00f6toiminta'],
    ['46434', 'Optisen alan tukkukauppa'],
    [
        '47199',
        'Pienoistavaratalot ja muut erikoistumattomat myym\u00e4l\u00e4t  enint\u00e4\u00e4n 2500 m\u00b2)'
    ],
    [
        '47230',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten v\u00e4hitt\u00e4iskauppa'
    ],
    ['23110', 'Tasolasin valmistus'],
    ['88991', 'Kotipalvelut muille kuin ik\u00e4\u00e4ntyneille ja vammaisille'],
    ['10840', 'Mausteiden ja maustekastikkeiden valmistus'],
    ['35130', 'S\u00e4hk\u00f6n jakelu'],
    ['46431', 'Kodinkoneiden ja kodin s\u00e4hk\u00f6laitteiden tukkukauppa'],
    ['23420', 'Keraamisten saniteettikalusteiden valmistus'],
    ['69109', 'Muu lakiasiain palvelu'],
    ['51101', 'S\u00e4\u00e4nn\u00f6llinen lentoliikenne'],
    ['47593', 'Kumi- ja muovitavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['47210', 'Hedelmien, marjojen ja vihannesten v\u00e4hitt\u00e4iskauppa'],
    ['20140', 'Muiden orgaanisten peruskemikaalien valmistus'],
    ['5610', 'Ravintolat ja vastaava ravitsemistoiminta'],
    ['93291', 'Hiihto- ja laskettelukeskukset'],
    ['58290', 'Muu ohjelmistojen kustantaminen'],
    ['47260', 'Tupakkatuotteiden v\u00e4hitt\u00e4iskauppa'],
    ['6820', 'Omien tai leasing-kiinteist\u00f6jen vuokraus ja hallinta'],
    [
        '46110',
        'Maatalousraaka-aineiden, el\u00e4vien el\u00e4inten, tekstiiliraaka-aineiden sek\u00e4 puolivalmisteiden agentuuritoiminta'
    ],
    ['10310', 'Perunoiden jalostus ja s\u00e4il\u00f6nt\u00e4'],
    [
        '66110',
        'P\u00f6rssitoiminta ja rahoitusmarkkinoiden hallinnolliset tukipalvelut'
    ],
    ['85200', 'Alemman perusasteen koulutus'],
    ['42120', 'Rautateiden ja metrolinjojen rakentaminen'],
    ['32110', 'Kolikoiden ja mitalien valmistus'],
    ['59130', 'Elokuvien, videoiden ja televisio-ohjelmien levitys'],
    ['46650', 'Toimitilakalusteiden tukkukauppa'],
    ['4649', 'Muiden taloustavaroiden tukkukauppa'],
    ['61900', 'Muut televiestint\u00e4palvelut'],
    ['47890', 'Muiden tavaroiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'],
    ['46492', 'Kirjatukkukauppa'],
    ['46494', 'Musiikkitarvikkeiden tukkukauppa'],
    ['46370', 'Kahvin, teen, kaakaon ja mausteiden tukkukauppa'],
    ['28940', 'Tekstiili-, vaate- ja nahkateollisuuden koneiden valmistus'],
    ['46719', 'Muiden polttoaineiden tukkukauppa'],
    [
        '4741',
        'Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen v\u00e4hitt\u00e4iskauppa'
    ],
    ['50101', 'Meriliikenteen henkil\u00f6kuljetus'],
    ['33130', 'Elektronisten ja optisten laitteiden korjaus ja huolto'],
    ['16211', 'Vanerin ja vaneriviilun valmistus'],
    ['46382', 'Luontaistuotteiden tukkukauppa'],
    ['47650', 'Pelien ja leikkikalujen v\u00e4hitt\u00e4iskauppa'],
    ['20150', 'Lannoitteiden ja typpiyhdisteiden valmistus'],
    ['87201', 'Kehitysvammaisten laitokset ja asumispalvelut'],
    ['19200', 'Jalostettujen \u00f6ljytuotteiden valmistus'],
    [
        '24200',
        'Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus ter\u00e4ksest\u00e4'
    ],
    ['4762', 'Sanomalehtien ja paperitavaran v\u00e4hitt\u00e4iskauppa'],
    ['91010', 'Kirjastojen ja arkistojen toiminta'],
    ['24530', 'Kevytmetallien valu'],
    ['13930', 'Mattojen valmistus'],
    ['17230', 'Paperikauppatavaroiden valmistus'],
    ['28220', 'Nosto- ja siirtolaitteiden valmistus'],
    ['47792', 'Antikvariaattikauppa'],
    [
        '11010',
        'Alkoholijuomien tislaus ja sekoittaminen; etyylialkoholin valmistus k\u00e4ymisteitse'
    ],
    ['09900', 'Muuta kaivostoimintaa ja louhintaa palveleva toiminta'],
    ['14200', 'Turkisvaatteiden ja -tuotteiden valmistus'],
    ['31010', 'Konttori- ja myym\u00e4l\u00e4kalusteiden valmistus'],
    ['52212', 'Tieliikenteen terminaalitoiminta'],
    ['2592', 'Kevytmetallipakkausten valmistus'],
    ['52211', 'Linja-autoasemat'],
    ['26300', 'Viestint\u00e4laitteiden valmistus'],
    ['01420', 'Muun nautakarjan ja puhvelien kasvatus'],
    [
        '27320',
        'Muiden elektronisten ja s\u00e4hk\u00f6johtojen sek\u00e4 -kaapelien valmistus'
    ],
    ['50202', 'Rannikkovesiliikenteen tavarankuljetus'],
    ['35112', 'S\u00e4hk\u00f6n erillistuotanto l\u00e4mp\u00f6voimalla'],
    ['46480', 'Kellojen ja korujen tukkukauppa'],
    ['01133', 'Perunan viljely'],
    [
        '77330',
        'Toimistokoneiden ja -laitteiden sek\u00e4 tietokoneiden vuokraus ja leasing'
    ],
    ['46350', 'Tupakkatuotteiden tukkukauppa'],
    ['96040', 'Kylpylaitokset, saunat, solariumit yms. palvelut'],
    ['74202', 'Valokuvien kehitt\u00e4minen'],
    ['43310', 'Rappaus'],
    ['38310', 'Romujen purkaminen'],
    ['20530', 'Eteeristen \u00f6ljyjen valmistus'],
    ['0220', 'Puunkorjuu'],
    ['4333', 'Lattianp\u00e4\u00e4llystys ja seinien verhoilu'],
    ['0162', 'Kotiel\u00e4intaloutta palveleva toiminta'],
    ['85311', 'Ylemm\u00e4n perusasteen koulutus'],
    ['23910', 'Hiontatuotteiden valmistus'],
    [
        '28110',
        'Moottorien ja turbiinien valmistus  pl. lentokoneiden ja ajoneuvojen moottorit)'
    ],
    ['4772', 'Jalkineiden ja nahkatavaroiden v\u00e4hitt\u00e4iskauppa'],
    [
        '91040',
        'Kasvitieteellisten puutarhojen, el\u00e4intarhojen ja luonnonpuistojen toiminta'
    ],
    [
        '71',
        'Arkkitehti- ja insin\u00f6\u00f6ripalvelut; tekninen testaus ja analysointi'
    ],
    ['35210', 'Kaasun tuotanto'],
    ['65121', 'Vahinkovakuutusyhti\u00f6t'],
    ['13950', 'Kuitukankaiden ja kuitukangastuotteiden valmistus  pl. vaatteet)'],
    ['1399', 'Muualla luokittelematon tekstiilituotteiden valmistus'],
    ['4763', 'Musiikki- ja videotallenteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '33123',
        'Metallinty\u00f6st\u00f6koneiden ja muiden konety\u00f6kalujen korjaus ja huolto'
    ],
    ['02300', 'Luonnon tuotteiden keruu  pl. polttopuu)'],
    ['82192', 'Postituspalvelut'],
    [
        '28150',
        'Laakereiden, hammaspy\u00f6rien, vaihteisto- ja ohjauselementtien valmistus'
    ],
    ['26200', 'Tietokoneiden ja niiden oheislaitteiden valmistus'],
    ['14390', 'Muiden neulevaatteiden valmistus'],
    ['17211', 'Paperis\u00e4kkien ja -pussien valmistus'],
    ['49500', 'Putkijohtokuljetus'],
    [
        '2829',
        'Muualla luokittelematon yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen koneiden valmistus'
    ],
    ['18110', 'Sanomalehtien painaminen'],
    ['22210', 'Muovilevyjen, -kalvojen, -putkien ja -profiilien valmistus'],
    ['C', 'Teollisuus'],
    ['65122', 'Vakuutusyhdistykset'],
    ['17110', 'Massan valmistus'],
    ['18140', 'Sidonta ja siihen liittyv\u00e4t palvelut'],
    ['86102', 'Kuntoutuslaitokset ja sairaskodit'],
    ['M', 'Ammatillinen, tieteellinen ja tekninen toiminta'],
    ['20420', 'Hajuvesien ja hygieniatuotteiden valmistus'],
    ['11050', 'Oluen valmistus'],
    ['47712', 'Miesten vaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['87902', 'Ensi- ja turvakodit'],
    [
        '25500',
        'Metallin takominen, puristaminen, meist\u00e4minen ja valssaus; jauhemetallurgia'
    ],
    ['46230', 'El\u00e4vien el\u00e4inten tukkukauppa'],
    ['66210', 'Riskin- ja vahingonarviointi'],
    ['10820', 'Kaakaon, suklaan ja makeisten valmistus'],
    ['10320', 'Hedelm\u00e4-, marja- ja kasvismehujen valmistus'],
    ['43292', 'Hissien ja liukuportaiden asennus'],
    ['58120', 'Hakemistojen ja postituslistojen julkaiseminen'],
    ['20130', 'Muiden ep\u00e4orgaanisten peruskemikaalien valmistus'],
    ['0210', 'Mets\u00e4nhoito'],
    ['25930', 'Metallilankatuotteiden, ketjujen ja jousien valmistus'],
    ['4321', 'S\u00e4hk\u00f6asennus'],
    ['5914', 'Elokuvien esitt\u00e4minen'],
    ['20300', 'Maalien, lakan, painov\u00e4rien yms. valmistus'],
    [
        '4711',
        'Elintarvikkeiden, juomien ja tupakan erikoistumaton v\u00e4hitt\u00e4iskauppa'
    ],
    ['84302', 'Lakis\u00e4\u00e4teinen ty\u00f6el\u00e4kevakuutus'],
    ['01461', 'Porsastuotanto'],
    ['87909', 'Muut laitokset ja asumispalvelut'],
    ['17120', 'Paperin, kartongin ja pahvin valmistus'],
    [
        '21200',
        'L\u00e4\u00e4kkeiden ja muiden l\u00e4\u00e4kevalmisteiden valmistus'
    ],
    ['23190', 'Muu lasin valmistus ja muokkaus ml. tekniset lasituotteet'],
    ['50102', 'Rannikkovesiliikenteen henkil\u00f6kuljetus'],
    ['95250', 'Kellojen ja korujen korjaus'],
    ['53100', 'Postin yleispalvelu'],
    ['08111', 'Koriste- ja rakennuskiven louhinta'],
    [
        '60201',
        'Televisio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen  pl. maksulliset tv-kanavat)'
    ],
    ['58210', 'Tietokonepelien kustantaminen'],
    ['10520', 'J\u00e4\u00e4tel\u00f6n valmistus'],
    ['19100', 'Koksituotteiden valmistus'],
    ['35220', 'Kaasumaisten polttoaineiden jakelu putkiverkossa'],
    ['32991', 'Turvavarusteiden valmistus'],
    ['43130', 'Koeporaus'],
    ['88919', 'Muu lasten p\u00e4iv\u00e4hoito'],
    [
        '77400',
        'Henkisen omaisuuden ja vastaavien tuotteiden leasing  pl. tekij\u00e4noikeuden suojaamat teokset)'
    ],
    ['4941', 'Tieliikenteen tavarankuljetus'],
    ['9001', 'Esitt\u00e4v\u00e4t taiteet'],
    ['55201', 'Retkeilymajat'],
    [
        '25300',
        'H\u00f6yrykattiloiden valmistus  pl. keskusl\u00e4mmityslaitteet)'
    ],
    ['28410', 'Metallin ty\u00f6st\u00f6koneiden valmistus'],
    ['0149', 'Muiden el\u00e4inten kasvatus'],
    ['4511', 'Henkil\u00f6autojen ja kevyiden moottoriajoneuvojen kauppa'],
    ['6110', 'Langallisen verkon hallinta ja palvelut'],
    ['07290', 'Muiden v\u00e4rimetallimalmien louhinta'],
    ['25910', 'Metallipakkausten ja -astioiden valmistus'],
    ['59140', 'Elokuvien esitt\u00e4minen'],
    ['10120', 'Siipikarjan teurastus ja lihan s\u00e4ilyvyysk\u00e4sittely'],
    ['85420', 'Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa'],
    ['11020', 'Viinin valmistus ryp\u00e4leist\u00e4'],
    ['11030', 'Siiderin, hedelm\u00e4- ja marjaviinien valmistus'],
    ['49200', 'Rautateiden tavaraliikenne'],
    ['24410', 'Jalometallien valmistus'],
    ['51210', 'Lentoliikenteen tavarankuljetus'],
    [
        '87302',
        'Vammaisten palveluasuminen  pl. kehitysvammaisten palveluasuminen)'
    ],
    ['08990', 'Muualla luokittelematon kaivostoiminta ja louhinta'],
    ['27200', 'Paristojen ja akkujen valmistus'],
    [
        '4782',
        'Tekstiilien, vaatteiden ja jalkineiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'
    ],
    ['9523', 'Jalkineiden ja nahkatavaroiden korjaus'],
    ['47793', 'Huutokauppakamarit'],
    ['29320', 'Muiden osien ja tarvikkeiden valmistus moottoriajoneuvoihin'],
    ['88993', 'Avomuotoinen p\u00e4ihdekuntoutus'],
    ['14120', 'Ty\u00f6vaatteiden valmistus'],
    ['15110', 'Turkisten ja nahan muokkaus ja v\u00e4rj\u00e4ys'],
    ['47192', 'Tavaratalot  yli 2500 m\u00b2)'],
    ['30990', 'Muiden muualla luokittelemattomien kulkuneuvojen valmistus'],
    ['N', 'Hallinto- ja tukipalvelutoiminta'],
    ['23310', 'Keraamisten tiilien ja laattojen valmistus'],
    ['14140', 'Alusvaatteiden valmistus'],
    ['24540', 'Muiden v\u00e4rimetallien valu'],
    [
        '58141',
        'Paikallislehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen'
    ],
    ['4721', 'Hedelmien, marjojen ja vihannesten v\u00e4hitt\u00e4iskauppa'],
    ['30920', 'Polkupy\u00f6rien ja invalidiajoneuvojen valmistus'],
    [
        '45401',
        'Moottoripy\u00f6rien sek\u00e4 niiden osien ja varusteiden tukkukauppa'
    ],
    ['84231', 'Oikeudenhoito'],
    ['2822', 'Nosto- ja siirtolaitteiden valmistus'],
    ['86210', 'Terveyskeskus- ja vastaavat yleisl\u00e4\u00e4k\u00e4ripalvelut'],
    ['52221', 'Satamat'],
    ['13940', 'Purjelankojen, nuoran, sidelangan ja verkkojen valmistus'],
    ['01280', 'Mauste-, aromi-, rohdos- ja l\u00e4\u00e4kekasvien viljely'],
    ['14110', 'Nahkavaatteiden valmistus'],
    ['49', 'Maaliikenne ja putkijohtokuljetus'],
    ['90040', 'Taidelaitosten toiminta'],
    ['24420', 'Alumiinin valmistus'],
    ['13910', 'Neulosten valmistus'],
    ['47302', 'Polttoaineiden v\u00e4hitt\u00e4iskauppa automaateista'],
    [
        '26600',
        'S\u00e4teilylaitteiden sek\u00e4 elektronisten l\u00e4\u00e4kint\u00e4- ja terapialaitteiden valmistus'
    ],
    ['4774', 'Terveydenhoitotarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    ['4662', 'Ty\u00f6st\u00f6koneiden tukkukauppa'],
    ['11060', 'Maltaiden valmistus'],
    ['23640', 'Muurauslaastin valmistus'],
    ['5813', 'Sanomalehtien kustantaminen'],
    ['1102', 'Viinin valmistus ryp\u00e4leist\u00e4'],
    ['11040', 'Muiden tislaamattomien juomien valmistus k\u00e4ymisteitse'],
    ['4761', 'Kirjojen v\u00e4hitt\u00e4iskauppa'],
    [
        '47820',
        'Tekstiilien, vaatteiden ja jalkineiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'
    ],
    ['23320', 'Poltettujen tiilien ja muun rakennuskeramiikan valmistus'],
    ['F', 'Rakentaminen'],
    ['84242', 'Rajojen vartiointi ja v\u00e4est\u00f6nsuojelu'],
    ['28240', 'Voimak\u00e4ytt\u00f6isten k\u00e4sity\u00f6kalujen valmistus'],
    ['10830', 'Teen ja kahvin valmistus'],
    [
        '4759',
        'Huonekalujen, valaisimien ja muualla luokittelemattomien taloustarvikkeiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    ['28120', 'Hydraulisten voimalaitteiden valmistus'],
    ['84220', 'Maanpuolustus'],
    [
        '10410',
        'Kasvi- ja el\u00e4inper\u00e4isten \u00f6ljyjen ja -rasvojen valmistus  pl. ravintorasvat)'
    ],
    ['26120', 'Kalustettujen piirilevyjen valmistus'],
    ['9311', 'Urheilulaitosten toiminta'],
    ['35114', 'S\u00e4hk\u00f6n tuotanto ydinvoimalla'],
    ['8810', 'Vanhusten ja vammaisten sosiaalihuollon avopalvelut'],
    ['01479', 'Muu siipikarjatalous'],
    ['25400', 'Aseiden ja ammusten valmistus'],
    ['05100', 'Kivihiilen kaivu'],
    ['16213', 'Kuitulevyn valmistus'],
    ['47250', 'Alkoholi- ja muiden juomien v\u00e4hitt\u00e4iskauppa'],
    ['25720', 'Lukkojen ja saranoiden valmistus'],
    ['17290', 'Muiden paperi-, kartonki- ja pahvituotteiden valmistus'],
    ['20200', 'Torjunta-aineiden ja muiden maatalouskemikaalien valmistus'],
    ['0145', 'Lampaiden ja vuohien kasvatus'],
    ['20510', 'R\u00e4j\u00e4hdysaineiden valmistus'],
    ['6202', 'Atk-laitteisto- ja ohjelmistokonsultointi'],
    ['4771', 'Vaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['2814', 'Muiden hanojen ja venttiilien valmistus'],
    ['13960', 'Teknisten ja teollisuustekstiilien valmistus'],
    ['10860', 'Homogenoitujen ravintovalmisteiden ja dieettiruokien valmistus'],
    ['1812', 'Muu painaminen'],
    ['95120', 'Viestint\u00e4laitteiden korjaus'],
    ['23130', 'Onton lasitavaran valmistus'],
    ['4334', 'Maalaus ja lasitus'],
    ['4120', 'Asuin- ja muiden rakennusten rakentaminen'],
    ['4322', 'L\u00e4mp\u00f6-, vesijohto- ja ilmastointiasennus'],
    ['16220', 'Asennettavien parkettilevyjen valmistus'],
    ['47622', 'Aikakausjulkaisujen ja lehtien v\u00e4hitt\u00e4iskauppa'],
    [
        '2815',
        'Laakereiden, hammaspy\u00f6rien, vaihteisto- ja ohjauselementtien valmistus'
    ],
    ['2593', 'Metallilankatuotteiden, ketjujen ja jousien valmistus'],
    ['33170', 'Muiden kulkuneuvojen korjaus ja huolto'],
    ['6419', 'Muu pankkitoiminta'],
    [
        '4661',
        'Maa- ja mets\u00e4talouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit'
    ],
    ['13200', 'Kankaiden kudonta'],
    [
        '38220',
        'Ongelmaj\u00e4tteen k\u00e4sittely, loppusijoitus ja h\u00e4vitt\u00e4minen'
    ],
    ['61300', 'Satelliittiviestint\u00e4'],
    ['23690', 'Muiden betoni-, kipsi- ja sementtituotteiden valmistus'],
    ['17240', 'Tapettien valmistus'],
    [
        '01240',
        'Omenoiden, kirsikoiden, luumujen ym. kota- ja kivihedelmien viljely'
    ],
    ['81', 'Kiinteist\u00f6n- ja maisemanhoito'],
    ['9602', 'Kampaamo- ja kauneudenhoitopalvelut'],
    ['06200', 'Maakaasun tuotanto'],
    ['3811', 'Tavanomaisen j\u00e4tteen keruu'],
    ['85100', 'Esiasteen koulutus'],
    ['30910', 'Moottoripy\u00f6rien valmistus'],
    ['7022', 'Muu liikkeenjohdon konsultointi'],
    ['1520', 'Jalkineiden valmistus'],
    ['46383', 'Lemmikkiel\u00e4inten ruokien tukkukauppa'],
    ['4312', 'Rakennuspaikan valmisteluty\u00f6t'],
    ['32910', 'Luutien ja harjojen valmistus'],
    ['24520', 'Ter\u00e4ksen valu'],
    [
        '27520',
        'S\u00e4hk\u00f6ist\u00e4m\u00e4tt\u00f6mien kodinkoneiden valmistus'
    ],
    ['20520', 'Liimojen valmistus'],
    ['1811', 'Sanomalehtien painaminen'],
    ['21100', 'L\u00e4\u00e4keaineiden valmistus'],
    ['4764', 'Urheiluv\u00e4lineiden v\u00e4hitt\u00e4iskauppa'],
    ['2599', 'Muiden metallituotteiden valmistus'],
    ['4730', 'Ajoneuvojen polttoaineen v\u00e4hitt\u00e4iskauppa'],
    [
        '7112',
        'Insin\u00f6\u00f6ripalvelut ja niihin liittyv\u00e4 tekninen konsultointi'
    ],
    ['2511', 'Metallirakenteiden ja niiden osien valmistus'],
    [
        '4633',
        'Maitotaloustuotteiden, munien sek\u00e4 ravinto\u00f6ljyjen ja -rasvojen tukkukauppa'
    ],
    ['4775', 'Kosmetiikka- ja hygieniatuotteiden v\u00e4hitt\u00e4iskauppa'],
    ['38120', 'Ongelmaj\u00e4tteen keruu'],
    ['08113', 'Liuskekiven louhinta'],
    [
        '91030',
        'Historiallisten n\u00e4ht\u00e4vyyksien, rakennusten ja vastaavien kohteiden toiminta'
    ],
    ['16212', 'Lastulevyn valmistus'],
    ['84241', 'Poliisitoimi'],
    [
        '6619',
        'Muu rahoitusta palveleva toiminta  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    ['47715', 'Lakkien ja hattujen v\u00e4hitt\u00e4iskauppa'],
    ['23430', 'Keraamisten eristystuotteiden valmistus'],
    ['84121', 'Opetuksen ja kulttuuriasiain hallinto'],
    ['30300', 'Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus'],
    ['01630', 'Sadon jatkok\u00e4sittely'],
    [
        '10720',
        'N\u00e4kkileiv\u00e4n ja keksien valmistus; s\u00e4ilyvien leivonnaisten ja kakkujen valmistus'
    ],
    ['25940', 'Kiinnittimien ja ruuvituotteiden valmistus'],
    ['2824', 'Voimak\u00e4ytt\u00f6isten k\u00e4sity\u00f6kalujen valmistus'],
    ['25920', 'Kevytmetallipakkausten valmistus'],
    ['5510', 'Hotellit ja vastaavat majoitusliikkeet'],
    ['23440', 'Muiden teknisten keraamisten tuotteiden valmistus'],
    ['20110', 'Teollisuuskaasujen valmistus'],
    ['4669', 'Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa'],
    ['60202', 'Maksulliset tv-kanavat'],
    ['23520', 'Kalkin ja kipsin valmistus'],
    ['55901', 'Asuntolat ja t\u00e4ysihoitolat yms.'],
    ['1392', 'Sovitettujen tekstiilituotteiden valmistus  pl. vaatteet)'],
    ['H', 'Kuljetus ja varastointi'],
    ['4742', 'Televiestint\u00e4laitteiden v\u00e4hitt\u00e4iskauppa'],
    ['0142', 'Muun nautakarjan ja puhvelien kasvatus'],
    ['1622', 'Asennettavien parkettilevyjen valmistus'],
    ['8423', 'Oikeustoimi'],
    ['2011', 'Teollisuuskaasujen valmistus'],
    ['01199', 'Muiden yksivuotisten kasvien viljely'],
    ['4614', 'Koneiden ja laitteiden agentuuritoiminta'],
    ['20170', 'Synteettisen kumiraaka-aineen valmistus'],
    ['09100', 'Raaka\u00f6ljyn ja maakaasun tuotantoa palveleva toiminta'],
    ['30200', 'Raideliikenteen kulkuneuvojen valmistus'],
    ['68', 'Kiinteist\u00f6alan toiminta'],
    ['3211', 'Kolikoiden ja mitalien valmistus'],
    ['1103', 'Siiderin, hedelm\u00e4- ja marjaviinien valmistus'],
    ['4752', 'Rautakauppatavaran, maalien ja lasin v\u00e4hitt\u00e4iskauppa'],
    ['23510', 'Sementin valmistus'],
    ['23140', 'Lasikuitujen valmistus'],
    ['10620', 'T\u00e4rkkelyksen ja t\u00e4rkkelystuotteiden valmistus'],
    ['87102', 'Vammaisten hoitolaitokset  pl. kehitysvammaisten laitospalvelut)'],
    [
        '98200',
        'Kotitalouksien eriytt\u00e4m\u00e4t\u00f6n toiminta palvelujen tuottamiseksi omaan k\u00e4ytt\u00f6\u00f6n'
    ],
    [
        '79',
        'Matkatoimistojen ja matkanj\u00e4rjest\u00e4jien toiminta; varauspalvelut'
    ],
    ['8110', 'Kiinteist\u00f6nhoito'],
    ['8541', 'Korkea-asteen koulutus  pl. yliopistot ja ammattikorkeakoulut)'],
    ['12000', 'Tupakkatuotteiden valmistus'],
    ['9321', 'Huvi- ja teemapuistojen toiminta'],
    ['8690', 'Muut terveydenhuoltopalvelut'],
    ['24430', 'Lyijyn, sinkin ja tinan valmistus'],
    [
        '4791',
        'V\u00e4hitt\u00e4iskauppa postimyyntiliikkeiden tai Internetin v\u00e4lityksell\u00e4'
    ],
    ['7820', 'Ty\u00f6voiman vuokraus'],
    ['2591', 'Metallipakkausten ja -astioiden valmistus'],
    [
        '2399',
        'Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus'
    ],
    [
        '10730',
        'Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus'
    ],
    ['80', 'Turvallisuus-, vartiointi- ja etsiv\u00e4palvelut'],
    ['4778', 'Muu uusien tavaroiden v\u00e4hitt\u00e4iskauppa'],
    [
        '28230',
        'Konttorikoneiden ja -laitteiden valmistus  pl. tietokoneet ja niiden oheislaitteet)'
    ],
    ['8121', 'Kiinteist\u00f6jen siivous'],
    ['10810', 'Sokerin valmistus'],
    ['27310', 'Optisten kuitukaapelien valmistus'],
    ['24310', 'Raudan ja ter\u00e4ksen kylm\u00e4vet\u00e4minen'],
    ['65300', 'El\u00e4kevakuutustoiminta'],
    [
        '4532',
        'Moottoriajoneuvojen osien ja varusteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['9329', 'Muu huvi- ja virkistystoiminta'],
    [
        '4520',
        'Moottoriajoneuvojen huolto ja korjaus  pl. moottoripy\u00f6r\u00e4t)'
    ],
    ['1610', 'Puun sahaus, h\u00f6yl\u00e4ys ja kyll\u00e4stys'],
    ['6910', 'Lakiasiainpalvelut'],
    ['7420', 'Valokuvaustoiminta'],
    ['86', 'Terveyspalvelut'],
    [
        '2420',
        'Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus ter\u00e4ksest\u00e4'
    ],
    ['8542', 'Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa'],
    ['8510', 'Esiasteen koulutus'],
    [
        '1073',
        'Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus'
    ],
    ['0146', 'Sikojen kasvatus'],
    ['9609', 'Muualla luokittelemattomat henkil\u00f6kohtaiset palvelut'],
    [
        '4799',
        'Muu v\u00e4hitt\u00e4iskauppa muualla kuin myym\u00e4l\u00f6iss\u00e4'
    ],
    ['4676', 'Muiden v\u00e4lituotteiden tukkukauppa'],
    ['23200', 'Tulenkest\u00e4vien keraamisten tuotteiden valmistus'],
    ['07100', 'Rautamalmien louhinta'],
    ['84301', 'Kansanel\u00e4kelaitoksen vakuutustoiminta'],
    [
        '2823',
        'Konttorikoneiden ja -laitteiden valmistus  pl. tietokoneet ja niiden oheislaitteet)'
    ],
    ['3011', 'Laivojen ja kelluvien rakenteiden rakentaminen'],
    ['26520', 'Kellojen valmistus'],
    ['9511', 'Tietokoneiden ja niiden oheislaitteiden korjaus'],
    [
        '1020',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    ['9102', 'Museoiden toiminta'],
    ['0161', 'Kasvinviljely\u00e4 palveleva toiminta'],
    ['3512', 'S\u00e4hk\u00f6n siirto'],
    ['3511', 'S\u00e4hk\u00f6n tuotanto'],
    [
        '8720',
        'Kehitysvammaisten sek\u00e4 mielenterveys- ja p\u00e4ihdeongelmaisten asumispalvelut'
    ],
    ['9312', 'Urheiluseurojen toiminta'],
    ['3012', 'Huvi- ja urheiluveneiden rakentaminen'],
    ['9524', 'Huonekalujen ja kodin kalusteiden korjaus'],
    ['8891', 'Lasten p\u00e4iv\u00e4hoitopalvelut'],
    ['7410', 'Taideteollinen muotoilu ja suunnittelu'],
    ['31030', 'Patjojen valmistus'],
    ['2013', 'Muiden ep\u00e4orgaanisten peruskemikaalien valmistus'],
    ['5040', 'Sis\u00e4vesiliikenteen tavarankuljetus'],
    ['3315', 'Laivojen ja veneiden korjaus ja huolto'],
    ['08910', 'Kemiallisten ja lannoitemineraalien louhinta'],
    ['01492', 'Poronhoito'],
    [
        '4779',
        'K\u00e4ytettyjen tavaroiden v\u00e4hitt\u00e4iskauppa myym\u00e4l\u00f6iss\u00e4'
    ],
    [
        '46',
        'Tukkukauppa  pl. moottoriajoneuvojen ja moottoripy\u00f6rien kauppa)'
    ],
    ['5621', 'Pitopalvelu'],
    ['35230', 'Kaasun kauppa putkiverkossa'],
    ['6201', 'Ohjelmistojen suunnittelu ja valmistus'],
    ['24330', 'Kylm\u00e4muovaus tai kylm\u00e4taitto'],
    ['84232', 'Vankeinhoito'],
    ['23490', 'Muiden keramiikkatuotteiden valmistus'],
    ['2012', 'V\u00e4rien ja pigmenttien valmistus'],
    ['1393', 'Mattojen valmistus'],
    ['7010', 'P\u00e4\u00e4konttorien toiminta'],
    ['4729', 'Muu v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'],
    ['3320', 'Teollisuuden koneiden ja laitteiden ym. asennus'],
    [
        'G',
        'Tukku- ja v\u00e4hitt\u00e4iskauppa; moottoriajoneuvojen ja moottoripy\u00f6rien korjaus'
    ],
    [
        '7740',
        'Henkisen omaisuuden ja vastaavien tuotteiden leasing  pl. tekij\u00e4noikeuden suojaamat teokset)'
    ],
    ['41', 'Talonrakentaminen'],
    ['70', 'P\u00e4\u00e4konttorien toiminta; liikkeenjohdon konsultointi'],
    ['01120', 'Riisin viljely'],
    ['80300', 'Etsiv\u00e4toiminta'],
    [
        '1071',
        'Leiv\u00e4n valmistus; tuoreiden leivonnaisten ja kakkujen valmistus'
    ],
    ['01440', 'Kamelien ja kameliel\u00e4inten kasvatus'],
    ['8230', 'Messujen ja kongressien j\u00e4rjest\u00e4minen'],
    ['2432', 'Rainan kylm\u00e4valssaus'],
    ['9499', 'Muut j\u00e4rjest\u00f6t'],
    ['4617', 'Elintarvikkeiden, juomien ja tupakan agentuuritoiminta'],
    ['01160', 'Kuitukasvien viljely'],
    ['10420', 'Margariinin ja sen kaltaisten ravintorasvojen valmistus'],
    ['6629', 'Muu vakuutus- ja el\u00e4kevakuutustoimintaa avustava toiminta'],
    ['20600', 'Tekokuitujen valmistus'],
    [
        '6311',
        'Tietojenk\u00e4sittely, palvelintilan vuokraus ja niihin liittyv\u00e4t palvelut'
    ],
    ['64110', 'Keskuspankkitoiminta'],
    ['5630', 'Baarit ja kahvilat'],
    ['4652', 'Elektroniikka- ja viestint\u00e4laitteiden ja osien tukkukauppa'],
    ['24450', 'Muiden v\u00e4rimetallien valmistus'],
    ['7732', 'Rakennuskoneiden ja -laitteiden vuokraus ja leasing'],
    ['4110', 'Rakennuttaminen ja rakennushankkeiden kehitt\u00e4minen'],
    [
        '5920',
        '\u00c4\u00e4nitysstudiot; \u00e4\u00e4nitteiden ja musiikin kustantaminen'
    ],
    ['7711', 'Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing'],
    [
        '4753',
        'Mattojen, tapettien ja lattianp\u00e4\u00e4llysteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['0150', 'Yhdistetty kasvinviljely ja kotiel\u00e4intalous  sekatilat)'],
    ['2620', 'Tietokoneiden ja niiden oheislaitteiden valmistus'],
    ['7430', 'K\u00e4\u00e4nt\u00e4minen ja tulkkaus'],
    ['1412', 'Ty\u00f6vaatteiden valmistus'],
    ['1623', 'Muiden rakennuspuusep\u00e4ntuotteiden valmistus'],
    ['6420', 'Rahoitusalan holdingyhti\u00f6iden toiminta'],
    ['65200', 'J\u00e4lleenvakuutustoiminta'],
    ['7120', 'Tekninen testaus ja analysointi'],
    [
        '6203',
        'Tietojenk\u00e4sittelyn ja laitteistojen k\u00e4ytt\u00f6- ja hallintapalvelut'
    ],
    ['9521', 'Viihde-elektroniikan korjaus'],
    [
        '8622',
        'L\u00e4\u00e4k\u00e4riasemat, yksityisl\u00e4\u00e4k\u00e4rit ja vastaavat erikoisl\u00e4\u00e4k\u00e4ripalvelut'
    ],
    ['73', 'Mainostoiminta ja markkinatutkimus'],
    ['Q', 'Terveys- ja sosiaalipalvelut'],
    ['9002', 'Esitt\u00e4vi\u00e4 taiteita palveleva toiminta'],
    ['94', 'J\u00e4rjest\u00f6jen toiminta'],
    ['949', 'Muiden j\u00e4rjest\u00f6jen toiminta'],
    ['9499', 'Muut j\u00e4rjest\u00f6t'],
    ['41', 'Talonrakentaminen'],
    ['412', 'Asuin- ja muiden rakennusten rakentaminen'],
    ['4120', 'Asuin- ja muiden rakennusten rakentaminen'],
    [
        '45',
        'Moottoriajoneuvojen ja moottoripy\u00f6rien tukku- ja v\u00e4hitt\u00e4iskauppa sek\u00e4 korjaus'
    ],
    ['451', 'Moottoriajoneuvojen kauppa'],
    ['4519', 'Muiden moottoriajoneuvojen myynti'],
    ['66', 'Rahoitusta ja vakuuttamista palveleva toiminta'],
    [
        '661',
        'Rahoitusta ja vakuuttamista palveleva toiminta  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    ['6612', 'Arvopaperien ja raaka-ainesopimusten v\u00e4litt\u00e4minen'],
    ['73', 'Mainostoiminta ja markkinatutkimus'],
    ['731', 'Mainostoiminta'],
    ['7311', 'Mainostoimistot ja mainospalvelu'],
    ['49', 'Maaliikenne ja putkijohtokuljetus'],
    ['494', 'Tieliikenteen tavarankuljetus ja muuttopalvelut'],
    ['4941', 'Tieliikenteen tavarankuljetus'],
    [
        '71',
        'Arkkitehti- ja insin\u00f6\u00f6ripalvelut; tekninen testaus ja analysointi'
    ],
    [
        '711',
        'Arkkitehti- ja insin\u00f6\u00f6ripalvelut ja niihin liittyv\u00e4 tekninen konsultointi'
    ],
    [
        '7112',
        'Insin\u00f6\u00f6ripalvelut ja niihin liittyv\u00e4 tekninen konsultointi'
    ],
    ['43', 'Erikoistunut rakennustoiminta'],
    ['433', 'Rakennusten ja rakennelmien viimeistely'],
    ['4334', 'Maalaus ja lasitus'],
    [
        '46',
        'Tukkukauppa  pl. moottoriajoneuvojen ja moottoripy\u00f6rien kauppa)'
    ],
    ['466', 'Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa'],
    ['4669', 'Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa'],
    ['68', 'Kiinteist\u00f6alan toiminta'],
    ['682', 'Omien tai leasing-kiinteist\u00f6jen vuokraus ja hallinta'],
    ['6820', 'Omien tai leasing-kiinteist\u00f6jen vuokraus ja hallinta'],
    ['27', 'S\u00e4hk\u00f6laitteiden valmistus'],
    ['279', 'Muiden s\u00e4hk\u00f6laitteiden valmistus'],
    ['2790', 'Muiden s\u00e4hk\u00f6laitteiden valmistus'],
    ['683', 'Kiinteist\u00f6alan toiminta palkkio- tai sopimusperusteisesti'],
    ['6832', 'Kiinteist\u00f6jen is\u00e4nn\u00f6inti'],
    ['55', 'Majoitus'],
    ['551', 'Hotellit ja vastaavat majoitusliikkeet'],
    ['5510', 'Hotellit ja vastaavat majoitusliikkeet'],
    [
        '47',
        'V\u00e4hitt\u00e4iskauppa  pl. moottoriajoneuvojen ja moottoripy\u00f6rien kauppa)'
    ],
    [
        '479',
        'V\u00e4hitt\u00e4iskauppa muualla kuin myym\u00e4l\u00f6iss\u00e4  pl. tori- ja markkinakauppa)'
    ],
    [
        '4791',
        'V\u00e4hitt\u00e4iskauppa postimyyntiliikkeiden tai Internetin v\u00e4lityksell\u00e4'
    ],
    [
        '6619',
        'Muu rahoitusta palveleva toiminta  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    [
        '462',
        'Maatalousper\u00e4isten raaka-aineiden ja el\u00e4vien el\u00e4inten tukkukauppa'
    ],
    ['4621', 'Viljan, raakatupakan, siementen ja el\u00e4inrehujen tukkukauppa'],
    ['6831', 'Kiinteist\u00f6nv\u00e4litys'],
    [
        '16',
        'Sahatavaran sek\u00e4 puu- ja korkkituotteiden valmistus  pl. huonekalut); olki- ja punontatuotteiden valmistus'
    ],
    ['162', 'Puu-, korkki-, olki- ja punontatuotteiden valmistus'],
    [
        '1629',
        'Muiden puutuotteiden valmistus; korkki-, olki- ja punontatuotteiden valmistus'
    ],
    ['32', 'Muu valmistus'],
    ['323', 'Urheiluv\u00e4lineiden valmistus'],
    ['3230', 'Urheiluv\u00e4lineiden valmistus'],
    ['23', 'Muiden ei-metallisten mineraalituotteiden valmistus'],
    ['236', 'Betoni-, kipsi- ja sementtituotteiden valmistus'],
    ['2362', 'Kipsituotteiden valmistus rakennustarkoituksiin'],
    ['56', 'Ravitsemistoiminta'],
    ['561', 'Ravintolat ja vastaava ravitsemistoiminta'],
    ['5610', 'Ravintolat ja vastaava ravitsemistoiminta'],
    [
        '35',
        'S\u00e4hk\u00f6-, kaasu- ja l\u00e4mp\u00f6huolto, j\u00e4\u00e4hdytysliiketoiminta'
    ],
    ['351', 'S\u00e4hk\u00f6voiman tuotanto, siirto ja jakelu'],
    ['3512', 'S\u00e4hk\u00f6n siirto'],
    [
        '431',
        'Rakennusten ja rakennelmien purku ja rakennuspaikan valmisteluty\u00f6t'
    ],
    ['4312', 'Rakennuspaikan valmisteluty\u00f6t'],
    ['464', 'Taloustavaroiden tukkukauppa'],
    ['4649', 'Muiden taloustavaroiden tukkukauppa'],
    ['20', 'Kemikaalien ja kemiallisten tuotteiden valmistus'],
    [
        '201',
        'Peruskemikaalien, lannoitteiden ja typpiyhdisteiden, muoviaineiden ja synteettisen kumiraaka-aineen valmistus'
    ],
    ['2012', 'V\u00e4rien ja pigmenttien valmistus'],
    ['70', 'P\u00e4\u00e4konttorien toiminta; liikkeenjohdon konsultointi'],
    ['702', 'Liikkeenjohdon konsultointi'],
    ['7022', 'Muu liikkeenjohdon konsultointi'],
    ['493', 'Muu maaliikenteen henkil\u00f6liikenne'],
    ['4932', 'Taksiliikenne'],
    ['25', 'Metallituotteiden valmistus  pl. koneet ja laitteet)'],
    ['251', 'Metallirakenteiden valmistus'],
    ['2511', 'Metallirakenteiden ja niiden osien valmistus'],
    ['62', 'Ohjelmistot, konsultointi ja siihen liittyv\u00e4 toiminta'],
    ['620', 'Ohjelmistot, konsultointi ja siihen liittyv\u00e4 toiminta'],
    ['6202', 'Atk-laitteisto- ja ohjelmistokonsultointi'],
    [
        '256',
        'Metallien k\u00e4sittely, p\u00e4\u00e4llyst\u00e4minen ja ty\u00f6st\u00f6'
    ],
    ['2562', 'Metallien ty\u00f6st\u00f6'],
    [
        '01',
        'Kasvinviljely ja kotiel\u00e4intalous, riistatalous ja niihin liittyv\u00e4t palvelut'
    ],
    ['014', 'Kotiel\u00e4intalous'],
    ['0149', 'Muiden el\u00e4inten kasvatus'],
    ['84', 'Julkinen hallinto ja maanpuolustus; pakollinen sosiaalivakuutus'],
    ['841', 'Julkinen hallinto'],
    ['8411', 'Julkinen yleishallinto'],
    ['24', 'Metallien jalostus'],
    ['245', 'Metallien valu'],
    ['2451', 'Raudan valu'],
    ['69', 'Lakiasiain- ja laskentatoimen palvelut'],
    ['692', 'Laskentatoimi, kirjanpito ja tilintarkastus; veroneuvonta'],
    ['6920', 'Laskentatoimi, kirjanpito ja tilintarkastus; veroneuvonta'],
    [
        '452',
        'Moottoriajoneuvojen huolto ja korjaus  pl. moottoripy\u00f6r\u00e4t)'
    ],
    [
        '4520',
        'Moottoriajoneuvojen huolto ja korjaus  pl. moottoripy\u00f6r\u00e4t)'
    ],
    ['64', 'Rahoituspalvelut  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'],
    ['641', 'Pankkitoiminta'],
    ['6419', 'Muu pankkitoiminta'],
    ['732', 'Markkina- ja mielipidetutkimukset'],
    ['7320', 'Markkina- ja mielipidetutkimukset'],
    ['96', 'Muut henkil\u00f6kohtaiset palvelut'],
    ['960', 'Muut henkil\u00f6kohtaiset palvelut'],
    ['9602', 'Kampaamo- ja kauneudenhoitopalvelut'],
    ['4942', 'Muuttopalvelut'],
    ['72', 'Tieteellinen tutkimus ja kehitt\u00e4minen'],
    ['721', 'Luonnontieteen ja tekniikan tutkimus ja kehitt\u00e4minen'],
    ['7219', 'Muu luonnontieteellinen ja tekninen tutkimus ja kehitt\u00e4minen'],
    ['259', 'Muu metallituotteiden valmistus'],
    ['2599', 'Muiden metallituotteiden valmistus'],
    [
        '204',
        'Saippuan, pesu-, puhdistus- ja kiillotusaineiden; hajuvesien ja hygieniatuotteiden valmistus'
    ],
    ['2041', 'Saippuan, pesu-, puhdistus- ja kiillotusaineiden valmistus'],
    ['74', 'Muut erikoistuneet palvelut liike-el\u00e4m\u00e4lle'],
    [
        '749',
        'Muualla luokittelemattomat erikoistuneet palvelut liike-el\u00e4m\u00e4lle'
    ],
    [
        '7490',
        'Muualla luokittelemattomat erikoistuneet palvelut liike-el\u00e4m\u00e4lle'
    ],
    [
        '649',
        'Muut rahoituspalvelut  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    [
        '6499',
        'Muualla luokittelemattomat rahoituspalvelut  pl. vakuutus- ja el\u00e4kevakuutustoiminta)'
    ],
    ['86', 'Terveyspalvelut'],
    ['869', 'Muut terveydenhuoltopalvelut'],
    ['8690', 'Muut terveydenhuoltopalvelut'],
    ['742', 'Valokuvaustoiminta'],
    ['7420', 'Valokuvaustoiminta'],
    ['36', 'Veden otto, puhdistus ja jakelu'],
    ['360', 'Veden otto, puhdistus ja jakelu'],
    ['3600', 'Veden otto, puhdistus ja jakelu'],
    ['02', 'Mets\u00e4talous ja puunkorjuu'],
    ['022', 'Puunkorjuu'],
    ['0220', 'Puunkorjuu'],
    ['231', 'Lasin ja lasituotteiden valmistus'],
    ['2312', 'Tasolasin muotoilu ja muokkaus'],
    ['81', 'Kiinteist\u00f6n- ja maisemanhoito'],
    ['813', 'Maisemanhoitopalvelut'],
    ['8130', 'Maisemanhoitopalvelut'],
    ['93', 'Urheilutoiminta sek\u00e4 huvi- ja virkistyspalvelut'],
    ['931', 'Urheilutoiminta'],
    ['9319', 'Muu urheilutoiminta'],
    ['77', 'Vuokraus- ja leasingtoiminta'],
    ['773', 'Koneiden ja laitteiden vuokraus ja leasing'],
    ['7732', 'Rakennuskoneiden ja -laitteiden vuokraus ja leasing'],
    ['0145', 'Lampaiden ja vuohien kasvatus'],
    [
        '474',
        'Tieto- ja viestint\u00e4teknisten laitteiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    [
        '4741',
        'Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen v\u00e4hitt\u00e4iskauppa'
    ],
    [
        '477',
        'Muiden tavaroiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    ['4778', 'Muu uusien tavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['63', 'Tietopalvelutoiminta'],
    ['639', 'Muu tietopalvelutoiminta'],
    ['6399', 'Muualla luokittelematon tietopalvelutoiminta'],
    [
        '476',
        'Kulttuuri- ja vapaa-ajan tuotteiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    ['4764', 'Urheiluv\u00e4lineiden v\u00e4hitt\u00e4iskauppa'],
    ['33', 'Koneiden ja laitteiden korjaus, huolto ja asennus'],
    [
        '331',
        'Metallituotteiden, teollisuuden koneiden ja laitteiden korjaus ja huolto'
    ],
    ['3312', 'Teollisuuden koneiden ja laitteiden korjaus ja huolto'],
    ['85', 'Koulutus'],
    ['855', 'Muu koulutus'],
    ['8559', 'Muualla luokittelematon koulutus'],
    ['4642', 'Vaatteiden ja jalkineiden tukkukauppa'],
    ['663', 'Omaisuudenhoitotoiminta'],
    ['6630', 'Omaisuudenhoitotoiminta'],
    ['30', 'Muiden kulkuneuvojen valmistus'],
    ['301', 'Laivojen ja veneiden rakentaminen'],
    ['3012', 'Huvi- ja urheiluveneiden rakentaminen'],
    ['4332', 'Rakennuspuusep\u00e4n asennusty\u00f6t'],
    ['9312', 'Urheiluseurojen toiminta'],
    ['439', 'Muu erikoistunut rakennustoiminta'],
    ['4399', 'Muualla luokittelematon erikoistunut rakennustoiminta'],
    ['9601', 'Pesulapalvelut'],
    ['812', 'Siivouspalvelut'],
    ['8121', 'Kiinteist\u00f6jen siivous'],
    ['52', 'Varastointi ja liikennett\u00e4 palveleva toiminta'],
    ['522', 'Liikennett\u00e4 palveleva toiminta'],
    ['5221', 'Maaliikennett\u00e4 palveleva toiminta'],
    ['432', 'S\u00e4hk\u00f6-, vesijohto- ja muu rakennusasennus'],
    ['4322', 'L\u00e4mp\u00f6-, vesijohto- ja ilmastointiasennus'],
    [
        '95',
        'Tietokoneiden, henkil\u00f6kohtaisten ja kotitaloustavaroiden korjaus'
    ],
    ['952', 'Henkil\u00f6kohtaisten ja kotitaloustavaroiden korjaus'],
    [
        '9522',
        'Kotitalouskoneiden sek\u00e4 kodin ja puutarhan laitteiden korjaus'
    ],
    ['78', 'Ty\u00f6llist\u00e4mistoiminta'],
    ['782', 'Ty\u00f6voiman vuokraus'],
    ['7820', 'Ty\u00f6voiman vuokraus'],
    [
        '631',
        'Tietojenk\u00e4sittely, palvelintilan vuokraus ja niihin liittyv\u00e4t palvelut; verkkoportaalit'
    ],
    [
        '6311',
        'Tietojenk\u00e4sittely, palvelintilan vuokraus ja niihin liittyv\u00e4t palvelut'
    ],
    ['8553', 'Kuljettajakoulutus'],
    ['4646', 'Farmaseuttisten tuotteiden tukkukauppa'],
    ['4333', 'Lattianp\u00e4\u00e4llystys ja seinien verhoilu'],
    ['4663', 'Kaivos- ja rakennuskoneiden tukkukauppa'],
    ['7111', 'Arkkitehtipalvelut'],
    ['469', 'Muu tukkukauppa'],
    ['4690', 'Muu tukkukauppa'],
    ['08', 'Muu kaivostoiminta ja louhinta'],
    ['089', 'Muu mineraalien kaivu'],
    ['0892', 'Turpeen nosto'],
    ['2016', 'Muoviaineiden valmistus'],
    [
        '6203',
        'Tietojenk\u00e4sittelyn ja laitteistojen k\u00e4ytt\u00f6- ja hallintapalvelut'
    ],
    ['461', 'Agentuuritoiminta'],
    ['4613', 'Puutavaran ja rakennusmateriaalien agentuuritoiminta'],
    ['3319', 'Muiden laitteiden korjaus ja huolto'],
    ['9491', 'Seurakunnat ja uskonnolliset j\u00e4rjest\u00f6t'],
    ['467', 'Muu erikoistunut tukkukauppa'],
    [
        '4671',
        'Kiinteiden, nestem\u00e4isten ja kaasumaisten polttoaineiden yms. tukkukauppa'
    ],
    ['7211', 'Biotekninen tutkimus ja kehitt\u00e4minen'],
    ['861', 'Terveydenhuollon laitospalvelut'],
    ['8610', 'Terveydenhuollon laitospalvelut'],
    ['88', 'Sosiaalihuollon avopalvelut'],
    ['889', 'Muut sosiaalihuollon avopalvelut'],
    ['8891', 'Lasten p\u00e4iv\u00e4hoitopalvelut'],
    ['90', 'Kulttuuri- ja viihdetoiminta'],
    ['900', 'Kulttuuri- ja viihdetoiminta'],
    ['9003', 'Taiteellinen luominen'],
    ['4321', 'S\u00e4hk\u00f6asennus'],
    ['4511', 'Henkil\u00f6autojen ja kevyiden moottoriajoneuvojen kauppa'],
    ['87', 'Sosiaalihuollon laitospalvelut'],
    ['879', 'Muut sosiaalihuollon laitospalvelut'],
    ['8790', 'Muut sosiaalihuollon laitospalvelut'],
    ['862', 'L\u00e4\u00e4k\u00e4ri- ja hammasl\u00e4\u00e4k\u00e4ripalvelut'],
    ['8623', 'Hammasl\u00e4\u00e4k\u00e4ripalvelut'],
    [
        '872',
        'Kehitysvammaisten sek\u00e4 mielenterveys- ja p\u00e4ihdeongelmaisten asumispalvelut'
    ],
    [
        '8720',
        'Kehitysvammaisten sek\u00e4 mielenterveys- ja p\u00e4ihdeongelmaisten asumispalvelut'
    ],
    ['6492', 'Muu luotonanto'],
    ['8551', 'Urheilu- ja liikuntakoulutus'],
    ['4673', 'Puun, rakennusmateriaalien ja saniteettilaitteiden tukkukauppa'],
    ['13', 'Tekstiilien valmistus'],
    ['139', 'Muiden tekstiilituotteiden valmistus'],
    ['1392', 'Sovitettujen tekstiilituotteiden valmistus  pl. vaatteet)'],
    ['4618', 'Muu erikoistunut agentuuritoiminta'],
    [
        '475',
        'Muiden kotitaloustavaroiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    [
        '4759',
        'Huonekalujen, valaisimien ja muualla luokittelemattomien taloustarvikkeiden v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    ['811', 'Kiinteist\u00f6nhoito'],
    ['8110', 'Kiinteist\u00f6nhoito'],
    ['50', 'Vesiliikenne'],
    ['503', 'Sis\u00e4vesiliikenteen henkil\u00f6kuljetus'],
    ['5030', 'Sis\u00e4vesiliikenteen henkil\u00f6kuljetus'],
    ['3315', 'Laivojen ja veneiden korjaus ja huolto'],
    ['743', 'K\u00e4\u00e4nt\u00e4minen ja tulkkaus'],
    ['7430', 'K\u00e4\u00e4nt\u00e4minen ja tulkkaus'],
    ['562', 'Ateriapalvelut ja muut ravitsemispalvelut'],
    ['5629', 'Henkil\u00f6st\u00f6- ja laitosruokalat'],
    ['4643', 'Kodinkoneiden tukkukauppa'],
    [
        '4664',
        'Tekstiiliteollisuuden koneiden sek\u00e4 ompelu- ja kutomakoneiden tukkukauppa'
    ],
    [
        '59',
        'Elokuva-, video- ja televisio-ohjelmatuotanto, \u00e4\u00e4nitteiden ja musiikin kustantaminen'
    ],
    [
        '592',
        '\u00c4\u00e4nitysstudiot; \u00e4\u00e4nitteiden ja musiikin kustantaminen'
    ],
    [
        '5920',
        '\u00c4\u00e4nitysstudiot; \u00e4\u00e4nitteiden ja musiikin kustantaminen'
    ],
    ['591', 'Elokuva-, video- ja televisio-ohjelmatoiminta'],
    ['5911', 'Elokuvien, videoiden ja televisio-ohjelmien tuotanto'],
    ['453', 'Moottoriajoneuvojen osien ja varusteiden kauppa'],
    [
        '4532',
        'Moottoriajoneuvojen osien ja varusteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['161', 'Puun sahaus, h\u00f6yl\u00e4ys ja kyll\u00e4stys'],
    ['1610', 'Puun sahaus, h\u00f6yl\u00e4ys ja kyll\u00e4stys'],
    ['03', 'Kalastus ja vesiviljely'],
    ['031', 'Kalastus'],
    ['0312', 'Sis\u00e4vesikalastus'],
    ['6201', 'Ohjelmistojen suunnittelu ja valmistus'],
    ['237', 'Kiven leikkaaminen, muotoilu ja viimeistely'],
    ['2370', 'Kiven leikkaaminen, muotoilu ja viimeistely'],
    ['4762', 'Sanomalehtien ja paperitavaran v\u00e4hitt\u00e4iskauppa'],
    ['332', 'Teollisuuden koneiden ja laitteiden ym. asennus'],
    ['3320', 'Teollisuuden koneiden ja laitteiden ym. asennus'],
    ['18', 'Painaminen ja tallenteiden j\u00e4ljent\u00e4minen'],
    ['181', 'Painaminen ja siihen liittyv\u00e4t palvelut'],
    ['1813', 'Painamista ja julkaisemista edelt\u00e4v\u00e4t palvelut'],
    [
        '26',
        'Tietokoneiden sek\u00e4 elektronisten ja optisten tuotteiden valmistus'
    ],
    ['264', 'Viihde-elektroniikan valmistus'],
    ['2640', 'Viihde-elektroniikan valmistus'],
    ['82', 'Hallinto- ja tukipalvelut liike-el\u00e4m\u00e4lle'],
    ['822', 'Puhelinpalvelukeskusten toiminta'],
    ['8220', 'Puhelinpalvelukeskusten toiminta'],
    ['463', 'Elintarvikkeiden, juomien ja tupakan tukkukauppa'],
    ['4639', 'Elintarvikkeiden, juomien ja tupakan yleistukkukauppa'],
    ['012', 'Monivuotisten kasvien viljely'],
    ['0129', 'Muu monivuotisten kasvien viljely'],
    [
        '79',
        'Matkatoimistojen ja matkanj\u00e4rjest\u00e4jien toiminta; varauspalvelut'
    ],
    ['799', 'Varauspalvelut, matkaoppaiden palvelut ym.'],
    ['7990', 'Varauspalvelut, matkaoppaiden palvelut ym.'],
    ['6312', 'Verkkoportaalit'],
    ['465', 'Tieto- ja viestint\u00e4teknisten laitteiden tukkukauppa'],
    ['4652', 'Elektroniikka- ja viestint\u00e4laitteiden ja osien tukkukauppa'],
    ['4614', 'Koneiden ja laitteiden agentuuritoiminta'],
    ['4939', 'Muualla luokittelematon maaliikenteen henkil\u00f6liikenne'],
    [
        '4776',
        'Kukkien, kasvien, siementen, lannoitteiden, lemmikkiel\u00e4inten ja niiden ruokien v\u00e4hitt\u00e4iskauppa'
    ],
    ['1399', 'Muualla luokittelematon tekstiilituotteiden valmistus'],
    ['9603', 'Hautaustoimistojen palvelut'],
    ['28', 'Muiden koneiden ja laitteiden valmistus'],
    [
        '281',
        'Yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen voimakoneiden valmistus'
    ],
    ['2814', 'Muiden hanojen ja venttiilien valmistus'],
    ['741', 'Taideteollinen muotoilu ja suunnittelu'],
    ['7410', 'Taideteollinen muotoilu ja suunnittelu'],
    [
        '325',
        'L\u00e4\u00e4kint\u00e4- ja hammasl\u00e4\u00e4kint\u00e4instrumenttien ja -tarvikkeiden valmistus'
    ],
    [
        '3250',
        'L\u00e4\u00e4kint\u00e4- ja hammasl\u00e4\u00e4kint\u00e4instrumenttien ja -tarvikkeiden valmistus'
    ],
    ['873', 'Vanhusten ja vammaisten asumispalvelut'],
    ['8730', 'Vanhusten ja vammaisten asumispalvelut'],
    ['4773', 'Apteekit'],
    ['4391', 'Kattorakenteiden asennus ja kattaminen'],
    ['3514', 'S\u00e4hk\u00f6n kauppa'],
    [
        '4674',
        'Rautakauppatavaroiden, LVI-laitteiden ja -tarvikkeiden tukkukauppa'
    ],
    ['4751', 'Tekstiilien v\u00e4hitt\u00e4iskauppa'],
    ['00', 'Toimiala tuntematon'],
    ['000', 'Toimiala tuntematon'],
    ['0000', 'Toimiala tuntematon'],
    ['4771', 'Vaatteiden v\u00e4hitt\u00e4iskauppa'],
    ['021', 'Mets\u00e4nhoito'],
    ['0210', 'Mets\u00e4nhoito'],
    ['9311', 'Urheilulaitosten toiminta'],
    [
        '4644',
        'Posliini-, lasi- ja muiden taloustavaroiden sek\u00e4 puhdistusaineiden tukkukauppa'
    ],
    ['4752', 'Rautakauppatavaran, maalien ja lasin v\u00e4hitt\u00e4iskauppa'],
    [
        '8622',
        'L\u00e4\u00e4k\u00e4riasemat, yksityisl\u00e4\u00e4k\u00e4rit ja vastaavat erikoisl\u00e4\u00e4k\u00e4ripalvelut'
    ],
    ['8899', 'Muualla luokittelemattomat sosiaalihuollon avopalvelut'],
    ['53', 'Posti- ja kuriiritoiminta'],
    ['532', 'Muu posti-, jakelu- ja kuriiritoiminta'],
    ['5320', 'Muu posti-, jakelu- ja kuriiritoiminta'],
    [
        '4799',
        'Muu v\u00e4hitt\u00e4iskauppa muualla kuin myym\u00e4l\u00f6iss\u00e4'
    ],
    ['241', 'Raudan, ter\u00e4ksen ja rautaseosten valmistus'],
    ['2410', 'Raudan, ter\u00e4ksen ja rautaseosten valmistus'],
    ['58', 'Kustannustoiminta'],
    ['581', 'Kirjojen ja lehtien kustantaminen ja muu kustannustoiminta'],
    ['5813', 'Sanomalehtien kustantaminen'],
    ['8413', 'Ty\u00f6voima- ja elinkeinoasiain hallinto'],
    ['22', 'Kumi- ja muovituotteiden valmistus'],
    ['222', 'Muovituotteiden valmistus'],
    ['2229', 'Muiden muovituotteiden valmistus'],
    ['80', 'Turvallisuus-, vartiointi- ja etsiv\u00e4palvelut'],
    ['801', 'Yksityiset turvallisuuspalvelut'],
    ['8010', 'Yksityiset turvallisuuspalvelut'],
    ['4329', 'Muu rakennusasennus'],
    ['205', 'Muiden kemiallisten tuotteiden valmistus'],
    ['2059', 'Muualla luokittelematon kemiallisten tuotteiden valmistus'],
    ['024', 'Mets\u00e4taloutta palveleva toiminta'],
    ['0240', 'Mets\u00e4taloutta palveleva toiminta'],
    ['9313', 'Kuntokeskukset'],
    [
        '282',
        'Muiden yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen koneiden valmistus'
    ],
    [
        '2821',
        'Teollisuusuunien, l\u00e4mmitysj\u00e4rjestelmien ja tulipes\u00e4polttimien valmistus'
    ],
    ['283', 'Maa- ja mets\u00e4talouskoneiden valmistus'],
    ['2830', 'Maa- ja mets\u00e4talouskoneiden valmistus'],
    ['942', 'Ammattiyhdistysten toiminta'],
    ['9420', 'Ammattiyhdistysten toiminta'],
    ['491', 'Rautateiden henkil\u00f6liikenne, kaukoliikenne'],
    ['4910', 'Rautateiden henkil\u00f6liikenne, kaukoliikenne'],
    ['7312', 'Mainostilan vuokraus ja myynti'],
    ['712', 'Tekninen testaus ja analysointi'],
    ['7120', 'Tekninen testaus ja analysointi'],
    ['17', 'Paperin, paperi- ja kartonkituotteiden valmistus'],
    ['172', 'Paperi-, kartonki- ja pahvituotteiden valmistus'],
    ['1722', 'Paperisten talous- ja hygieniatarvikkeiden valmistus'],
    [
        '471',
        'V\u00e4hitt\u00e4iskauppa erikoistumattomissa myym\u00e4l\u00f6iss\u00e4'
    ],
    [
        '4711',
        'Elintarvikkeiden, juomien ja tupakan erikoistumaton v\u00e4hitt\u00e4iskauppa'
    ],
    [
        '4719',
        'Muu v\u00e4hitt\u00e4iskauppa erikoistumattomissa myym\u00e4l\u00f6iss\u00e4'
    ],
    ['563', 'Baarit ja kahvilat'],
    ['5630', 'Baarit ja kahvilat'],
    [
        '4616',
        'Tekstiilien, vaatteiden, jalkineiden ja nahkavalmisteiden agentuuritoiminta'
    ],
    ['8552', 'Taiteen ja musiikin koulutus'],
    ['4641', 'Tekstiilien tukkukauppa'],
    ['4651', 'Tietokoneiden, oheislaitteiden ja ohjelmistojen tukkukauppa'],
    ['60', 'Radio- ja televisiotoiminta'],
    ['601', 'Radio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen'],
    ['6010', 'Radio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen'],
    ['4774', 'Terveydenhoitotarvikkeiden v\u00e4hitt\u00e4iskauppa'],
    [
        '4633',
        'Maitotaloustuotteiden, munien sek\u00e4 ravinto\u00f6ljyjen ja -rasvojen tukkukauppa'
    ],
    ['411', 'Rakennuttaminen ja rakennushankkeiden kehitt\u00e4minen'],
    ['4110', 'Rakennuttaminen ja rakennushankkeiden kehitt\u00e4minen'],
    ['011', 'Yksivuotisten kasvien viljely'],
    ['0119', 'Muu yksivuotisten ja koristekasvien viljely'],
    ['0141', 'Lypsykarjan kasvatus'],
    ['829', 'Muu liike-el\u00e4m\u00e4\u00e4 palveleva toiminta'],
    ['8299', 'Muut palvelut liike-el\u00e4m\u00e4lle'],
    [
        '38',
        'J\u00e4tteen keruu, k\u00e4sittely ja loppusijoitus; materiaalien kierr\u00e4tys'
    ],
    ['382', 'J\u00e4tteen k\u00e4sittely ja loppusijoitus'],
    ['3821', 'Tavanomaisen j\u00e4tteen k\u00e4sittely ja loppusijoitus'],
    ['9609', 'Muualla luokittelemattomat henkil\u00f6kohtaiset palvelut'],
    ['791', 'Matkatoimistojen ja matkanj\u00e4rjest\u00e4jien toiminta'],
    ['7912', 'Matkanj\u00e4rjest\u00e4jien toiminta'],
    [
        '4661',
        'Maa- ja mets\u00e4talouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit'
    ],
    ['3511', 'S\u00e4hk\u00f6n tuotanto'],
    ['932', 'Huvi- ja virkistystoiminta'],
    ['9321', 'Huvi- ja teemapuistojen toiminta'],
    ['42', 'Maa- ja vesirakentaminen'],
    ['422', 'Yleisten jakeluverkkojen rakentaminen'],
    ['4222', 'S\u00e4hk\u00f6- ja tietoliikenneverkkojen rakentaminen'],
    ['015', 'Yhdistetty kasvinviljely ja kotiel\u00e4intalous  sekatilat)'],
    ['0150', 'Yhdistetty kasvinviljely ja kotiel\u00e4intalous  sekatilat)'],
    ['0147', 'Siipikarjan kasvatus'],
    ['881', 'Vanhusten ja vammaisten sosiaalihuollon avopalvelut'],
    ['8810', 'Vanhusten ja vammaisten sosiaalihuollon avopalvelut'],
    [
        '0111',
        'Viljakasvien  pl. riisin), palkokasvien ja \u00f6ljysiemenkasvien viljely'
    ],
    ['0113', 'Vihannesten ja melonien, juuresten ja mukulakasvien viljely'],
    ['681', 'Omien kiinteist\u00f6jen kauppa'],
    ['6810', 'Omien kiinteist\u00f6jen kauppa'],
    ['4677', 'J\u00e4tteen ja romun tukkukauppa'],
    ['1812', 'Muu painaminen'],
    ['478', 'Tori- ja markkinakauppa'],
    [
        '4781',
        'Elintarvikkeiden, juomien ja tupakkatuotteiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'
    ],
    ['691', 'Lakiasiainpalvelut'],
    ['6910', 'Lakiasiainpalvelut'],
    ['5912', 'Elokuvien, video- ja televisio-ohjelmien j\u00e4lkituotanto'],
    [
        '2829',
        'Muualla luokittelematon yleisk\u00e4ytt\u00f6\u00f6n tarkoitettujen koneiden valmistus'
    ],
    ['1623', 'Muiden rakennuspuusep\u00e4ntuotteiden valmistus'],
    ['2561', 'Metallien k\u00e4sittely ja p\u00e4\u00e4llyst\u00e4minen'],
    ['5811', 'Kirjojen kustantaminen'],
    [
        '4753',
        'Mattojen, tapettien ja lattianp\u00e4\u00e4llysteiden v\u00e4hitt\u00e4iskauppa'
    ],
    ['842', 'Ulkoasiain hallinto, maanpuolustus ja j\u00e4rjestystoimi'],
    ['8425', 'Palo- ja pelastustoimi'],
    [
        '1721',
        'Aaltopaperin ja -pahvin sek\u00e4 paperi-, kartonki- ja pahvipakkausten valmistus'
    ],
    ['5621', 'Pitopalvelu'],
    ['662', 'Vakuutus- ja el\u00e4kevakuutustoimintaa avustava toiminta'],
    ['6622', 'Vakuutusasiamiesten ja -v\u00e4litt\u00e4jien toiminta'],
    ['75', 'El\u00e4inl\u00e4\u00e4kint\u00e4palvelut'],
    ['750', 'El\u00e4inl\u00e4\u00e4kint\u00e4palvelut'],
    ['7500', 'El\u00e4inl\u00e4\u00e4kint\u00e4palvelut'],
    ['032', 'Vesiviljely'],
    ['0322', 'Kalanviljely sis\u00e4vesiss\u00e4'],
    [
        '29',
        'Moottoriajoneuvojen, per\u00e4vaunujen ja puoliper\u00e4vaunujen valmistus'
    ],
    [
        '292',
        'Moottoriajoneuvojen korien valmistus; per\u00e4vaunujen ja puoliper\u00e4vaunujen valmistus'
    ],
    [
        '2920',
        'Moottoriajoneuvojen korien valmistus; per\u00e4vaunujen ja puoliper\u00e4vaunujen valmistus'
    ],
    ['9521', 'Viihde-elektroniikan korjaus'],
    ['8291', 'Perint\u00e4- ja luottotietopalvelut'],
    ['8129', 'Muu siivoustoiminta'],
    ['5819', 'Muu kustannustoiminta'],
    ['252', 'Metallis\u00e4ili\u00f6iden ja -altaiden yms. valmistus'],
    ['2529', 'Muiden metallis\u00e4ili\u00f6iden ja -altaiden yms. valmistus'],
    ['559', 'Muu majoitus'],
    ['5590', 'Muu majoitus'],
    ['521', 'Varastointi'],
    ['5210', 'Varastointi'],
    ['4619', 'Yleisagentuuritoiminta'],
    ['353', 'L\u00e4mm\u00f6n ja kylm\u00e4n tuotanto ja jakelu'],
    ['3530', 'L\u00e4mm\u00f6n ja kylm\u00e4n tuotanto ja jakelu'],
    ['4531', 'Moottoriajoneuvojen osien ja varusteiden tukkukauppa'],
    ['4662', 'Ty\u00f6st\u00f6koneiden tukkukauppa'],
    ['275', 'Kodinkoneiden valmistus'],
    ['2751', 'S\u00e4hk\u00f6isten kodinkoneiden valmistus'],
    ['289', 'Muiden erikoiskoneiden valmistus'],
    ['2899', 'Muualla luokittelematon erikoiskoneiden valmistus'],
    ['10', 'Elintarvikkeiden valmistus'],
    ['108', 'Muiden elintarvikkeiden valmistus'],
    ['1085', 'Einesten ja valmisruokien valmistus'],
    ['4638', 'Kalan, luontaistuotteiden ja muiden elintarvikkeiden tukkukauppa'],
    ['7731', 'Maatalouskoneiden ja -laitteiden vuokraus ja leasing'],
    ['642', 'Rahoitusalan holdingyhti\u00f6iden toiminta'],
    ['6420', 'Rahoitusalan holdingyhti\u00f6iden toiminta'],
    [
        '722',
        'Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehitt\u00e4minen'
    ],
    [
        '7220',
        'Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehitt\u00e4minen'
    ],
    ['783', 'Muut henkil\u00f6st\u00f6n hankintapalvelut'],
    ['7830', 'Muut henkil\u00f6st\u00f6n hankintapalvelut'],
    ['823', 'Messujen ja kongressien j\u00e4rjest\u00e4minen'],
    ['8230', 'Messujen ja kongressien j\u00e4rjest\u00e4minen'],
    ['9001', 'Esitt\u00e4v\u00e4t taiteet'],
    ['4634', 'Alkoholi- ja muiden juomien tukkukauppa'],
    ['421', 'Teiden ja rautateiden rakentaminen'],
    ['4211', 'Teiden ja moottoriteiden rakentaminen'],
    ['3311', 'Metallituotteiden korjaus ja huolto'],
    ['473', 'Ajoneuvojen polttoaineen v\u00e4hitt\u00e4iskauppa'],
    ['4730', 'Ajoneuvojen polttoaineen v\u00e4hitt\u00e4iskauppa'],
    ['9002', 'Esitt\u00e4vi\u00e4 taiteita palveleva toiminta'],
    [
        '4779',
        'K\u00e4ytettyjen tavaroiden v\u00e4hitt\u00e4iskauppa myym\u00e4l\u00f6iss\u00e4'
    ],
    [
        '257',
        'Ruokailu- ja leikkuuv\u00e4lineiden yms. sek\u00e4 ty\u00f6kalujen ja rautatavaran valmistus'
    ],
    ['2573', 'Ty\u00f6kalujen valmistus'],
    [
        '102',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    [
        '1020',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    ['4666', 'Muiden konttorikoneiden ja -laitteiden tukkukauppa'],
    [
        '454',
        'Moottoripy\u00f6rien sek\u00e4 niiden osien ja varusteiden myynti, huolto ja korjaus'
    ],
    [
        '4540',
        'Moottoripy\u00f6rien sek\u00e4 niiden osien ja varusteiden myynti, huolto ja korjaus'
    ],
    ['274', 'S\u00e4hk\u00f6lamppujen ja valaisimien valmistus'],
    ['2740', 'S\u00e4hk\u00f6lamppujen ja valaisimien valmistus'],
    ['4617', 'Elintarvikkeiden, juomien ja tupakan agentuuritoiminta'],
    ['701', 'P\u00e4\u00e4konttorien toiminta'],
    ['7010', 'P\u00e4\u00e4konttorien toiminta'],
    [
        '472',
        'Elintarvikkeiden, juomien ja tupakan v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'
    ],
    ['4729', 'Muu v\u00e4hitt\u00e4iskauppa erikoismyym\u00e4l\u00f6iss\u00e4'],
    ['7021', 'Suhdetoiminta ja viestint\u00e4'],
    ['107', 'Leipomotuotteiden, makaronien yms. valmistus'],
    [
        '1071',
        'Leiv\u00e4n valmistus; tuoreiden leivonnaisten ja kakkujen valmistus'
    ],
    ['4213', 'Siltojen ja tunneleiden rakentaminen'],
    ['37', 'Viem\u00e4ri- ja j\u00e4tevesihuolto'],
    ['370', 'Viem\u00e4ri- ja j\u00e4tevesihuolto'],
    ['3700', 'Viem\u00e4ri- ja j\u00e4tevesihuolto'],
    ['109', 'El\u00e4inten ruokien valmistus'],
    ['1091', 'Kotiel\u00e4inten rehujen valmistus'],
    ['4647', 'Huonekalujen, mattojen ja valaisimien tukkukauppa'],
    ['016', 'Maataloutta palveleva toiminta'],
    ['0162', 'Kotiel\u00e4intaloutta palveleva toiminta'],
    ['273', 'S\u00e4hk\u00f6johtojen ja kytkent\u00e4laitteiden valmistus'],
    ['2733', 'Kytkent\u00e4laitteiden valmistus'],
    ['772', 'Henkil\u00f6kohtaisten ja kotitaloustavaroiden vuokraus ja leasing'],
    [
        '7729',
        'Muiden henkil\u00f6kohtaisten ja kotitaloustavaroiden vuokraus ja leasing'
    ],
    ['9329', 'Muu huvi- ja virkistystoiminta'],
    ['2223', 'Rakennusmuovien valmistus'],
    ['381', 'J\u00e4tteen keruu'],
    ['3811', 'Tavanomaisen j\u00e4tteen keruu'],
    ['329', 'Muualla luokittelematon valmistus'],
    ['3299', 'Muu muualla luokittelematon valmistus'],
    ['4775', 'Kosmetiikka- ja hygieniatuotteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '321',
        'Korujen, kultasep\u00e4ntuotteiden ja muiden vastaavien tuotteiden valmistus'
    ],
    ['3212', 'Jalokivikorujen ja muiden kultasep\u00e4ntuotteiden valmistus'],
    ['4931', 'Paikallisliikenne'],
    ['7739', 'Muiden koneiden ja laitteiden vuokraus ja leasing'],
    ['11', 'Juomien valmistus'],
    ['110', 'Juomien valmistus'],
    [
        '1107',
        'Virvoitusjuomien valmistus; kivenn\u00e4isvesien ja muiden pullotettujen vesien tuotanto'
    ],
    ['133', 'Tekstiilien viimeistely'],
    ['1330', 'Tekstiilien viimeistely'],
    [
        '4221',
        'Yleisten jakeluverkkojen rakentaminen nestem\u00e4isi\u00e4 ja kaasumaisia aineita varten'
    ],
    ['2361', 'Betonituotteiden valmistus rakennustarkoituksiin'],
    ['31', 'Huonekalujen valmistus'],
    ['310', 'Huonekalujen valmistus'],
    ['3102', 'Keitti\u00f6kalusteiden valmistus'],
    ['4777', 'Kultasep\u00e4nteosten ja kellojen v\u00e4hitt\u00e4iskauppa'],
    ['4754', 'S\u00e4hk\u00f6isten kodinkoneiden v\u00e4hitt\u00e4iskauppa'],
    ['5224', 'Lastink\u00e4sittely'],
    [
        '4615',
        'Huonekalujen, taloustavaroiden ja rautakauppatavaroiden agentuuritoiminta'
    ],
    ['14', 'Vaatteiden valmistus'],
    ['141', 'Vaatteiden valmistus  pl. turkisvaatteet)'],
    ['1413', 'Muu takkien, pukujen, housujen, hameiden yms. valmistus'],
    ['105', 'Maitotaloustuotteiden valmistus'],
    ['1051', 'Maitotaloustuotteiden ja juuston valmistus'],
    ['15', 'Nahan ja nahkatuotteiden valmistus'],
    ['152', 'Jalkineiden valmistus'],
    ['1520', 'Jalkineiden valmistus'],
    ['221', 'Kumituotteiden valmistus'],
    ['2219', 'Muiden kumituotteiden valmistus'],
    ['0143', 'Hevosten ja muiden hevosel\u00e4inten kasvatus'],
    ['5229', 'Muu liikennett\u00e4 palveleva toiminta'],
    ['7911', 'Matkatoimistojen toiminta'],
    ['081', 'Kiven louhinta, hiekan ja saven otto'],
    ['0812', 'Soran, hiekan, saven ja kaoliinin otto'],
    ['4772', 'Jalkineiden ja nahkatavaroiden v\u00e4hitt\u00e4iskauppa'],
    ['2512', 'Metalliovien ja -ikkunoiden valmistus'],
    [
        '941',
        'Elinkeinoel\u00e4m\u00e4n, ty\u00f6nantaja- ja ammattialaj\u00e4rjest\u00f6jen toiminta'
    ],
    [
        '9411',
        'Elinkeinoel\u00e4m\u00e4n ja ty\u00f6nantajaj\u00e4rjest\u00f6jen toiminta'
    ],
    ['293', 'Osien ja tarvikkeiden valmistus moottoriajoneuvoihin'],
    [
        '2931',
        'S\u00e4hk\u00f6- ja elektroniikkalaitteiden valmistus moottoriajoneuvoihin'
    ],
    ['0161', 'Kasvinviljely\u00e4 palveleva toiminta'],
    ['4676', 'Muiden v\u00e4lituotteiden tukkukauppa'],
    [
        '101',
        'Teurastus, lihan s\u00e4ilyvyysk\u00e4sittely ja lihatuotteiden valmistus'
    ],
    ['1011', 'Teurastus ja lihan s\u00e4ilyvyysk\u00e4sittely  pl. siipikarja)'],
    ['92', 'Rahapeli- ja vedonly\u00f6ntipalvelut'],
    ['920', 'Rahapeli- ja vedonly\u00f6ntipalvelut'],
    ['9200', 'Rahapeli- ja vedonly\u00f6ntipalvelut'],
    ['261', 'Elektronisten komponenttien ja piirilevyjen valmistus'],
    ['2611', 'Elektronisten komponenttien valmistus'],
    ['182', '\u00c4\u00e4ni-, kuva- ja atk-tallenteiden tuotanto'],
    ['1820', '\u00c4\u00e4ni-, kuva- ja atk-tallenteiden tuotanto'],
    [
        '91',
        'Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta'
    ],
    [
        '910',
        'Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta'
    ],
    ['9102', 'Museoiden toiminta'],
    ['7722', 'Videofilmien vuokraus'],
    [
        '265',
        'Mittaus-, testaus- ja navigointiv\u00e4lineiden ja -laitteiden valmistus; kellot'
    ],
    [
        '2651',
        'Mittaus-, testaus- ja navigointiv\u00e4lineiden ja -laitteiden valmistus'
    ],
    ['322', 'Soitinten valmistus'],
    ['3220', 'Soitinten valmistus'],
    [
        '4612',
        'Polttoaineiden, malmien, metallien ja teollisuuskemikaalien agentuuritoiminta'
    ],
    ['4675', 'Peruskemikaalien, lannoitteiden yms. tukkukauppa'],
    ['4743', 'Viihde-elektroniikan v\u00e4hitt\u00e4iskauppa'],
    ['9529', 'Muiden henkil\u00f6kohtaisten ja kotitaloustavaroiden korjaus'],
    ['99', 'Kansainv\u00e4listen organisaatioiden ja toimielinten toiminta'],
    ['990', 'Kansainv\u00e4listen organisaatioiden ja toimielinten toiminta'],
    ['9900', 'Kansainv\u00e4listen organisaatioiden ja toimielinten toiminta'],
    ['821', 'Hallinto- ja toimistopalvelut'],
    ['8211', 'Yhdistetyt toimistopalvelut'],
    ['103', 'Hedelmien ja kasvisten jalostus ja s\u00e4il\u00f6nt\u00e4'],
    [
        '1039',
        'Muu hedelmien, marjojen ja kasvisten jalostus ja s\u00e4il\u00f6nt\u00e4'
    ],
    ['2521', 'Keskusl\u00e4mmityspatterien ja kuumavesivaraajien valmistus'],
    ['429', 'Muu maa- ja vesirakentaminen'],
    ['4299', 'Muualla luokittelematon maa- ja vesirakentaminen'],
    ['1013', 'Liha- ja siipikarjatuotteiden valmistus'],
    [
        '5814',
        'Aikakauslehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen'
    ],
    ['51', 'Ilmaliikenne'],
    ['511', 'Matkustajalentoliikenne'],
    ['5110', 'Matkustajalentoliikenne'],
    ['5222', 'Vesiliikennett\u00e4 palveleva toiminta'],
    [
        '151',
        'Nahan parkitseminen ja muokkaus; matka- ja k\u00e4silaukkujen, satuloiden ja valjaiden valmistus; turkisten muokkaus ja v\u00e4rj\u00e4ys'
    ],
    [
        '1512',
        'Matka-, k\u00e4si- ym. laukkujen, satuloiden ja valjaiden valmistus'
    ],
    ['9412', 'Ammattialaj\u00e4rjest\u00f6jen toiminta'],
    ['017', 'Mets\u00e4stys ja sit\u00e4 palveleva toiminta'],
    ['0170', 'Mets\u00e4stys ja sit\u00e4 palveleva toiminta'],
    ['291', 'Moottoriajoneuvojen valmistus'],
    ['2910', 'Moottoriajoneuvojen valmistus'],
    ['8219', 'Sihteeri-, toimisto- ja postituspalvelut'],
    ['61', 'Televiestint\u00e4'],
    ['611', 'Langallisen verkon hallinta ja palvelut'],
    ['6110', 'Langallisen verkon hallinta ja palvelut'],
    ['552', 'Lomakyl\u00e4t, retkeilymajat yms. majoitus'],
    ['5520', 'Lomakyl\u00e4t, retkeilymajat yms. majoitus'],
    ['502', 'Meri- ja rannikkovesiliikenteen tavarankuljetus'],
    ['5020', 'Meri- ja rannikkovesiliikenteen tavarankuljetus'],
    ['7735', 'Ilmaliikennev\u00e4lineiden vuokraus ja leasing'],
    ['771', 'Moottoriajoneuvojen vuokraus ja leasing'],
    ['7711', 'Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing'],
    ['324', 'Pelien ja leikkikalujen valmistus'],
    ['3240', 'Pelien ja leikkikalujen valmistus'],
    ['284', 'Metallin ty\u00f6st\u00f6koneiden ja konety\u00f6kalujen valmistus'],
    ['2849', 'Muiden konety\u00f6kalujen valmistus'],
    ['267', 'Optisten instrumenttien ja valokuvausv\u00e4lineiden valmistus'],
    ['2670', 'Optisten instrumenttien ja valokuvausv\u00e4lineiden valmistus'],
    ['383', 'Materiaalien kierr\u00e4tys'],
    ['3832', 'Lajiteltujen materiaalien kierr\u00e4tys'],
    [
        '239',
        'Hiontatuotteiden ja muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus'
    ],
    [
        '2399',
        'Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus'
    ],
    ['4742', 'Televiestint\u00e4laitteiden v\u00e4hitt\u00e4iskauppa'],
    ['9523', 'Jalkineiden ja nahkatavaroiden korjaus'],
    ['4672', 'Raakametallien ja metallimalmien tukkukauppa'],
    ['143', 'Neulevaatteiden ja sukkien valmistus'],
    ['1431', 'Sukkien ja sukkahousujen valmistus'],
    ['3109', 'Muiden huonekalujen valmistus'],
    ['3314', 'S\u00e4hk\u00f6laitteiden korjaus ja huolto'],
    ['4761', 'Kirjojen v\u00e4hitt\u00e4iskauppa'],
    ['612', 'Langattoman verkon hallinta ja palvelut'],
    ['6120', 'Langattoman verkon hallinta ja palvelut'],
    ['3011', 'Laivojen ja kelluvien rakenteiden rakentaminen'],
    ['106', 'Mylly- ja t\u00e4rkkelystuotteiden valmistus'],
    ['1061', 'Myllytuotteiden valmistus'],
    ['4339', 'Muu rakennusten viimeistely'],
    ['856', 'Koulutusta palveleva toiminta'],
    ['8560', 'Koulutusta palveleva toiminta'],
    ['2365', 'Kuitusementin valmistus'],
    ['244', 'Jalometallien ja muiden v\u00e4rimetallien valmistus'],
    ['2444', 'Kuparin valmistus'],
    ['1624', 'Puupakkausten valmistus'],
    ['4724', 'Leipomotuotteiden ja makeisten v\u00e4hitt\u00e4iskauppa'],
    [
        '271',
        'S\u00e4hk\u00f6moottorien, generaattorien, muuntajien sek\u00e4 s\u00e4hk\u00f6njakelu- ja valvontalaitteiden valmistus'
    ],
    ['2712', 'S\u00e4hk\u00f6njakelu- ja valvontalaitteiden valmistus'],
    ['6209', 'Muu laitteisto- ja tietotekninen palvelutoiminta'],
    ['6491', 'Rahoitusleasing'],
    [
        '3213',
        'J\u00e4ljitelm\u00e4korujen ja muiden vastaavien tuotteiden valmistus'
    ],
    ['9492', 'Poliittiset j\u00e4rjest\u00f6t'],
    ['4645', 'Hajuvesien ja kosmetiikan tukkukauppa'],
    ['4632', 'Lihan ja lihatuotteiden tukkukauppa'],
    ['951', 'Tietokoneiden ja viestint\u00e4laitteiden korjaus'],
    ['9511', 'Tietokoneiden ja niiden oheislaitteiden korjaus'],
    ['234', 'Muiden posliini- ja keramiikkatuotteiden valmistus'],
    ['2341', 'Keraamisten talous- ja koriste-esineiden valmistus'],
    ['2222', 'Muovipakkausten valmistus'],
    ['802', 'Turvallisuusj\u00e4rjestelm\u00e4t'],
    ['8020', 'Turvallisuusj\u00e4rjestelm\u00e4t'],
    ['7734', 'Vesiliikennev\u00e4lineiden vuokraus ja leasing'],
    [
        '39',
        'Maaper\u00e4n ja vesist\u00f6jen kunnostus ja muut ymp\u00e4rist\u00f6nhuoltopalvelut'
    ],
    [
        '390',
        'Maaper\u00e4n ja vesist\u00f6jen kunnostus ja muut ymp\u00e4rist\u00f6nhuoltopalvelut'
    ],
    [
        '3900',
        'Maaper\u00e4n ja vesist\u00f6jen kunnostus ja muut ymp\u00e4rist\u00f6nhuoltopalvelut'
    ],
    ['2363', 'Valmisbetonin valmistus'],
    ['2892', 'Kaivos-, louhinta- ja rakennuskoneiden valmistus'],
    ['3316', 'Ilma- ja avaruusalusten korjaus ja huolto'],
    ['4291', 'Vesirakentaminen'],
    ['2571', 'Ruokailu- ja leikkuuv\u00e4lineiden yms. valmistus'],
    ['2896', 'Muovi- ja kumiteollisuuden koneiden valmistus'],
    ['871', 'Sosiaalihuollon hoitolaitokset'],
    ['8710', 'Sosiaalihuollon hoitolaitokset'],
    ['853', 'Ylemm\u00e4n perusasteen ja keskiasteen koulutus'],
    ['8532', 'Keskiasteen ammatillinen koulutus'],
    ['013', 'Taimien kasvatus ja muu kasvien lis\u00e4\u00e4minen'],
    ['0130', 'Taimien kasvatus ja muu kasvien lis\u00e4\u00e4minen'],
    ['9524', 'Huonekalujen ja kodin kalusteiden korjaus'],
    ['0311', 'Merikalastus'],
    ['4631', 'Juures-, vihannes- marja- ja hedelm\u00e4tukkukauppa'],
    ['8122', 'Muu rakennus- ja teollisuussiivous'],
    ['6629', 'Muu vakuutus- ja el\u00e4kevakuutustoimintaa avustava toiminta'],
    ['4722', 'Lihan ja lihatuotteiden v\u00e4hitt\u00e4iskauppa'],
    ['643', 'Rahastotoiminta'],
    ['6430', 'Rahastotoiminta'],
    ['131', 'Tekstiilikuitujen valmistelu ja kehruu'],
    ['1310', 'Tekstiilikuitujen valmistelu ja kehruu'],
    [
        '8412',
        'Terveydenhuollon, opetuksen, kulttuurin ja muiden yhteiskuntapalvelujen hallinto'
    ],
    ['553', 'Leirint\u00e4alueet, asuntovaunu- ja matkailuvaunualueet'],
    ['5530', 'Leirint\u00e4alueet, asuntovaunu- ja matkailuvaunualueet'],
    ['4624', 'Turkisten ja nahkojen tukkukauppa'],
    ['2891', 'Metallinjalostuskoneiden valmistus'],
    ['4311', 'Rakennusten ja rakennelmien purku'],
    ['5223', 'Ilmaliikennett\u00e4 palveleva toiminta'],
    [
        '2825',
        'Muuhun kuin kotitalousk\u00e4ytt\u00f6\u00f6n tarkoitettujen j\u00e4\u00e4hdytys- ja tuuletuslaitteiden valmistus'
    ],
    ['7721', 'Vapaa-ajan ja urheiluv\u00e4lineiden vuokraus ja leasing'],
    ['504', 'Sis\u00e4vesiliikenteen tavarankuljetus'],
    ['5040', 'Sis\u00e4vesiliikenteen tavarankuljetus'],
    ['843', 'Pakollinen sosiaalivakuutustoiminta'],
    ['8430', 'Pakollinen sosiaalivakuutustoiminta'],
    ['2211', 'Renkaiden valmistus ja uudelleenpinnoitus'],
    [
        '65',
        'Vakuutus-, j\u00e4lleenvakuutus- ja el\u00e4kevakuutustoiminta  pl. pakollinen sosiaalivakuutus)'
    ],
    ['651', 'Vakuutustoiminta'],
    ['6512', 'Muu vakuutustoiminta'],
    ['4622', 'Kukkien ja taimien tukkukauppa'],
    ['4636', 'Sokerin, suklaan, makeisten ja leipomotuotteiden tukkukauppa'],
    ['6391', 'Uutistoimistot'],
    ['2711', 'S\u00e4hk\u00f6moottorien, generaattorien ja muuntajien valmistus'],
    ['0321', 'Kalanviljely meress\u00e4'],
    ['2893', 'Elintarvike-, juoma- ja tupakkateollisuuden koneiden valmistus'],
    ['1089', 'Muualla luokittelematon elintarvikkeiden valmistus'],
    ['781', 'Ty\u00f6nv\u00e4litystoiminta'],
    ['7810', 'Ty\u00f6nv\u00e4litystoiminta'],
    ['2813', 'Pumppujen ja kompressoreiden valmistus'],
    ['2895', 'Paperi-, kartonki- ja pahviteollisuuden koneiden valmistus'],
    [
        '0811',
        'Koriste- ja rakennuskiven, kalkkikiven, kipsin, liidun ja liuskekiven louhinta'
    ],
    ['8292', 'Pakkauspalvelut'],
    ['0146', 'Sikojen kasvatus'],
    [
        '0125',
        'Marjojen, p\u00e4hkin\u00f6iden ja muiden puissa ja pensaissa kasvavien hedelmien viljely'
    ],
    ['4763', 'Musiikki- ja videotallenteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '97',
        'Kotitalouksien toiminta kotitalousty\u00f6ntekij\u00f6iden ty\u00f6nantajina'
    ],
    [
        '970',
        'Kotitalouksien toiminta kotitalousty\u00f6ntekij\u00f6iden ty\u00f6nantajina'
    ],
    [
        '9700',
        'Kotitalouksien toiminta kotitalousty\u00f6ntekij\u00f6iden ty\u00f6nantajina'
    ],
    ['0164', 'Siementen k\u00e4sittely kasvinviljely\u00e4 varten'],
    [
        '7712',
        'Kuorma-autojen ja muiden raskaiden ajoneuvojen vuokraus ja leasing'
    ],
    ['1419', 'Muiden vaatteiden ja asusteiden valmistus'],
    ['1092', 'Lemmikkiel\u00e4inten ruokien valmistus'],
    ['8531', 'Ylemm\u00e4n perusasteen koulutus ja lukiokoulutus'],
    ['6511', 'Henkivakuutustoiminta'],
    [
        '4723',
        'Kalan, \u00e4yri\u00e4isten ja nilvi\u00e4isten v\u00e4hitt\u00e4iskauppa'
    ],
    ['2311', 'Tasolasin valmistus'],
    ['1084', 'Mausteiden ja maustekastikkeiden valmistus'],
    ['3513', 'S\u00e4hk\u00f6n jakelu'],
    ['2342', 'Keraamisten saniteettikalusteiden valmistus'],
    ['4721', 'Hedelmien, marjojen ja vihannesten v\u00e4hitt\u00e4iskauppa'],
    ['2014', 'Muiden orgaanisten peruskemikaalien valmistus'],
    ['582', 'Ohjelmistojen kustantaminen'],
    ['5829', 'Muu ohjelmistojen kustantaminen'],
    ['4726', 'Tupakkatuotteiden v\u00e4hitt\u00e4iskauppa'],
    [
        '4611',
        'Maatalousraaka-aineiden, el\u00e4vien el\u00e4inten, tekstiiliraaka-aineiden sek\u00e4 puolivalmisteiden agentuuritoiminta'
    ],
    ['1031', 'Perunoiden jalostus ja s\u00e4il\u00f6nt\u00e4'],
    [
        '6611',
        'P\u00f6rssitoiminta ja rahoitusmarkkinoiden hallinnolliset tukipalvelut'
    ],
    ['852', 'Alemman perusasteen koulutus'],
    ['8520', 'Alemman perusasteen koulutus'],
    ['4212', 'Rautateiden ja metrolinjojen rakentaminen'],
    ['3211', 'Kolikoiden ja mitalien valmistus'],
    ['5913', 'Elokuvien, videoiden ja televisio-ohjelmien levitys'],
    ['4665', 'Toimitilakalusteiden tukkukauppa'],
    ['619', 'Muut televiestint\u00e4palvelut'],
    ['6190', 'Muut televiestint\u00e4palvelut'],
    ['4789', 'Muiden tavaroiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'],
    ['4637', 'Kahvin, teen, kaakaon ja mausteiden tukkukauppa'],
    ['2894', 'Tekstiili-, vaate- ja nahkateollisuuden koneiden valmistus'],
    ['501', 'Meri- ja rannikkovesiliikenteen henkil\u00f6kuljetus'],
    ['5010', 'Meri- ja rannikkovesiliikenteen henkil\u00f6kuljetus'],
    ['3313', 'Elektronisten ja optisten laitteiden korjaus ja huolto'],
    ['1621', 'Vaneriviilun ja puupaneelien valmistus'],
    ['4765', 'Pelien ja leikkikalujen v\u00e4hitt\u00e4iskauppa'],
    ['2015', 'Lannoitteiden ja typpiyhdisteiden valmistus'],
    ['19', 'Koksin ja jalostettujen \u00f6ljytuotteiden valmistus'],
    ['192', 'Jalostettujen \u00f6ljytuotteiden valmistus'],
    ['1920', 'Jalostettujen \u00f6ljytuotteiden valmistus'],
    [
        '242',
        'Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus ter\u00e4ksest\u00e4'
    ],
    [
        '2420',
        'Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus ter\u00e4ksest\u00e4'
    ],
    ['9101', 'Kirjastojen ja arkistojen toiminta'],
    ['2453', 'Kevytmetallien valu'],
    ['1393', 'Mattojen valmistus'],
    ['1723', 'Paperikauppatavaroiden valmistus'],
    ['2822', 'Nosto- ja siirtolaitteiden valmistus'],
    [
        '1101',
        'Alkoholijuomien tislaus ja sekoittaminen; etyylialkoholin valmistus k\u00e4ymisteitse'
    ],
    ['09', 'Kaivostoimintaa palveleva toiminta'],
    ['099', 'Muuta kaivostoimintaa ja louhintaa palveleva toiminta'],
    ['0990', 'Muuta kaivostoimintaa ja louhintaa palveleva toiminta'],
    ['142', 'Turkisvaatteiden ja -tuotteiden valmistus'],
    ['1420', 'Turkisvaatteiden ja -tuotteiden valmistus'],
    ['3101', 'Konttori- ja myym\u00e4l\u00e4kalusteiden valmistus'],
    ['2592', 'Kevytmetallipakkausten valmistus'],
    ['263', 'Viestint\u00e4laitteiden valmistus'],
    ['2630', 'Viestint\u00e4laitteiden valmistus'],
    ['0142', 'Muun nautakarjan ja puhvelien kasvatus'],
    [
        '2732',
        'Muiden elektronisten ja s\u00e4hk\u00f6johtojen sek\u00e4 -kaapelien valmistus'
    ],
    ['4648', 'Kellojen ja korujen tukkukauppa'],
    [
        '7733',
        'Toimistokoneiden ja -laitteiden sek\u00e4 tietokoneiden vuokraus ja leasing'
    ],
    ['4635', 'Tupakkatuotteiden tukkukauppa'],
    ['9604', 'Kylpylaitokset, saunat, solariumit yms. palvelut'],
    ['4331', 'Rappaus'],
    ['3831', 'Romujen purkaminen'],
    ['2053', 'Eteeristen \u00f6ljyjen valmistus'],
    ['2391', 'Hiontatuotteiden valmistus'],
    [
        '2811',
        'Moottorien ja turbiinien valmistus  pl. lentokoneiden ja ajoneuvojen moottorit)'
    ],
    [
        '9104',
        'Kasvitieteellisten puutarhojen, el\u00e4intarhojen ja luonnonpuistojen toiminta'
    ],
    ['352', 'Kaasun tuotanto; kaasumaisten polttoaineiden jakelu putkiverkossa'],
    ['3521', 'Kaasun tuotanto'],
    ['1395', 'Kuitukankaiden ja kuitukangastuotteiden valmistus  pl. vaatteet)'],
    ['023', 'Luonnon tuotteiden keruu  pl. polttopuu)'],
    ['0230', 'Luonnon tuotteiden keruu  pl. polttopuu)'],
    [
        '2815',
        'Laakereiden, hammaspy\u00f6rien, vaihteisto- ja ohjauselementtien valmistus'
    ],
    ['262', 'Tietokoneiden ja niiden oheislaitteiden valmistus'],
    ['2620', 'Tietokoneiden ja niiden oheislaitteiden valmistus'],
    ['1439', 'Muiden neulevaatteiden valmistus'],
    ['495', 'Putkijohtokuljetus'],
    ['4950', 'Putkijohtokuljetus'],
    ['1811', 'Sanomalehtien painaminen'],
    ['2221', 'Muovilevyjen, -kalvojen, -putkien ja -profiilien valmistus'],
    ['C', 'Teollisuus'],
    ['171', 'Massan, paperin, kartongin ja pahvin valmistus'],
    ['1711', 'Massan valmistus'],
    ['1814', 'Sidonta ja siihen liittyv\u00e4t palvelut'],
    ['M', 'Ammatillinen, tieteellinen ja tekninen toiminta'],
    ['2042', 'Hajuvesien ja hygieniatuotteiden valmistus'],
    ['1105', 'Oluen valmistus'],
    [
        '255',
        'Metallin takominen, puristaminen, meist\u00e4minen ja valssaus; jauhemetallurgia'
    ],
    [
        '2550',
        'Metallin takominen, puristaminen, meist\u00e4minen ja valssaus; jauhemetallurgia'
    ],
    ['4623', 'El\u00e4vien el\u00e4inten tukkukauppa'],
    ['6621', 'Riskin- ja vahingonarviointi'],
    ['1082', 'Kaakaon, suklaan ja makeisten valmistus'],
    ['1032', 'Hedelm\u00e4-, marja- ja kasvismehujen valmistus'],
    ['5812', 'Hakemistojen ja postituslistojen julkaiseminen'],
    ['2013', 'Muiden ep\u00e4orgaanisten peruskemikaalien valmistus'],
    ['2593', 'Metallilankatuotteiden, ketjujen ja jousien valmistus'],
    ['5914', 'Elokuvien esitt\u00e4minen'],
    ['203', 'Maalien, lakan, painov\u00e4rien yms. valmistus'],
    ['2030', 'Maalien, lakan, painov\u00e4rien yms. valmistus'],
    ['1712', 'Paperin, kartongin ja pahvin valmistus'],
    ['21', 'L\u00e4\u00e4keaineiden ja l\u00e4\u00e4kkeiden valmistus'],
    [
        '212',
        'L\u00e4\u00e4kkeiden ja muiden l\u00e4\u00e4kevalmisteiden valmistus'
    ],
    [
        '2120',
        'L\u00e4\u00e4kkeiden ja muiden l\u00e4\u00e4kevalmisteiden valmistus'
    ],
    ['2319', 'Muu lasin valmistus ja muokkaus ml. tekniset lasituotteet'],
    ['9525', 'Kellojen ja korujen korjaus'],
    ['531', 'Postin yleispalvelu'],
    ['5310', 'Postin yleispalvelu'],
    ['602', 'Televisio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen'],
    ['6020', 'Televisio-ohjelmien tuottaminen ja l\u00e4hett\u00e4minen'],
    ['5821', 'Tietokonepelien kustantaminen'],
    ['1052', 'J\u00e4\u00e4tel\u00f6n valmistus'],
    ['191', 'Koksituotteiden valmistus'],
    ['1910', 'Koksituotteiden valmistus'],
    ['3522', 'Kaasumaisten polttoaineiden jakelu putkiverkossa'],
    ['4313', 'Koeporaus'],
    [
        '774',
        'Henkisen omaisuuden ja vastaavien tuotteiden leasing  pl. tekij\u00e4noikeuden suojaamat teokset)'
    ],
    [
        '7740',
        'Henkisen omaisuuden ja vastaavien tuotteiden leasing  pl. tekij\u00e4noikeuden suojaamat teokset)'
    ],
    ['253', 'H\u00f6yrykattiloiden valmistus  pl. keskusl\u00e4mmityslaitteet)'],
    ['2530', 'H\u00f6yrykattiloiden valmistus  pl. keskusl\u00e4mmityslaitteet)'],
    ['2841', 'Metallin ty\u00f6st\u00f6koneiden valmistus'],
    ['07', 'Metallimalmien louhinta'],
    ['072', 'V\u00e4rimetallimalmien louhinta'],
    ['0729', 'Muiden v\u00e4rimetallimalmien louhinta'],
    ['2591', 'Metallipakkausten ja -astioiden valmistus'],
    ['1012', 'Siipikarjan teurastus ja lihan s\u00e4ilyvyysk\u00e4sittely'],
    ['854', 'Korkea-asteen koulutus'],
    ['8542', 'Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa'],
    ['1102', 'Viinin valmistus ryp\u00e4leist\u00e4'],
    ['1103', 'Siiderin, hedelm\u00e4- ja marjaviinien valmistus'],
    ['492', 'Rautateiden tavaraliikenne'],
    ['4920', 'Rautateiden tavaraliikenne'],
    ['2441', 'Jalometallien valmistus'],
    ['512', 'Lentoliikenteen tavarankuljetus ja avaruusliikenne'],
    ['5121', 'Lentoliikenteen tavarankuljetus'],
    ['0899', 'Muualla luokittelematon kaivostoiminta ja louhinta'],
    ['272', 'Paristojen ja akkujen valmistus'],
    ['2720', 'Paristojen ja akkujen valmistus'],
    [
        '4782',
        'Tekstiilien, vaatteiden ja jalkineiden v\u00e4hitt\u00e4iskauppa kojuista ja toreilla'
    ],
    ['2932', 'Muiden osien ja tarvikkeiden valmistus moottoriajoneuvoihin'],
    ['1412', 'Ty\u00f6vaatteiden valmistus'],
    ['1511', 'Turkisten ja nahan muokkaus ja v\u00e4rj\u00e4ys'],
    ['309', 'Muualla luokittelematon kulkuneuvojen valmistus'],
    ['3099', 'Muiden muualla luokittelemattomien kulkuneuvojen valmistus'],
    ['N', 'Hallinto- ja tukipalvelutoiminta'],
    ['233', 'Keraamisten rakennusaineiden valmistus'],
    ['2331', 'Keraamisten tiilien ja laattojen valmistus'],
    ['1414', 'Alusvaatteiden valmistus'],
    ['2454', 'Muiden v\u00e4rimetallien valu'],
    ['3092', 'Polkupy\u00f6rien ja invalidiajoneuvojen valmistus'],
    ['8423', 'Oikeustoimi'],
    ['8621', 'Terveyskeskus- ja vastaavat yleisl\u00e4\u00e4k\u00e4ripalvelut'],
    ['1394', 'Purjelankojen, nuoran, sidelangan ja verkkojen valmistus'],
    ['0128', 'Mauste-, aromi-, rohdos- ja l\u00e4\u00e4kekasvien viljely'],
    ['1411', 'Nahkavaatteiden valmistus'],
    ['9004', 'Taidelaitosten toiminta'],
    ['2442', 'Alumiinin valmistus'],
    ['1391', 'Neulosten valmistus'],
    [
        '266',
        'S\u00e4teilylaitteiden sek\u00e4 elektronisten l\u00e4\u00e4kint\u00e4- ja terapialaitteiden valmistus'
    ],
    [
        '2660',
        'S\u00e4teilylaitteiden sek\u00e4 elektronisten l\u00e4\u00e4kint\u00e4- ja terapialaitteiden valmistus'
    ],
    ['1106', 'Maltaiden valmistus'],
    ['2364', 'Muurauslaastin valmistus'],
    ['1104', 'Muiden tislaamattomien juomien valmistus k\u00e4ymisteitse'],
    ['2332', 'Poltettujen tiilien ja muun rakennuskeramiikan valmistus'],
    ['F', 'Rakentaminen'],
    ['8424', 'Poliisitoimi ja rajojen vartiointi'],
    ['2824', 'Voimak\u00e4ytt\u00f6isten k\u00e4sity\u00f6kalujen valmistus'],
    ['1083', 'Teen ja kahvin valmistus'],
    ['2812', 'Hydraulisten voimalaitteiden valmistus'],
    ['8422', 'Maanpuolustus'],
    ['104', 'Kasvi- ja el\u00e4in\u00f6ljyjen ja -rasvojen valmistus'],
    [
        '1041',
        'Kasvi- ja el\u00e4inper\u00e4isten \u00f6ljyjen ja -rasvojen valmistus  pl. ravintorasvat)'
    ],
    ['2612', 'Kalustettujen piirilevyjen valmistus'],
    ['254', 'Aseiden ja ammusten valmistus'],
    ['2540', 'Aseiden ja ammusten valmistus'],
    ['05', 'Kivihiilen ja ruskohiilen kaivu'],
    ['051', 'Kivihiilen kaivu'],
    ['0510', 'Kivihiilen kaivu'],
    ['4725', 'Alkoholi- ja muiden juomien v\u00e4hitt\u00e4iskauppa'],
    ['2572', 'Lukkojen ja saranoiden valmistus'],
    ['1729', 'Muiden paperi-, kartonki- ja pahvituotteiden valmistus'],
    ['202', 'Torjunta-aineiden ja muiden maatalouskemikaalien valmistus'],
    ['2020', 'Torjunta-aineiden ja muiden maatalouskemikaalien valmistus'],
    ['2051', 'R\u00e4j\u00e4hdysaineiden valmistus'],
    ['1396', 'Teknisten ja teollisuustekstiilien valmistus'],
    ['1086', 'Homogenoitujen ravintovalmisteiden ja dieettiruokien valmistus'],
    ['9512', 'Viestint\u00e4laitteiden korjaus'],
    ['2313', 'Onton lasitavaran valmistus'],
    ['1622', 'Asennettavien parkettilevyjen valmistus'],
    ['3317', 'Muiden kulkuneuvojen korjaus ja huolto'],
    ['132', 'Kankaiden kudonta'],
    ['1320', 'Kankaiden kudonta'],
    [
        '3822',
        'Ongelmaj\u00e4tteen k\u00e4sittely, loppusijoitus ja h\u00e4vitt\u00e4minen'
    ],
    ['613', 'Satelliittiviestint\u00e4'],
    ['6130', 'Satelliittiviestint\u00e4'],
    ['2369', 'Muiden betoni-, kipsi- ja sementtituotteiden valmistus'],
    ['1724', 'Tapettien valmistus'],
    [
        '0124',
        'Omenoiden, kirsikoiden, luumujen ym. kota- ja kivihedelmien viljely'
    ],
    ['06', 'Raaka\u00f6ljyn ja maakaasun tuotanto'],
    ['062', 'Maakaasun tuotanto'],
    ['0620', 'Maakaasun tuotanto'],
    ['851', 'Esiasteen koulutus'],
    ['8510', 'Esiasteen koulutus'],
    ['3091', 'Moottoripy\u00f6rien valmistus'],
    ['3291', 'Luutien ja harjojen valmistus'],
    ['2452', 'Ter\u00e4ksen valu'],
    [
        '2752',
        'S\u00e4hk\u00f6ist\u00e4m\u00e4tt\u00f6mien kodinkoneiden valmistus'
    ],
    ['2052', 'Liimojen valmistus'],
    ['211', 'L\u00e4\u00e4keaineiden valmistus'],
    ['2110', 'L\u00e4\u00e4keaineiden valmistus'],
    ['3812', 'Ongelmaj\u00e4tteen keruu'],
    [
        '9103',
        'Historiallisten n\u00e4ht\u00e4vyyksien, rakennusten ja vastaavien kohteiden toiminta'
    ],
    ['2343', 'Keraamisten eristystuotteiden valmistus'],
    ['303', 'Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus'],
    ['3030', 'Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus'],
    ['0163', 'Sadon jatkok\u00e4sittely'],
    [
        '1072',
        'N\u00e4kkileiv\u00e4n ja keksien valmistus; s\u00e4ilyvien leivonnaisten ja kakkujen valmistus'
    ],
    ['2594', 'Kiinnittimien ja ruuvituotteiden valmistus'],
    ['2344', 'Muiden teknisten keraamisten tuotteiden valmistus'],
    ['2011', 'Teollisuuskaasujen valmistus'],
    ['235', 'Sementin, kalkin ja kipsin valmistus'],
    ['2352', 'Kalkin ja kipsin valmistus'],
    ['H', 'Kuljetus ja varastointi'],
    ['2017', 'Synteettisen kumiraaka-aineen valmistus'],
    ['091', 'Raaka\u00f6ljyn ja maakaasun tuotantoa palveleva toiminta'],
    ['0910', 'Raaka\u00f6ljyn ja maakaasun tuotantoa palveleva toiminta'],
    ['302', 'Raideliikenteen kulkuneuvojen valmistus'],
    ['3020', 'Raideliikenteen kulkuneuvojen valmistus'],
    ['2351', 'Sementin valmistus'],
    ['2314', 'Lasikuitujen valmistus'],
    ['1062', 'T\u00e4rkkelyksen ja t\u00e4rkkelystuotteiden valmistus'],
    [
        '98',
        'Kotitalouksien eriytt\u00e4m\u00e4t\u00f6n toiminta tavaroiden ja palvelujen tuottamiseksi omaan k\u00e4ytt\u00f6\u00f6n'
    ],
    [
        '982',
        'Kotitalouksien eriytt\u00e4m\u00e4t\u00f6n toiminta palvelujen tuottamiseksi omaan k\u00e4ytt\u00f6\u00f6n'
    ],
    [
        '9820',
        'Kotitalouksien eriytt\u00e4m\u00e4t\u00f6n toiminta palvelujen tuottamiseksi omaan k\u00e4ytt\u00f6\u00f6n'
    ],
    ['8541', 'Korkea-asteen koulutus  pl. yliopistot ja ammattikorkeakoulut)'],
    ['12', 'Tupakkatuotteiden valmistus'],
    ['120', 'Tupakkatuotteiden valmistus'],
    ['1200', 'Tupakkatuotteiden valmistus'],
    ['2443', 'Lyijyn, sinkin ja tinan valmistus'],
    [
        '1073',
        'Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus'
    ],
    [
        '2823',
        'Konttorikoneiden ja -laitteiden valmistus  pl. tietokoneet ja niiden oheislaitteet)'
    ],
    ['1081', 'Sokerin valmistus'],
    ['2731', 'Optisten kuitukaapelien valmistus'],
    ['243', 'Muu ter\u00e4ksen jalostus'],
    ['2431', 'Raudan ja ter\u00e4ksen kylm\u00e4vet\u00e4minen'],
    ['653', 'El\u00e4kevakuutustoiminta'],
    ['6530', 'El\u00e4kevakuutustoiminta'],
    ['232', 'Tulenkest\u00e4vien keraamisten tuotteiden valmistus'],
    ['2320', 'Tulenkest\u00e4vien keraamisten tuotteiden valmistus'],
    ['071', 'Rautamalmien louhinta'],
    ['0710', 'Rautamalmien louhinta'],
    ['2652', 'Kellojen valmistus'],
    ['3103', 'Patjojen valmistus'],
    ['0891', 'Kemiallisten ja lannoitemineraalien louhinta'],
    ['3523', 'Kaasun kauppa putkiverkossa'],
    ['2433', 'Kylm\u00e4muovaus tai kylm\u00e4taitto'],
    ['2349', 'Muiden keramiikkatuotteiden valmistus'],
    [
        'G',
        'Tukku- ja v\u00e4hitt\u00e4iskauppa; moottoriajoneuvojen ja moottoripy\u00f6rien korjaus'
    ],
    ['0112', 'Riisin viljely'],
    ['803', 'Etsiv\u00e4toiminta'],
    ['8030', 'Etsiv\u00e4toiminta'],
    ['0144', 'Kamelien ja kameliel\u00e4inten kasvatus'],
    ['2432', 'Rainan kylm\u00e4valssaus'],
    ['0116', 'Kuitukasvien viljely'],
    ['1042', 'Margariinin ja sen kaltaisten ravintorasvojen valmistus'],
    ['206', 'Tekokuitujen valmistus'],
    ['2060', 'Tekokuitujen valmistus'],
    ['6411', 'Keskuspankkitoiminta'],
    ['2445', 'Muiden v\u00e4rimetallien valmistus'],
    ['652', 'J\u00e4lleenvakuutustoiminta'],
    ['6520', 'J\u00e4lleenvakuutustoiminta'],
    ['Q', 'Terveys- ja sosiaalipalvelut']
];
