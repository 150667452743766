import _ from 'lodash';
const fields = {
    value: 'Yrityksesi arvo on laskettu verottajan käytännön mukaan tuottoarvon ja substanssiarvon perusteella. Jos tuottoarvo on suurempi kuin substanssiarvo, yrityksen arvo on tuottoarvon ja substanssiarvon keskiarvo. Jos puolestaan substanssiarvo on suurempi (tai yhtä suuri) kuin tuottoarvo, yrityksen arvo on sama kuin substanssiarvo. Substanssiarvo saadaan, kun yrityksesi varoista vähennetään velat. Tuottoarvo puolestaan saadaan laskemalla kolmen edellisen tilikauden nettotuloksen keskiarvo, jota pääomitetaan 15 prosentin laskentakorkokannalla. Huomioithan, että kyseessä on suuntaa antava arvio, joka ei ota huomioon muun muassa yhtiön toimintaympäristöä tai kasvumahdollisuuksia. Lopulliseen hinnanmääritykseen on hyvä pyytää ulkopuolisen asiantuntijan apua.',
    digitality: [
        { min: 0, max: 40, text: 'Digitalisaatio muuttaa voimakkaasti toimialoja ja se tarjoaa suuria mahdollisuuksia. Onkin tärkeää varmistaa, että yrityksesi pysyy kehityksessä mukana. Digitalisaatiossa mittarina toimivat uudet teknologiat ja niiden käyttö verkkopalveluissasi. Jos haluat nostaa digitaalista valmiuttasi, voit ottaa käyttöön uusia teknologioita parantaaksesi yrityksesi digitaalisuutta. On hyvä lähteä liikkeelle perusasioista, joihin kuuluu muun muassa yrityksesi internet-sivujen toiminnan varmistaminen niin, että sivut toimivat sekä tietokoneella että erilaisilla mobiililaitteilla.' },
        { min: 40, max: 80, text: 'Digitalisaatio muuttaa voimakkaasti toimialoja ja se tarjoaa suuria mahdollisuuksia. Onkin tärkeää varmistaa, että yrityksesi pysyy kehityksessä mukana. Digitalisaation mittareina toimivat uudet teknologiat ja niiden käyttö verkkopalveluissasi. Digitaalinen valmiutesi on reilusti yli keskiarvoa parempi, joten yrityksesi on digitaalisesti hyvällä tasolla. Voit parantaa digitaalista valmiuttasi ottamalla käyttöön uusia teknologisia ratkaisuja web-sivuillesi. Teknologioita ovat esimerkiksi Nginx, React sekä sosiaalisen median integraatiot.' },
        { min: 80, max: Number.MAX_VALUE, text: 'Digitalisaatio muuttaa voimakkaasti toimialoja ja se tarjoaa suuria mahdollisuuksia. Onkin tärkeää varmistaa, että yrityksesi pysyy kehityksessä mukana. Digitalisaation mittareina toimivat uudet teknologiat ja niiden käyttö verkkopalveluissasi. Digitaalinen valmiutesi on erinomainen. Varmistaaksesi jatkuvan digitaalisen edelläkävijyyden suosittelemme seuraamaan uusien teknologioiden tuloa ja ottamaan sinulle hyödylliset käyttöön web-palveluissasi.' }
    ],
    sociality: [
        { min: 0, max: 40, text: 'Sosiaalinen media on suora yhteys asiakkaaseesi. Se on usein myös hyvin kustannustehokas kanava mainostaa ja saada asiakaskontakteja. Mainontaa tehdessäsi muista kohdentaa kampanjasi, niin pienelläkin budjetilla on mahdollista saavuttaa tuloksia. Mainonnan lisäksi sinun tulisi huolehtia yrityksesi yleisestä näkymisestä sosiaalisessa mediassa ja panostaa vähintään yhteen palveluun. Palveluita ovat esimerkiksi Twitter, Facebook ja YouTube.' },
        { min: 40, max: 80, text: 'Sosiaalisen median valmiutesi on hyvällä tasolla. Sosiaalinen media on suora yhteys asiakkaaseesi. Se on usein myös hyvin kustannustehokas kanava mainostaa ja saada asiakaskontakteja. Mainontaa tehdessäsi muista kohdentaa kampanjasi, niin pienelläkin budjetilla on mahdollista saavuttaa tuloksia. Voit parantaa sosiaalisen median näkyvyyttäsi pitämällä huolen, että yrityksesi näkyy esimerkiksi seuraavissa palveluissa: Twitter, Facebook ja YouTube.' },
        { min: 80, max: Number.MAX_VALUE, text: 'Sosiaalinen media on usein hyvin kustannustehokas kanava mainostaa ja saada asiakaskontakteja. Mainontaa tehdessäsi muista kohdentaa kampanjasi, niin pienelläkin budjetilla on mahdollista saavuttaa tuloksia. Olet erittäin aktiivinen ja tunnettu toimija sosiaalisessa mediassa. Jatka samaan malliin ja seuraa sosiaalisen median trendejä.' }
    ],
    marketability: [
        { min: 0, max: 40, text: 'Sisältömarkkinoinnin lähtökohtana on oman asiakaskunnan ymmärtäminen. On tärkeää ymmärtää keitä asiakkaat ovat, mitä he tarvitsevat, miten he käyttäytyvät ja mistä he ovat kiinnostuneita. Asiakkaat käyttävät nykyisin paljon internetiä, joten on yrityksesi kannalta ensiarvoisen tärkeää, että yrityksesi internet-sivut ovat myös löydettävissä. Suosittelemmekin varmistamaan sivujesi löydettävyyden hakukoneilla. Suosittelemme lisäksi jatkuvaa sisältömarkkinointia eri sosiaalisissa kanavissa. Esimerkiksi Facebookissa.' },
        { min: 40, max: 80, text: 'Tasosi on hyvä suhteessa keskiarvoon Suomessa. Sisältömarkkinoinnin lähtökohtana on oman asiakaskunnan ymmärtäminen. On tärkeää ymmärtää keitä asiakkaat ovat, mitä he tarvitsevat, miten he käyttäytyvät ja mistä he ovat kiinnostuneita. Asiakkaat käyttävät nykyisin paljon internetiä, joten on yrityksesi kannalta ensiarvoisen tärkeää, että yrityksesi internet-sivut ovat myös löydettävissä. Suosittelemmekin varmistamaan sivujesi löydettävyyden hakukoneilla. Suosittelemme lisäksi jatkuvaa sisältömarkkinointia eri sosiaalisissa kanavissa esimerkiksi Facebookissa.' },
        { min: 80, max: Number.MAX_VALUE, text: 'Tasosi on erittäin hyvä suhteessa keskiarvoon Suomessa. Sisältömarkkinoinnin lähtökohtana on oman asiakaskunnan ymmärtäminen. On tärkeää ymmärtää keitä asiakkaat ovat, mitä he tarvitsevat, miten he käyttäytyvät ja mistä he ovat kiinnostuneita. Asiakkaat käyttävät nykyisin paljon internetiä, joten on yrityksesi kannalta ensiarvoisen tärkeää, että yrityksesi internet-sivut ovat myös löydettävissä. Suosittelemmekin varmistamaan sivujesi löydettävyyden hakukoneilla. Suosittelemme lisäksi jatkuvaa sisältömarkkinointia eri sosiaalisissa kanavissa esimerkiksi Facebookissa.' }
    ],
    liquidity: [
        { min: 0, max: 30, text: 'Yrityksesi maksuvalmius on heikko ns.quick ratiolla mitattuna. Heikko maksuvalmius voi tarkoittaa sitä, että yrityksesi ei ole riittävästi varautunut mahdollisiin yllättäviin tilanteisiin.On kuitenkin hyvä huomata, että heikko quick ratio ei ole välttämättä hälyttävä merkki.Maksuvalmius voi olla heikko esimerkiksi tilanteissa, joissa joudutaan sitomaan runsaasti pääomaa varastoihin tai laitteistoihin.' },
        { min: 30, max: 150, text: 'Yrityksesi maksuvalmius on hyvä ns. quick ratiolla mitattuna. Hyvä maksuvalmius tarkoittaa sitä, että yritykselläsi on riittävä kyky selviytyä lyhytaikaisista veloista. Yritystoiminnan jatkuvuuden kannalta on tärkeää, että maksuvalmiutesi pysyy myös jatkossa vähintään hyvällä tasolla. Maksuvalmiutta voidaan parantaa esimerkiksi sitomalla mahdollisimman vähän pääomaa varastoon.' },
        { min: 150, max: Number.MAX_VALUE, text: 'Yrityksesi maksuvalmius on erinomainen ns.quick ratiolla mitattuna. Yritykselläsi on siis erinomainen kyky selviytyä lyhytaikaisista veloista.' }
    ],
    returnOnEquity: [
        { min: 0, max: 5, text: 'Yrityksesi kyky tuottaa voittoa omalle pääomalle on heikko. Tuottoprosenttia voi kasvattaa esimerkiksi nostamalla perusliiketoiminnan kannattavuutta, tehostamalla pääoman kiertoa tai kasvattamalla vieraan pääoman osuutta. On kuitenkin hyvä huomioida, että vieraan pääoman kasvattaminen lisää myös yritystoimintaasi kohdistuvia riskejä.' },
        { min: 5, max: 20, text: 'Yrityksesi kyky tuottaa voittoa omalle pääomalle on hyvä. Tuottoprosenttia voi kasvattaa esimerkiksi nostamalla perusliiketoiminnan kannattavuutta, tehostamalla pääoman kiertoa tai kasvattamalla vieraan pääoman osuutta. On kuitenkin hyvä huomioida, että vieraan pääoman kasvattaminen lisää myös yritystoimintaasi kohdistuvia riskejä.' },
        { min: 20, max: Number.MAX_VALUE, text: 'Yrityksesi kyky tuottaa voittoa omalle pääomalle on erinomainen. Huomioithan kuitenkin, että pienet pääomat saattavat joskus kasvattaa pääoman tuoton korkeaksi ja tämän vuoksi suosittelemme tarkastelemaan myös yrityksen euromääräistä tulosta ja sen tasoa.' }
    ],
    returnOnInvestment: [
        { min: 0, max: 3, text: 'Yrityksesi kyky tuottaa voittoa sekä omalle että vieraalle pääomalle on heikko. Sijoitetun pääoman tuoton tulisi nousta selvästi lainakorkoja suuremmaksi. Huomioithan sen, että jos yrityksessäsi on tehty merkittäviä investointeja lähiaikoina ja nämä investoinnit eivät ole vielä tuoneet lisätuottoja yritykselle, saattaa sijoitetun pääoman tuotto tällöin antaa liian heikon kuvan yrityksen tilanteesta.' },
        { min: 3, max: 15, text: 'Yrityksesi kyky tuottaa voittoa sekä omalle että vieraalle pääomalle on hyvä. Käytännössä tämä tarkoittaa sitä, että yritystoimintasi on kannattavaa. Toisaalta pienet pääomat saattavat joskus kasvattaa pääoman tuoton korkeaksi ja tämän vuoksi suosittelemme tarkastelemaan myös yrityksen euromääräistä tulosta ja sen tasoa.Nykyistä korkeampi tuottoprosentti voisi mahdollisesti helpottaa muun muassa rahoituksen saantia.' },
        { min: 15, max: Number.MAX_VALUE, text: 'Yrityksesi kyky tuottaa voittoa sekä omalle että vieraalle pääomalle on erinomainen. Käytännössä tämä tarkoittaa sitä, että yritystoimintasi on kannattavaa. Toisaalta pienet pääomat saattavat joskus kasvattaa pääoman tuoton korkeaksi ja tämän vuoksi suosittelemme tarkastelemaan myös yrityksen euromääräistä tulosta ja sen tasoa.' }
    ],
    equityToAssetsRatio: [
        { min: 0, max: 15, text: 'Omavaraisuusasteesi on heikko, jolloin yrityksesi kyky selviytyä tappiollisista jaksoista ja pitkäaikaisista sitoumuksista voi olla heikolla pohjalla. Suosittelemme tarkkailemaan yrityksesi omavaraisuusastetta, sillä alhainen aste voi olla yritystoiminnalle riski tai vähintäänkin haittatekijä, kun rahoituskulut vievät mahdollisuuksia panostaa esimerkiksi markkinointiin. On kuitenkin hyvä huomata, että omavaraisuusaste on yleensä riippuvainen yrityksen iästä ja tämän vuoksi alalla vähän aikaa toiminut yritys on usein velkaantuneempi kuin alalla pidempään toiminut yritys.' },
        { min: 15, max: 50, text: 'Omavaraisuusasteesi on hyvä, jolloin yrityksesi kyky selviytyä tappiollisista jaksoista ja pitkäaikaisista sitoumuksista on vakaalla pohjalla. Korkeampi omavaraisuusaste mahdollistaa vähäisemmän riippuvuuden suhdanteista ja muista toimintaympäristön muutoksista. Näin esimerkiksi kilpailun kiristyessä korkean omavaraisuuden yritys kestää heikentynyttä kannattavuutta paremmin ja pidempään.' },
        { min: 50, max: Number.MAX_VALUE, text: 'Omavaraisuusasteesi on erinomainen, jolloin yrityksesi kyky selviytyä tappiollisista jaksoista ja pitkäaikaisista sitoumuksista on hyvin vakaalla pohjalla. Korkeampi omavaraisuusaste mahdollistaa vähäisemmän riippuvuuden suhdanteista ja muista toimintaympäristön muutoksista. Näin esimerkiksi kilpailun kiristyessä korkean omavaraisuuden yritys kestää heikentynyttä kannattavuutta paremmin ja pidempään.' }
    ]
};

export type PieTitle = keyof typeof fields;

export const getDescription = (field: PieTitle, value?: number | undefined) => {
    // if (value === null) return '';
    if (field === 'value') return fields.value;
    if ((value && value < 0) || value === undefined || value === null) return fields[field][0] ? fields[field][0].text : '';
    const rule = _.find(fields[field], (rule) => rule.min <= value && value < rule.max);
    return rule?.text;
};
