import _ from 'lodash';
import moment from 'moment';
import { VainuData } from '../types/state';
import FieldData from './FieldData';

// import vainuDataMock from '../mocks/vainuDataMock.json';
// import vainuPublicDataMock from '../mocks/vainuPublicDataMock.json'; 
// console.log('VAINU DATA.INDUSTRY', vainuDataMock.industryStatistics);
// console.log('PUBLIC VAINU DATA', vainuPublicDataMock);

const percentageOverAvg = (median: number, value: number) => {
    const percentageAboveBelow = Math.abs(1 - (Math.round((value / median) * 100) / 100));
    return percentageAboveBelow * 100;
};

const bizFieldIdToReadable = (fieldId: string) => {
    const objectFieldData: { [key: string]: any } = {};
    FieldData.forEach((field) => {
        objectFieldData[field[0]] = field[1];
    });
    return objectFieldData[fieldId] || 'Ei tiedossa';
};

const mapFinancialSatetement = (s: any) => {
    if (!s) return {};
    let mapped: any = {
        equityToAssetsRatio: s.equity_to_assets_ratio,
        netIncome: (s.net_income_euro ? s.net_income_euro : (s.profit && s.turn_over_eur) ? parseFloat(s.profit) / 100 * parseFloat(s.turn_over_eur) : null),
        profit: s.profit,
        staff: s.staff_number,
        turnOver: s.turn_over_eur,
        year: s.year,
        document: s.document,
        liquidity: s.quick_ratio_percent,
        returnOnEquity: s.return_on_equity,
        returnOnInvestment: s.return_on_investment,
        value: s.total_equity
    };

    mapped = _.pickBy(mapped, (v) => v !== undefined || v !== null);
    mapped = _.mapValues(mapped, (v) => {
        const parsed = parseFloat(v);
        return !_.isNaN(parsed) ? parsed : v;
    });
    return mapped;
};
const filterMissing=(statement:any)=>statement?.net_income_euro !== null && statement?.turn_over_eur !==null


export const parseVainuData = (state: VainuData, data: any) => {
    let industryCode = data.industry_code || data.industry_code_5 || null;
    if (data.industry_codes) industryCode = data.industry_codes[0];
    if (_.isNumber(industryCode)) {
        industryCode = industryCode.toString();
    }

    const currentYear = parseInt(moment().format('YYYY'));
    let latestFinances;
    let previousFinances;
    //console.log(data.financial_statements);
    if (data.financial_statements) {
        const sortedFinancialStatements = data.financial_statements.sort((a: any, b: any) => {
            return a.year - b.year;
        }).filter(filterMissing)
        let latestAccountPerioidEnd = sortedFinancialStatements[sortedFinancialStatements.length - 1] && sortedFinancialStatements[sortedFinancialStatements.length - 1].account_period_end;
        let previousAccountPerioidEnd = sortedFinancialStatements[sortedFinancialStatements.length - 2] && sortedFinancialStatements[sortedFinancialStatements.length - 2].account_period_end;
        // console.log(sortedFinancialStatements[sortedFinancialStatements.length - 2]);
        // console.log(previousAccountPerioidEnd);
        if (latestAccountPerioidEnd) {
            latestFinances = sortedFinancialStatements[sortedFinancialStatements.length - 1];
            previousFinances = sortedFinancialStatements[sortedFinancialStatements.length - 2];
        } else if (previousAccountPerioidEnd && previousAccountPerioidEnd.split('-')[0] === (new Date()).getFullYear().toString() &&
            previousAccountPerioidEnd.split('-')[1] === '12') {
            latestFinances = sortedFinancialStatements[sortedFinancialStatements.length - 2];
            previousFinances = sortedFinancialStatements[sortedFinancialStatements.length - 3];
        } else if (previousAccountPerioidEnd) {
            previousFinances = sortedFinancialStatements[sortedFinancialStatements.length - 2];
        }
    }
    const last = mapFinancialSatetement(latestFinances);
    const previous = mapFinancialSatetement(previousFinances);
    const formInstanceId = _.join([state.identity.id, moment.utc().valueOf()], ':');

    let industryMedians;
    if (data.industryStatistics) {
        industryMedians = {
            netIncome: Math.floor(
                data.industryStatistics.turnOver.median *
                data.industryStatistics.profit.median / 100
            ),
            profit: data.industryStatistics.profit.median,
            staff: data.industryStatistics.staffNumber.median,
            turnOver: data.industryStatistics.turnOver.median,
            year: currentYear - 1,
            turnOverComparedToAvg: percentageOverAvg(
                data.industryStatistics.turnOver.median,
                last.turnOver
            )
        };
    }

    // Set default answers

    const formAnswers = {
        ...state.formAnswers,
        currentYearMissing: last.year !== (parseInt(moment().format('YYYY')) - 1),
        
        expectedTurnOver: {
            percentage: 0,
            calculated: last.turnOver,
            changed: false
        },
        expectedProfit: {
            percentage: 0,
            calculated: last.netIncome, // last.net_income_euro, ?? in old code
            changed: false
        },
        staff: {
            amount: last.staff,
            changed: false
        },
        // ok default values ??
        netIncome: {
            value: last.netIncome ?? null,
            changed: false,
        },
        turnOver: {
            value: last.turnOver ?? null,
            changed: false,
        }
    };
    let equityToAssetsRatio = last.equityToAssetsRatio ? last.equityToAssetsRatio : previous.equityToAssetsRatio || null;
    let liquidity = last.liquidity ? last.liquidity : previous.liquidity || null;
    let returnOnEquity = last.returnOnEquity ? last.returnOnEquity : previous.returnOnEquity || null;
    let returnOnInvestment = last.returnOnInvestment ? last.returnOnInvestment : previous.returnOnInvestment || null;
    let value = last.value ? last.value : previous.value || null;
    return {
        ...state,
        identity: {
            ...state.identity,
            name: data.company_name,
            formInstanceId,
            industryCode: industryCode || null,
            industryHumanReadable: bizFieldIdToReadable(industryCode),
            id: data.business_id.substring(0, data.business_id.length - 1) +
                '-' +
                data.business_id.substring(data.business_id.length - 1, data.business_id.length),
            fetched: true
        },
        finances: {
            previous: {
                ...state.finances.previous,
                ...previous
            },
            latest: {
                ...state.finances.latest,
                ...last
            },
            industry: {
                ...state.finances.industry,
                ...industryMedians
            },
            stats: {
                ...state.finances.stats,
                equityToAssetsRatio,
                liquidity,
                returnOnEquity,
                returnOnInvestment,
                value: value
            }
        },
        digitalization: {
            digitality: data.digitality || null,
            sociality: data.sociality || null,
            marketability: data.marketility || null
        },
        formAnswers,
    };
};