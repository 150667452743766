import React, { useState } from 'react';
import { DuetCard } from '@duetds/react';
import useWindowSize from '../../hooks/useWindowSize';
import PieCard from './PieCard';
import PieInfoBox from './PieInfoBox';
import { PieTitle } from '../../utils/Descriptions';
import usePieData from '../../hooks/usePieData';

export default function PieCharts() {
    const { getValidPieTitlesGroupA } = usePieData();
    const { isMobile, isPad, isDesktop } = useWindowSize();
    const [selectedTitleA, selectTitleA] = useState<PieTitle | undefined>();
    const [selectedTitleB, selectTitleB] = useState<PieTitle | undefined>();

    const handleSelectTitleA = React.useCallback((title?: PieTitle) => {
        selectTitleA(selectedTitleA === title ? undefined : title);
    }, [selectedTitleA]);

    const handleSelectTitleB = (title?: PieTitle) => {
        selectTitleB(selectedTitleB === title ? undefined : title);
    };

    const validTitlesGroupA = getValidPieTitlesGroupA();
    // For testing various lengths: validTitlesGroupA.pop(); 

    const validTitlesALength = validTitlesGroupA.length;

    // TODO: how to avoid extra rendering?
    // const FirstFourPies = React.memo(() => {
    //     return (<>
    //         {validTitlesGroupA.slice(0, 2).map((title) => <PieCard key={title} title={title} selectTitle={handleSelectTitleA} />)}
    //         {isPad && (selectedTitleA === validTitlesGroupA[0] || selectedTitleA === validTitlesGroupA[1]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
    //         {validTitlesGroupA.slice(2, 4).map((title) => (<PieCard key={title} title={title} selectTitle={handleSelectTitleA} />))}
    //         {isPad && (selectedTitleA === validTitlesGroupA[2] || selectedTitleA === validTitlesGroupA[3]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
    //     </>)
    // })

    return (
        <div>
            <DuetCard padding="small" heading="Keskeiset tunnusluvut">
                {!isMobile ? (
                    <>
                        {validTitlesALength < 3 && (
                            <div className="grid-columns-4">
                                {validTitlesGroupA.map((title) => <PieCard key={title} title={title} selectTitle={handleSelectTitleA} />)}
                                <PieInfoBox className="pie-info-desktop" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />
                            </div>
                        )}
                        {validTitlesALength > 2 && validTitlesALength < 5 && (
                            <div className="grid-columns-4">
                                {/* <FirstFourPies /> */}
                                {validTitlesGroupA.slice(0, 2).map((title) => <PieCard key={title} title={title} selectTitle={handleSelectTitleA} />)}
                                {isPad && (selectedTitleA === validTitlesGroupA[0] || selectedTitleA === validTitlesGroupA[1]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                                {validTitlesGroupA.slice(2, 4).map((title) => (<PieCard key={title} title={title} selectTitle={handleSelectTitleA} />))}
                                {isPad && (selectedTitleA === validTitlesGroupA[2] || selectedTitleA === validTitlesGroupA[3]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                                {isDesktop && <PieInfoBox className="pie-info-desktop" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                            </div>
                        )}
                        {validTitlesALength === 5 && (
                            <div className="grid-columns-4">
                                {/* <FirstFourPies /> */}
                                {validTitlesGroupA.slice(0, 2).map((title) => <PieCard key={title} title={title} selectTitle={handleSelectTitleA} />)}
                                {isPad && (selectedTitleA === validTitlesGroupA[0] || selectedTitleA === validTitlesGroupA[1]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                                {validTitlesGroupA.slice(2, 4).map((title) => <PieCard key={title} title={title} selectTitle={handleSelectTitleA} />)}
                                {isPad && (selectedTitleA === validTitlesGroupA[2] || selectedTitleA === validTitlesGroupA[3]) && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                                {isDesktop && selectedTitleA !== validTitlesGroupA[4] && <PieInfoBox className="pie-info-desktop" selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                                <PieCard title={validTitlesGroupA[4]} selectTitle={handleSelectTitleA} />
                            </div>
                        )}
                        {(((isPad && selectedTitleA === validTitlesGroupA[4])) || (isDesktop && selectedTitleA === validTitlesGroupA[4])) && <PieInfoBox selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />}
                    </>
                ) : validTitlesALength > 0
                    ? (
                        <>
                            <PieCard group="A" selectTitle={handleSelectTitleA} />
                            <PieInfoBox selectedTitle={selectedTitleA} selectTitle={handleSelectTitleA} />
                        </>
                    ) : <div>Ei Vainu-dataa saatavilla</div>}
            </DuetCard>
            <DuetCard padding="small" heading="Digitaalinen valmius">
                {!isMobile ? (
                    <>
                        <div className="grid-columns-4">
                            <PieCard title="digitality" selectTitle={handleSelectTitleB} disableToolTip/>
                            <PieCard title="marketability" selectTitle={handleSelectTitleB} disableToolTip/>
                            {isPad && (selectedTitleB === 'digitality' || selectedTitleB === 'marketability')
                                && <PieInfoBox className="pie-info-pad" selectedTitle={selectedTitleB} selectTitle={handleSelectTitleB} />}
                            <PieCard title="sociality" selectTitle={handleSelectTitleB} disableToolTip />
                        </div>
                        {(!isPad || selectedTitleB === 'sociality') && <PieInfoBox selectedTitle={selectedTitleB} selectTitle={handleSelectTitleB} />}
                    </>
                ) : (
                    <>
                        <PieCard group="B" selectTitle={handleSelectTitleB}  disableToolTip/>
                        <PieInfoBox selectedTitle={selectedTitleB} selectTitle={handleSelectTitleB} />
                    </>
                )}
            </DuetCard>
        </div>
    );
}

