
import React, { ReactElement, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useStateValue } from "./state/state";
import useInitState, { share, shareString } from "./hooks/useInitState";
import Header from "./components/Header";
import Questionnaire from "./components/Questionnaire/Questionnaire";
import Report from "./components/Report/Report";
import { DuetSpinner } from "@duetds/react";
import ErrorPage from "./components/ErrorPage";
import Invitation from "./components/admin/Invitation";
import Filters from "./components/admin/Filters"
import AdminHeader from "./components/admin/Header";
import CompanyView from "./components/admin/CompanyView";
import AdminReport from "./components/admin/ReportList";
import AdminLogin from "./components/admin/Login";
import axios from "axios";
import { setUser, logout} from './state/actions'
import ClientEmailLogin from "./components/ClientEmailLogin"


function Customer(){
  const { loading } = useInitState();
  const [state] = useStateValue();
  if(!shareString && !share) {
    return (
      <ClientEmailLogin title="Hei!" text="Syötä yrityksesi Y-tunnus ja sähköpostiosoitteesi aloittaaksesi kysely." />
    )
  }

  if (state.error) {
    return <ErrorPage />
  }
  
  return (
    <>
      <Header contact="Ota yhteyttä" region="Syke" />
      {loading ? (
        <DuetSpinner size="large" theme="default" />
      ) : state.page === 2 && !state.finished ? (
        <Questionnaire />
      ) : state.page === 3 || state.finished ? (
        <Report />
      ) : null}
    </>
  )
}

type PrivatePathProps ={
  children: ReactElement
}
function PrivatePath({children}: PrivatePathProps){
  const [state, dispatch] = useStateValue();


  useEffect(() =>{
    if(state.user === undefined )
    axios.get('/api/admin/getDetails',{withCredentials: true})
      .then((result) => {
        const {data: { id, username, areaCompanyId }} = result
        dispatch(setUser(id,username, areaCompanyId))
      })
      .catch(err=>{
        dispatch({type: 'LOGOUT', payload: {value: undefined }});
      })
    },[state.user, dispatch]);
    
  const navigate = useNavigate();
  if(state.user === undefined){
    navigate('/admin/login')
    return null
  }
  else{
    return children
  }
}

function Admin(){
  const [state] = useStateValue();
  return(
    <>
    {state.user ? <AdminHeader/> : null}
      <Routes>
      <Route path="login" element={<AdminLogin />}/>
      <Route path="search/*" element={<PrivatePath><CompanyView /></PrivatePath>} />
      <Route path="filter/*" element={<PrivatePath><Filters /></PrivatePath>} />
      <Route path="report" element={<PrivatePath><AdminReport /></PrivatePath>} />
      <Route path="send" element={<PrivatePath><Invitation /></PrivatePath>} />
      <Route index element={<PrivatePath><CompanyView /></PrivatePath>} />
    </Routes>
    </>
  )
}

function App() {
  const { loading } = useInitState();
  const [state] = useStateValue();


  if(state.error){
    return (
    <ErrorPage />
    )
  }


  return (
    <Routes>
      <Route path="/" element={<Customer/>}/>
      <Route path="/admin/*" element={<Admin/>} />
    </Routes>
  );
}

export default App
