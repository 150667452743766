import { DuetButton, DuetCard, DuetDatePicker, DuetGrid, DuetGridItem, DuetHeading, DuetLayout, DuetParagraph, DuetSelect, DuetSpacer } from '@duetds/react'
import { DuetTable } from '@duetds/react/commonjs'
import axios from 'axios'
import React, { useDebugValue, useEffect, useState } from 'react'
import { DateTime } from 'luxon';
import AreaCompanies from '../../utils/areaCompanies';
import { useNavigate } from 'react-router-dom';
import { identity, result } from 'lodash';
import { string } from 'yargs';
import Footer from '../Footer';
import { CSVLink } from 'react-csv'
import { type } from 'os';




type DateChangeEvent={
    detail:{
        value: string;
        valueAsDate: Date;
    }
}

type DateField =  {
    startDate?: {
        value: string;
        valueAsDate: Date;
    },
    endDate?: {
        value: string;
        valueAsDate: Date;
    },
}

type ListElement = {
    _id: string,
    businessId: string;
    finished: boolean;
    createdAt: string;
    data:{
        identity:{
            name: string;
            areaCompanyId: number;
            email: string;
        }
    }


}

export default function Report(){
    const initialStartDate = DateTime.now().minus({months: 1 });
    const initialEndDate = DateTime.now()
    const navigate = useNavigate()
    const [segmentFilterList, setSegmentFilterList] = useState<any[]>([{label:'Kyselyn täyttäneet yritykset', value: '1' }]);
    const [selectedFilter,setSelectedFilter] = useState<string>('1')
    const [csvData, setCsvData] = useState<any[]>([])
    const filterName = segmentFilterList.find((f)=>f.value==selectedFilter).label;
    

    const [dateField, setDateField] =useState<DateField>({
        startDate:{
            value: initialStartDate.toISODate(),
            valueAsDate: initialStartDate.toJSDate()
        },
        endDate:{
            value: initialEndDate.toISODate(),
            valueAsDate: initialEndDate.toJSDate()
        }
    })
    const [companyList, setCompanyList] = useState<ListElement[]>([])

    const onDateChange=(field: 'startDate' | 'endDate' ) =>(event:DateChangeEvent)=>{
        setCompanyList([]);
        setDateField({...dateField, [field]: event.detail})
    }
    
    useEffect(()=>{
        axios.get('/api/admin/segment/segments',{ withCredentials: true }).then(
            (response) => {
                const segments = response?.data.map(({name, _id}: {name:string, _id:string })=>({label: name, value: _id}))
                setSegmentFilterList([{label:'Kyselyn täyttäneet yritykset',value:'1',},...segments ]);
            });
    },[])

    const getForms = () => {
        if(dateField.endDate && dateField.endDate){
            if((dateField?.startDate === undefined )|| (dateField?.endDate === undefined)) return
            axios.post('/api/admin/getReports',{min: (new Date(dateField.startDate!.value)), max: (new Date(dateField.endDate!.value))},{withCredentials: true})
            .then(result=>{
                setCompanyList(result.data)
            })
            .catch((err) => {
                console.log('Error', err);
            })
        }
    };

    const getFormsWithFilter = async () => {
        if(dateField.endDate && dateField.endDate){
            if(selectedFilter==='1') return getForms();
            if((dateField?.startDate === undefined )|| (dateField?.endDate === undefined)) return
            axios.post('/api/admin/getReportsWithFilter',
            {
                min: (new Date(dateField.startDate!.value)),
                max: (new Date(dateField.endDate!.value)),
                segmentId: selectedFilter,
            },{
                withCredentials: true
            })
            .then(result=>{
              setCompanyList(result.data)
              return result.data
            })
            .catch((err) => {
                console.log('Error', err);
            })
        }
    };

    const getCSV = async () => {
       
        if(true && dateField.endDate && dateField.endDate){
           // if((dateField?.startDate === undefined )|| (dateField?.endDate === undefined)) return
            await axios.post('/api/admin/getReportsCsvWithFilter',
            {
                min: (new Date(dateField.startDate!.value)),
                max: (new Date(dateField.endDate!.value)),
                segmentId: selectedFilter === '1' ? undefined : selectedFilter,
            },{ withCredentials: true }).then( r => {
                setCsvData(r?.data?.replaceAll('"',''))
            }).catch(e=>{
                console.log('error',e)
            })
        }
        return 
    }

    // useEffect(()=>getFormsWithFilter,[selectedFilter,dateField]);

    const rowClicked=(id:string)=>()=>navigate(`/admin/search/${id}`);
   
    return  (
    <>
    <DuetLayout className='layout' >
        <div slot="main">
            <DuetHeading>Raportti</DuetHeading>
            <DuetParagraph>Selaa kyselyyn vastanneiden yritysten tietoja</DuetParagraph>
            <DuetGrid responsive>
            <DuetGridItem fill>
            <DuetDatePicker
                expand
                name="date"
                label="Valitse aikaväli"
                placeholder="pp.kk.vvvv"
                value={dateField.startDate?.value}
                onDuetChange={onDateChange('startDate')}
            />
            </DuetGridItem>
            <DuetGridItem fill>
            <DuetDatePicker
                expand
                name="date"
                label="Valitse aikaväli"
                placeholder="pp.kk.vvvv"
                value={dateField.endDate?.value}
                onDuetChange={onDateChange('endDate')}
            />
            </DuetGridItem>
            </DuetGrid>
            <DuetSpacer size='large'/>
            <DuetGrid responsive>
                <DuetGridItem>
                    <DuetButton onClick={()=>{
                        getFormsWithFilter()
                        getCSV();
                        }} variation="primary">Hae</DuetButton>
                </DuetGridItem>
            <DuetGridItem>
                <CSVLink
                    data={csvData}
                    >
                    <DuetButton
                        variation="secondary"
                        icon='action-download'
                        disabled={typeof csvData !== 'string'}
                        >
                            Lataa tiedot
                    </DuetButton>
                </CSVLink>
            </DuetGridItem>
            <DuetGridItem fill>
                <DuetSelect
                    expand
                    items={segmentFilterList}
                    labelHidden
                    value={selectedFilter}
                    onDuetChange={(event)=>{
                        setSelectedFilter(event.detail.value)
                        setCompanyList([]);
                        setCsvData([]);
                        }}>
                    
                </DuetSelect>
            </DuetGridItem>
            </DuetGrid>
            <DuetSpacer size='large'/>
            <DuetCard heading={`${filterName}`}>
                <DuetTable variation="striped" >
                <table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Y-tunnus</th>
                            <th>Alueyhtiö</th>
                            <th>Päivämäärä</th>
                            <th>Täytetty loppuun</th>
                        </tr>
                    </thead>
                    <tbody>
                    {companyList.map(({_id, businessId, finished, createdAt,data },i)=>{
                        const  { identity } = data ?? {}
                        const {name, areaCompanyId} = identity ?? {name: '-', areaCompanyId: '-'}
                    return(
                        <tr key={_id} onClick={rowClicked(_id)} className="reportlink-row">
                            <td><span className='maxchar'>{name}</span></td>
                            <td>{businessId}</td>
                            <td>{AreaCompanies?.[areaCompanyId]?.name ?? 'ei määritelty' }</td>
                            <td>{DateTime.fromISO(createdAt).toLocaleString()   }</td>
                            <td>{finished  ? 'Kyllä' : 'Ei'}</td>
                        </tr>)})}
                {companyList.length === 0 && <tr><td colSpan={5}>Ei näytettäviä yrityksiä</td></tr>}
                </tbody>
                </table>
                </DuetTable>
            </DuetCard>
        </div>

        </DuetLayout >
        <Footer />
        </>
        )
}
