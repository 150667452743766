export const risksQuestions = [
    {
        id: '1',
        label: 'Suomen talouden heikentyminen'
    },
    {
        id: "2",
        label: "Rahoituksen saatavuus",
    },
    {
        id: "3",
        label: "Kilpailun kiristyminen",
    },
    {
        id: "4",
        label: "Henkilöriskit (esim. sairastuminen)",
    },
    {
        id: "5",
        label: "Omaisuusvahingot",
    },
    {
        id: "6",
        label: "Toimeentulo yrittäjän sairastuessa",
    }
];

export const interestingEvents = [
    {
        id: "1",
        label: "Urheilu"
    },
    {
        id: "2",
        label: "Kulttuuri"
    },
    {
        id: "3",
        label: "Talous"
    },
    {
        id: "4",
        label: "Hyvinvointi"
    },
    {
        id: "5",
        label: "LähiTapiolan palvelukehitys"
    },
    {
        id: "6",
        label: "Verkotoituminen"
    },
];