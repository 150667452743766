import { DuetCard, DuetTable} from '@duetds/react'
import { colorSecondary, fontSizeMedium, fontFamilyText, colorPrimary } from '@duetds/tokens'
import { result } from 'lodash'
import { type } from 'os'
import React,{ useEffect, useRef } from 'react'
import { toNumberPresentation } from './utils'
import { SalesAndProfit } from './VainuGraphs'
import {VainuFinancialStatement } from './VainuView'



type EconomyData={
    financialStatements?: VainuFinancialStatement[]
}

export default function Economy({financialStatements}: EconomyData){


    if(financialStatements === undefined) return null;

    const turnOverChange = financialStatements.reduce((acc,cur) => {
        if( acc.prev ===undefined){
            return {prev: cur.turn_over_eur, result: [...acc.result, '-']}
        } else {
            let changePrefix = '';
            let colorToken;
            const change = (
                (Math.round(Number.parseInt(cur.turn_over_eur,10) / Number.parseInt(acc.prev,10) * 1000 - 1000)) / 10)
            if (change>0) {
                changePrefix ='+'
                colorToken="color-data-03"
            }
            if (change<0){
                changePrefix = ''
                colorToken="color-data-08"
            }

            return {prev: cur.turn_over_eur, result: [...acc.result, `${changePrefix}${change.toFixed(1)} %`]}
        }

    },({result: [], prev:undefined } as { prev?: string, result: string[]}))

    const turnOver = financialStatements.map(({year ,turn_over_eur}) => ({year: year.toString(), value: Number.parseFloat(turn_over_eur)}))
    const profits = financialStatements.map(({year ,net_income_euro}) => ({year: year.toString(), value: Number.parseFloat(net_income_euro)}))
    return(
        <DuetCard heading="Talous">
            <div className="grid">
            <SalesAndProfit title='Liikevaihto' data={turnOver} />
            <SalesAndProfit title='Liiketulos' data={profits}/>
            </div>
            <DuetTable>
                <table>
                <thead>
                <tr>
                    <th></th>
                    {financialStatements.map(({year}) =><th key={year}>{year}</th>)}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Liikevaihto</td>
                    {financialStatements.map(({year, turn_over_eur}) =><td key={String(year)}>{toNumberPresentation(turn_over_eur)}€</td>)}
                </tr>
                <tr>
                    <td>Liikevaihdon kehitys</td>
                    {turnOverChange.result.map(r=><td style={{color: 'green'}}>{r}</td>)}
                </tr>
                <tr>
                    <td>Liikevoitto prosentti</td>
                    {financialStatements.map(({year,profit}) =><td key={String(year)}>{profit} %</td>)}

                </tr>
                <tr>
                    <td>Omavaraisuusaste</td>
                    {financialStatements.map(({year,equity_to_assets_ratio}) =><td key={String(year)}>{equity_to_assets_ratio} %</td>)}

                </tr>
                <tr>
                    <td>Tilikauden tulos</td>
                    {financialStatements.map(({year,result_before_taxes_euro}) =><td key={String(year)}>{toNumberPresentation(result_before_taxes_euro)}€</td>)}
                </tr>
                </tbody>
                </table>
            </DuetTable>
        </DuetCard>
    )
}