import React, { useState } from "react";
import { DuetAlert, DuetSpacer, DuetGrid, DuetHeading, DuetIcon, DuetParagraph, DuetInput, DuetButton, DuetCard } from "@duetds/react";
import { useStateValue } from "../../state/state";
import useForm from "../../hooks/useForm";
import { validateEmail } from "../../utils/utils";

export default function Banner() {
    const [state] = useStateValue();
    const [showBanner, setShowBanner] = useState(true);
    const { shareEmail, setShareEmail,
        shareEmailError, handleShareReport, 
        sendingEmail, emailSendFailed, emailSendSuccess,
        setEmailSendFailed,setEmailSendSuccess
    } = useForm();

    if (!showBanner || state.share) return null;

    const dismiss=()=>{
        setEmailSendFailed(false)
        setEmailSendSuccess(false)
    }
    return (
        <DuetCard>
            <DuetSpacer size="small" />
            <DuetGrid>
                <DuetHeading level="h6">Kiitos kyselyyn vastaamisesta!</DuetHeading>
                <DuetIcon
                    size="small"
                    name="navigation-close-small"
                    color="gray"
                    onClick={() => setShowBanner(false)}
                />
            </DuetGrid>
            <div className="report-banner-text">
                <DuetParagraph>
                    Palkkioksi kyselyyn vastaamisesta saat yritystäsi koskevan raportin sekä toimialaasi ja kiinnostuksen
                    kohteitasi koskevia uutisia. Halutessasi voit jakaa raportin kaverillesi.
                </DuetParagraph>
            </div>
            <DuetInput
                type="email"
                label="Kirjoita alla olevaan kenttään haluamasi sähköpostiosoite, johon haluat jakaa raportin"
                value={shareEmail}
                onDuetChange={(e) => setShareEmail(e.detail.value)}
                error={(shareEmailError && !validateEmail(shareEmail)) ? 'Virheellinen sähköpostiosoite' : ''}
            />
            <DuetSpacer size="small" />
            {emailSendSuccess &&<DuetAlert  variation="success" autoDismiss={10000} onDuetDismiss={dismiss}>
                Raportti jaettu onnistuneesti
            </DuetAlert>}
            {emailSendFailed &&<DuetAlert  variation="warning" autoDismiss={10000}>
                Raporttin jakaminen epäonnistui
            </DuetAlert>}
            <DuetButton variation="primary" disabled={!validateEmail(shareEmail) || sendingEmail } onClick={handleShareReport}>Jaa raportti</DuetButton>
        </DuetCard>
    );
}