import moment from "moment";
import { useStateValue } from "../state/state";

const useChartData = () => {
    const [state] = useStateValue();
    const { latest, previous, } = state.finances;
    const { lastFinances } = state.formAnswers;

    const thisYear = parseInt(moment().format('YYYY'));
    const previousYear = previous.year ?? thisYear - 2;
    const latestYear = latest.year ?? thisYear - 1;
    const currentYear = thisYear;
    const missingLastYear =  latestYear !== thisYear - 1

    const turnOverDataQuestionnaire = [
        {
            year: previousYear,
            value: previous.turnOver ?? 0
        },
        {
            year: latestYear, 
            value: latest.turnOver ?? (state.formAnswers.turnOver.value ?? 0)
        },
    ];

    const profitDataQuestionnaire = [
        {
            year: previousYear,
            value: previous.netIncome ?? 0
        },
        {
            year: latestYear,
            value: latest.netIncome ?? (state.formAnswers.netIncome.value ?? 0)
        }
    ];

    if(missingLastYear){
        turnOverDataQuestionnaire.push({
            year: parseInt(moment().format('YYYY')) - 1,
            value: state.formAnswers.lastFinances?.turnOver  ?? latest.turnOver ?? (state.formAnswers.turnOver.value ?? 0)
        })
       // turnOverDataQuestionnaire.shift()

        profitDataQuestionnaire.push({
            year: parseInt(moment().format('YYYY')) - 1,
            value: state.formAnswers.lastFinances?.netIncome  ?? latest.profit ?? (state.formAnswers.netIncome.value ?? 0)
        })
        // profitDataQuestionnaire.shift()
    };

    if(false && missingLastYear){
        profitDataQuestionnaire.push({
            year: 2021,
            value: 100000,
        })
    };

    const turnOverDataReport = [
        { year: previousYear, value: previous.turnOver ?? 0 },
        { year: latestYear, value: latest.turnOver ?? 0 },
        { year: currentYear, value: (state.finances.industry.turnOver || 0) }
    ];
    // const turnOverPrediction = !state.share ? { value: Math.round(((state.formAnswers.expectedTurnOver.calculated ?? 0) - (latest.turnOver ?? 0)) / 1000), unit: 't €' } : null;

    const turnOverPrediction = !state.share ? { value: Math.round(((state.formAnswers.expectedTurnOver.calculated ?? 0)) / 1000), unit: 't €' } : null;

    const turnOverEstimate = state.formAnswers.expectedTurnOver.calculated;
    const profitEstimate = state.formAnswers.expectedProfit.calculated;
    const profitPercentageEstimate = state.formAnswers.expectedProfit.percentage

    const profitDataReport = [
        { year: previousYear, value: previous.netIncome ?? 0 },
        { year: latestYear, value: latest.netIncome ?? 0 },
        { year: currentYear, value: (state.finances.industry.netIncome || 0) }];
        
    //const profitPrediction = !state.share ? { value: Math.round(((state.formAnswers.expectedProfit.calculated ?? 0) - (latest.netIncome ?? 0)) / 1000), unit: 't €' } : null;

    const profitPrediction = !state.share ? { value: Math.round(((state.formAnswers.expectedProfit.calculated ?? 0)) / 1000), unit: 't €' } : null;

    const profitPercentageData = [{ year: previousYear, value: state.finances.previous.profit ?? 0 * 100 }, { year: latestYear, value: state.finances.latest.profit ?? 0 }, { year: currentYear, value: (state.finances.industry.profit || 0) }];
    // const profitPercentagePrediction = !state.share ? { value: (state.formAnswers.expectedProfit.calculated ?? 0 / (state.formAnswers?.expectedTurnOver?.calculated ?? 0) ), unit: '%' } : null;
   //  console.log('----<',state.estimates, profitPercentagePrediction,state.formAnswers.expectedProfit)
    const staffData = [{ year: previousYear, value: previous.staff ?? 0 }, { year: latestYear, value: latest.staff ?? 0 }, { year: currentYear, value: (state.finances.industry.staff || 0) }];
    // const staffPrediction = !state.share ? { value: (state.formAnswers.staff.amount ?? 0) - (state.finances.latest.staff ?? 0), unit: 'henkilö' } : null;
    const staffPrediction = !state.share ? { value: (state.formAnswers.staff.amount ?? 0), unit: 'henkilö' } : null;

    //const profitPercentagePrediction = state.share ? { value: 
    //   ((state.estimates.expectedProfit.calculated  || state.formAnswers.expectedProfit.calculated) ?? 0) / ((state.estimates?.expectedTurnOver?.calculated || state.formAnswers?.expectedTurnOver?.calculated )?? 0), unit: '%' } : null;


    const profitPercentagePrediction1 = state.share ? { value: ((state.estimates.expectedProfit.calculated  ?? 0)  /(state.estimates.expectedTurnOver.calculated ??  0))} : null
    const profitPercentagePrediction2 = !state.share ? { value: ((state.formAnswers.expectedProfit.calculated  ?? 0)  /(state.formAnswers.expectedTurnOver.calculated ??  0))} : null

    const profitPercentagePrediction = {value : ((!state.share ? profitPercentagePrediction2?.value : profitPercentagePrediction1?.value) ?? 0) , unit: '%'}

    const estimates = state.estimates
    return {
        profitPercentageEstimate,
        turnOverEstimate,
        profitEstimate,
        currentYear,
        turnOverDataQuestionnaire,
        profitDataQuestionnaire,
        turnOverDataReport,
        turnOverPrediction,
        profitDataReport,
        profitPrediction,
        profitPercentageData,
        profitPercentagePrediction,
        staffData,
        staffPrediction,
        missingLastYear,
        lastYearPartial:true,
        lastFinances,
        estimates,
    };
};

export default useChartData;