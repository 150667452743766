import { State } from "../types/state";
import { Action } from "./actions";

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload.value,
      }
    case "LOGOUT":
      return {
        ...state,
        user: undefined
      }
    case "SET_FORM":
      return {
        ...state,
        formAnswers: action.payload,
      }

    case "SET_VAINU_DATA":
      return {
        ...state,
        ...action.payload,
      }

    case 'UPDATE_IDENTITY': {
      return {
        ...state,
        shareString: action.payload.shareString,
        identity: {
          ...state.identity,
          email: action.payload.email,
          id: action.payload.id
        }
      };
    }

    case 'SET_AREA_COMPANY': {
      if (action.payload.value) {
        const areaCompanyId = typeof action.payload.value === 'number' ? action.payload.value : parseInt(action.payload.value);
        return {
          ...state,
          identity: {
            ...state.identity,
            areaCompanyId,
          }
        };
      }
      return state;
    }

    case 'FORM_FINISHED': {
      return {
        ...state,
        finished: action.payload.value
      };
    }

    case 'FORM_CURRENT_PAGE': {
      return {
        ...state,
        page: action.payload.value,
      };
    }

    case 'LT_NEWS_FULFILLED': {
      return {
        ...state,
        ltNews: action.payload
      };
    }

    case 'GET_TARGET_GROUP_FULFILLED': {
      return {
        ...state,
        newsLeads: {
          ...state.newsLeads,
          target: action.payload
        }
      };
    }

    case 'SET_DIGITALIZATION': {
      return{
        ...state,
        digitalization: action.payload
      }
    }

    case 'GET_TARGET_GROUP_LEADS_FULFILLED': {
      const newsList = Array.isArray(action.payload) ? action.payload : [];
      const cyrillicPattern = /[\u0400-\u04FF]/;
      const filteredNewsList = newsList.filter((lead) => {
        // Filter out Recruitment news and Russian news. 
        // Also remove "New website content", which is some general title generates
        return (!lead.tags || !lead.tags.includes('Rekrytointi')) && !lead.title.includes('New website content') && !lead.title.includes('NEW_WEBSITE_CONTENT') && !lead.title.includes('New Twitter tweet') && !cyrillicPattern.test(lead.title);
      });
      return {
        ...state,
        newsLeads: {
          ...state.newsLeads,
          news: filteredNewsList.slice(0, 10),
          newsNotFound: filteredNewsList.length === 0
        }
      };
    }

    case 'PAGE_CHANGE': {
      if (action.payload.value === 'next') {
        return {
          ...state,
          page: state.page + 1
        };
      }
      return {
        ...state,
        page: state.page - 1
      };
    }

    case 'GET_SHARE_DATA_FULFILLED': {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        page: 3,
        share: true,
        finances: action.payload.data.finances,
        estimates: {
          lastFinances:{
            turnOver: action.payload.data?.formAnswers?.lastFinances?.turnOver,
            netIncome: action.payload.data?.formAnswers?.lastFinances?.netIncome,
            staff: action.payload.data?.formAnswers?.lastFinances?.staff,
          },
         
          expectedProfit: action.payload.data?.formAnswers?.expectedProfit,
          expectedTurnOver: action.payload.data?.formAnswers?.expectedTurnOver,
          staff: action.payload.data?.formAnswers?.staff
        },
        digitalization: action.payload.data.digitalization,
        identity: {
          ...state.identity,
          name: action.payload.data.identity.name,
          id: action.payload.businessId,
          industryCode: action.payload.data.identity.industryCode
        }
      };
    }

    case 'USE_SHORT_FORM': {
      return {
        ...state,
        page: 2,
        shortForm: true
      };
    }

    case 'SET_TESTING': {
      return {
        ...state,
        testing: action.payload.value
      };
    }

    case 'RESTORE_FORM_STATE': {
      const { data, finished, page, testing, shareString, formInstanceId, businessId } = action.payload;
      const { identity, finances, formAnswers, digitalization } = data;

      return {
        ...state,
        // formInstanceId and also email and businessId (id) are not saved in identity in db
        // here businessId from db is put to identity.id
        identity: {
          ...state.identity,
          ...identity,
          formInstanceId,
          id: businessId,
        },
        finances,
        formAnswers,
        digitalization,
        finished,
        page,
        testing,
        shareString,
      };
    }

    case 'SET_ERROR' : {
        return {
          ...state,
          error:{
            status: true,
            message: action.payload.value
          }
        }
    }
    case 'CLEAR_ERROR' : {
      return {
        ...state,
        error:undefined
      }
    }

    case 'SET_MISSING_LAST_YEAR' : {
      return {
        ...state,
        formAnswers:{
          ...state.formAnswers,
          currentYearMissing: true,
        }
      }
    }
    case 'SET_LAST_YEAR_PARTIAL' : {
      return {
        ...state,
        formAnswers:{
          ...state.formAnswers,
          currentYearMissing: true,
        }
      }
    }
    case 'SET_FINANCES' : { 
      return{
        ...state,
        finances: action.payload
      }
      }
    case 'SET_CURRENT_YEAR' : {
      return{
        ...state,
        currentYear: action.payload.year
      }
    }
    

    // no more needed for setting missing vainu turn over and profit data?
    // case 'UPDATE_INPUT': {
    //   if (action.payload.id === 'turnOver') {
    //     let calculated = ((state.formAnswers.expectedTurnOver.percentage ?? 0) / 100) * Math.abs(action.payload.value) + action.payload.value;
    //     return {
    //       ...state,
    //       formAnswers: {
    //         ...state.formAnswers,
    //         turnOver: {
    //           value: action.payload.value,
    //           changed: true
    //         },
    //         expectedTurnOver: {
    //           ...state.formAnswers.expectedTurnOver,
    //           calculated: calculated
    //         }
    //       }
    //     };
    //   }

    //   if (action.payload.id === 'netIncome') {
    //     let calculated = ((state.formAnswers.expectedProfit.percentage ?? 0) / 100) * Math.abs(action.payload.value) + action.payload.value;
    //     return {
    //       ...state,
    //       formAnswers: {
    //         ...state.formAnswers,
    //         netIncome: {
    //           value: action.payload.value,
    //           changed: true
    //         },
    //         expectedProfit: {
    //           ...state.formAnswers.expectedProfit,
    //           calculated: calculated
    //         }
    //       }
    //     };
    //   }
    //   return state;
    // }

    default:
      return state
  }
};
