export const RuleTypes =[
    'staff', 
    'staffChangePercentage', 
    'staffChange',
    'expectedProfit', 
    'expectedTurnOver', 
    'netIncome',
    'netIncomeChange',
    'netIncomeChangePercentage', // TODO CHECK THIS
    'turnOver',
    'turnOverChange',
    'turnOverChangePercentage',
    'profit',
    'profitChange',
    'wishesContact',  // checkbox
    'risks', // checkbox
    'events', // Checkbox
    'or', // SubRules 
] as const;




export type RuleType = typeof RuleTypes[number];


export type SubRuleType = Omit<RuleType,'or'>

export type NumberInput = {
    value: string;
    type: 'number'
    name: 'min' | 'max'
}

export type CheckBoxInput = {
    value: null | boolean;
    type: 'checkbox';
    name: string;
}

/*
    Currently server actually allows subrules to have nested Rules and subrules,
    but I think that recursive  rules are unnessecary.
*/
export type SubRule = {
    name: SubRuleType;
    text: string;
    inputs: NumberInput[] | CheckBoxInput[];
}


export type Rule = {
    name: RuleType;
    text: string;
    label: string;
    inputs: NumberInput[] | CheckBoxInput[];
    subRules: SubRule[];
}


export function isNumberInput(input: NumberInput |  CheckBoxInput): input is NumberInput {
    return input.type==='number';
}
export function isCheckBoxInput(input: NumberInput |  CheckBoxInput): input is CheckBoxInput {
    return input.type==='checkbox';
}
/* TODO
export function isOrRule()

*/
export type Delivery = {
    checked: boolean;
    immediatly?:boolean;
    frequency: 'monthly' | ' weekly' |'daily';
    day: number;
}


export type FilterDataType = {
    _id: string;
    name: string;
    createdAt: string;
    modified: string;
    emails: string[];
    delivery: Delivery;
    rules: Rule[];
    areaCompanyId: number;
}