import React from 'react';
import { DuetCard, DuetGrid, DuetGridItem, DuetHeading, DuetLink, DuetParagraph, DuetSpacer, DuetSpinner } from '@duetds/react';
import newsPhoto from "../../images/news.jpg";
import { useStateValue } from '../../state/state';
import useNews from '../../hooks/useNews';
import useWindowSize from '../../hooks/useWindowSize';
import useResizeObserver from "use-resize-observer";

const NewsLeads = () => {
    const [state] = useStateValue();
    const { loadingNewsLeads } = useNews();
    const { isMobile, isPad, } = useWindowSize();
    const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();

    type Lead = { id?: number, title: string, link: string }

    const newsLeadsWithLink = state.newsLeads.news
        .filter(({ link }) => !!link)
        .map(({ id, title, link, }) => ({ id, title, link, })) as Lead[];

    const newsLeadsToShowLeftColumn = newsLeadsWithLink.slice(0, isMobile ? 3 : 2);
    const newsLeadsToShowRightColumn = isMobile ? undefined : newsLeadsWithLink.slice(2, 3)[0];

    const imagewidth = isMobile
        ? width
        : isPad
            ? width / 2
            : width / 3;

    const NewsLead = (news: Lead) => (
        <DuetParagraph
            color="color-primary"
            className="news-lead-paragraph"
        >
            <DuetLink url={news.link} external>
                {news.title}
            </DuetLink>
        </DuetParagraph>
    );

    return (
        <div className="grid-news-leads" ref={ref}>
            <img src={newsPhoto} width={imagewidth} alt="A man sitting at a desk at home" className="news-photo" />
            <DuetCard
                padding="small"
                margin="none"
                className="news-leads-container"
                heading=""
                
                style={{ height: imagewidth }}
            >
                {newsLeadsToShowLeftColumn.length > 0 ?
                    <>
                        <div className="grid">
                            <div>
                                {newsLeadsToShowLeftColumn.map(({ id, link, title }) => <NewsLead key={id} link={link} title={title} />)}
                            </div>
                            {newsLeadsToShowRightColumn && <NewsLead {...newsLeadsToShowRightColumn} />}
                        </div>
                        <DuetLink url="https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet" external>
                            Lue lisää
                        </DuetLink>
                    </>
                    : loadingNewsLeads
                        ? <DuetSpinner size="large" theme="default" />
                        : <div>Toimialallesi ei löytynyt uutisia</div>}
            </DuetCard>
        </div>
    );
};


type Props = {
    heading: string;
    text: string;
    url: string;
};

const NewsCard = ({ heading, text, url }: Props) => (
    <DuetCard color="color-gray-lightest" heading={heading} margin="none">
        <DuetGrid className="grow" direction="vertical" alignment="stretch" distribution="space-between">
            <DuetGridItem>
                <p className="news-paragraph">{text}</p>
            </DuetGridItem>
            <DuetGridItem>
                <DuetLink url={url} external>
                    Lue lisää
                </DuetLink>
            </DuetGridItem>
        </DuetGrid>
    </DuetCard>
);

const News = () => {
    const [{ ltNews }] = useStateValue();

    return (
        <div className="grid-columns-3" >
            {ltNews.slice(0, 3).map((news, i) => (
                <NewsCard
                    key={i}
                    heading={news.title[0]}
                    text={news.description[0].replace(/(<([^>]+)>)/gi, '')}
                    url={news.link[0]}
                />)
            )}
        </div>
    );
};

export default function NewsSection() {
    const { isMobile } = useWindowSize();
    return (
        <>
            <DuetGrid distribution="center">
                <DuetGridItem>
                    <DuetHeading level="h2">Uutisia</DuetHeading>
                </DuetGridItem>
            </DuetGrid>
            <NewsLeads />
            <DuetSpacer size={isMobile ? 'x-large' : 'large'} />
            <News />
        </>
    );
}