import { RuleTypes, Rule, SubRule, SubRuleType } from './types';


export const SubRuleList:SubRule[] = [
    {
        name: "staffChangePercentage",
        text: "Henkilöstön muutos (%)",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "staffChange",
        text: "Henkilöstön muutos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "netIncome",
        text: "Liikevaihto",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "netIncomeChange",
        text: "Liikevaihdon muuutos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "netIncomeChangePercentage",
        text: "Liikevaihdon muuutos (%)",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "staff",
        text: "Henkilöstömäärä",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "expectedProfit",
        text: "Tulos odotettu",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "expectedTurnOver",
        text: "Liikevaihto odotettu",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "netIncome",
        text: "Tulos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },/*
    {
        name: "netIncomeChange",
        text: "Netto voiton muutos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },*/
   /* {
        name: "netIncomeChangePercentage",
        text: "Netto voiton muutos (%)",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },*/
    {
        name: "turnOver",
        text: "Liikevaihto ",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "turnOverChange",
        text: "Liikevaihdon muutos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "turnOverChangePercentage",
        text: "Liikevaihdon muutos (%)",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "profit",
        text: "Liikevoitto",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "profitChange",
        text: "Liikevoiton muutos",
        inputs: [
            {
                value: "0",
                type: "number",
                name: "min",
            },
            {
                value: "0",
                type: "number",
                name: "max",
            },
        ]
    },
    {
        name: "wishesContact",
        text: "Toivoo yhteydenottoa",
        inputs: [
            {
                value: true,
                type: "checkbox",
                name: "wishesContact",
            },
        ]
    },
    {
        name: "risks",
        text: "Riskit ",
        inputs: [
            {
                value: true,
                type: 'checkbox',
                name: "Suomen_talouden_heikentyminen",
            },
            {
                value: true,
                type: 'checkbox',
                name: "Rahoituksen_saatavuus",
            },
            {
                value: true,
                type: 'checkbox',
                name: "Kilpailun_kiristyminen",
            },
            {
                value: true,
                type: 'checkbox',
                name: "Henkilöriskit_(esim_sairastuminen)",
            },
            {
                value: true,
                type: 'checkbox',
                name: "Omaisuusvahingot",
            },
            {
                value: true,
                type: 'checkbox',
                name: "Toimeentulo_yrittäjän_sairastuessa",
            },
        ]
    },
    {
        name: "events",
        text: "Tapahtumat",
        inputs: [
            {
                value: true,
                type: "checkbox",
                name: "LähiTapiolan_palvelukehitys",
            },
            {
                value: true,
                type: "checkbox",
                name: "Hyvinvointi",
            },
            {
                value: true,
                type: "checkbox",
                name: "Kulttuuri",
            },
            {
                value: true,
                type: "checkbox",
                name: "Verkotoituminen",
            },
            {
                value: true,
                type: "checkbox",
                name: "Urheilu",
            },
            {
                value: true,
                type: "checkbox",
                name: "Talous",
            },
        ]
    },



    
] 


export const toNumberPresentation=(n:number |string | undefined ):string =>{
    if(n === undefined || n === null) return '-';
    const num = typeof(n) ==='string' ? Number.parseFloat(n) : n; 
    if(Math.abs(num)>0.5e6) return `${(num/1e6).toFixed(1)} M`
    if(Math.abs(num)>0.5e3) return `${(num/1e3).toFixed(1)} t`
    return n.toString()
}