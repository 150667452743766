import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import "./index.scss"

import App from "./App"
import { StateProvider } from "./state/state"
import { reducer } from "./state/reducer"

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL ?? ""

ReactDOM.render(
  <StateProvider reducer={reducer}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </StateProvider>,
  document.getElementById("root")
)
