import { DuetButton, DuetCard, DuetGrid, DuetGridItem, DuetHeading, DuetInput, DuetLayout, DuetLink, DuetList, DuetTable } from '@duetds/react'
import { DuetListItem, DuetParagraph } from '@duetds/react/commonjs'
import React, { useEffect, useState } from 'react'
import QuestinaireAnswers from './QuestinaireAnswers'
import CompanyDetails from './CompanyDetails'
// @ts-expect-error
import iconActionArrowLeft from "@duetds/icons/lib/assets/action-arrow-left";

// import AdminColumnCharts from './AdminColumnCharts'
import PieCharts from '../Report/PieCharts'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import VainuView  from "./VainuView";
import {isValidBusinessId} from '../../utils/businessId'
import axios from 'axios'
import { FormAnswers, Identity} from '../../types/state';
import { getAreaCompanyById } from '../../utils/areaCompanies'
import { State } from "../../types/state";
import { DateTime } from 'luxon'
import Footer from '../Footer'
import { CompanyReport } from './CompanyReport'
import { LoadMore } from './LoadMore'
import ColumnCharts from '../../components/Report/ColumnCharts';


type testi = {
    businessId: string;
        finished: boolean;
        createdAt: string;
        data:{
            identity:Omit<Identity,'id'>
            finances: any
        },
}


const extractIdentityField=(field:keyof Identity, label: string) => (data: Identity) =>[label,data[field]]

const companyDetails=(data: testi) => [
    ['Nimi',data.data.identity.name ?? 'ei määritelty'],
    ['Y-tunnus', data.businessId  ?? 'ei määritelty'],
    ['Sähköposti',data.data.identity.email ?? 'ei määritelty'],
    ['Merkitty alueyhtiö', getAreaCompanyById(data?.data?.identity?.areaCompanyId)?.name ?? 'ei määritelty'],
    ['Kyselyyn vastauspäivä', new Date(data.createdAt).toLocaleDateString()],
    ['Kysely täytetty loppuun', data.finished ? 'kyllä' :'ei'] 
] as [string,string][]

function Normal(){
    const {id} =useParams();
    const [businessId, setBusinessId] = useState<string>(id || '');
    const [invalidBusinessId, setInvalidBusinessId] = useState<string|undefined>(undefined)
    const [search, setSearch] = useState<string>('')

    const [forms, setForms] = useState<any[]>([]);

    const [found,setFound] = useState<any[]>([]); 
    useEffect(()=>{
        if (id) getData()}
        ,[id])
    
    useEffect(()=>{
        axios.get('/api/admin/list/',{
            withCredentials: true,
            })
            .then((r)=>{ 
                setForms(r.data)
            })
        .catch((error)=>console.log('error',error) )
    },[])


    const [companyData,setCompanyData] = useState<any|undefined>(undefined);
    const [notFound, setNotFound] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const getData=()=>{
        if(true || isValidBusinessId(businessId ?? id)){
            setNotFound(undefined);
            setLoading(true);
                axios.get(`/api/admin/find/${businessId ?? id}`,{withCredentials: true})
                    .then((result)=>{
                        setCompanyData(result.data?.[0])
                        if(result?.data?.[0]===undefined){
                            setNotFound('Yrityksen tietoja ei löytynyt')
                        }
                    })
                    .catch((error)=>console.log('error',error))
                    .finally(()=>setLoading(false))
        }else{
            setInvalidBusinessId('Virheellinen y-tunnus')
            setCompanyData(undefined)
        }
    }

    const handleSubmit=(event:any)=>{
        event.preventDefault();
        navigate(`/admin/search/${businessId}`);
        // if(isValidBusinessId(businessId))
        // getData();
    
    }

    const onInputChange=(event:CustomEvent<any>)=>{
        setInvalidBusinessId(undefined)
        setBusinessId(event.detail.value)
    }

    const onSearchInputChange=(event:CustomEvent<any>)=> {
        setSearch(event.detail.value)
        if(businessId) {
            setBusinessId('')
            navigate('/admin/search')
        }
        console.log('SEARCH INPUT CHANGE',event)
        // searchCompanies()
    }
    

    const filteredForms = forms.filter((form) => form?.businessId.includes(search) || form?.data?.identity?.name?.toLowerCase().includes(search.toLowerCase()))
    return(
        <div className="grid">
            <DuetHeading  className="span-2 center-text" >Yrityshaku</DuetHeading>
            <DuetParagraph className="span-2 center-text">
                Hae syke-kyselyn täyttäneiden yritysten tietoja
            </DuetParagraph>

            <div  className="span-2 center-text">
            <form onSubmit={handleSubmit}>
            <DuetGrid  direction="horizontal">
                <DuetGridItem>
                
        {false &&   <DuetInput
                label='hae'
                labelHidden
                className='search-input'
                error={invalidBusinessId}
                onDuetChange={onInputChange}
                value={businessId}
            />}
            <DuetInput
                label='etsi'
                labelHidden
                className='search-input'
                onDuetChange={onSearchInputChange}
                value={search}
            />
            </DuetGridItem>

            {false && <DuetGridItem>
                <DuetButton style={{marginLeft:'20px'}}variation="primary" submit>Hae</DuetButton>
            </DuetGridItem>}
            </DuetGrid>
            </form>
            </div>
            <div className={'span-2'}>
                    {filteredForms.length >0 && filteredForms.length <100 && search.length>0 && filteredForms
                        .map(({_id, businessId, finished, createdAt,data, areaCompanyId, page})=><LoadMore key={_id} info={{ _id, businessId,areaCompanyId,page,createdAt, data}}/>)}
                </div>
            {(false && filteredForms.length >0 && search.length>0 && !businessId) && <DuetCard headingLevel='h6' heading="Löydetyt kyselyn täyttäneet yritykset" className="span-2" >
                <DuetTable variation="striped" style={{with: '100rem'}}>
                <table>
                <thead>
                    <tr>
                        <th>Nimi</th>
                        <th>Y-tunnus</th>
                        <th>Alueyhtiö</th>
                        <th>Päivämäärä</th>
                        <th>Täytetty loppuun</th>
                    </tr>
                </thead>
                <tbody>


                {false && filteredForms.map(({_id, businessId, finished, createdAt,data })=>{
                    const  { identity } = data ?? {}
                    const {name, areaCompanyId} = identity ?? {name: '-', areaCompanyId: '-'}
                    return(
                    <tr key={_id} onClick={()=> {
                        setSearch('')
                        navigate(`/admin/search/${businessId}`)}
                        }
                        className="reportlink-row"
                        >
                        <td>{name}</td>
                        <td>{businessId}</td>
                        <td>ei määritelty</td>
                        <td>{DateTime.fromISO(createdAt).toLocaleString()   }</td>
                        <td>{finished  ? 'Kyllä' : 'Ei'}</td>
                    </tr>)})}
                </tbody>
                </table>
            </DuetTable>
            </DuetCard>}
            {notFound && <div>Yrityksen tietoja ei löytynyt</div>}
            {companyData && id !==undefined && (
                <>
                <CompanyDetails
                    details={companyDetails(companyData)}
                    businessId={businessId}
                    id={businessId}
                />
                    <QuestinaireAnswers businessId={businessId} /><div className="span-2">
                    <ColumnCharts />
                    <PieCharts />
                </div></>)}
              
            </div>
    )
}

export default function CompanyView(){

    return(
        <>
        <DuetLayout  className="duet-bg-gradient layout" margin="none">
        <div slot="main">
            
            <Routes>
                <Route path=":id/vainu/" element={<VainuView />}/>
                <Route path="/:id" element={<CompanyReport />} />
                <Route index element={<Normal />} />
            </Routes>
        </div>
        </DuetLayout>
        <Footer />
        </>
    )
}