import { useEffect, useState } from 'react'
import { useStateValue } from '../state/state'
import {
  setAreaCompany,
  formFinished,
  setLTNews,
  updateIdentity,
  setCurrentPage,
  setShareData,
  setShortForm,
  setTesting,
  restoreFormState,
  setCurrentYear,
} from '../state/actions'
import useFetchVainuData from './useFetchVainuData'
import { fetchLtNews, getShareData, checkFormStatus, checkCurrentPage, checkIsFormFinished } from '../api/pulssi'
import axios from 'axios'
import moment from 'moment'
import { parse } from 'path'

const windowLocationSearch = new URLSearchParams(window.location.search)
export const shareString = windowLocationSearch.get('t')
const areaCompanyId = windowLocationSearch.get('a') || 0
export const share = windowLocationSearch.get('share')
const isShortForm = windowLocationSearch.get('f') === '1'
const isTesting = windowLocationSearch.get('testing') === '1'

// RND WORKS TOKEN
// http://localhost:3000/?t=U2FsdGVkX19Jd%2F4CkusuYjKnSTJZXlSVWUhtqYYvleEiloBTeCuD12IH58RI3Bpc
const TOKEN = process.env.NODE_ENV === 'production' ? window.token : { id: '2742151-8', email: 'teemu.@rnd.works' }

// SANOMA OY (no latest turnOver & profit data)
// http://localhost:3000/?t=U2FsdGVkX1%2BqanJNyZsdarsW3svbPTYIz2D4jsAL5mzekVTqLqzkU0Xwc6mr0uXs
// const TOKEN = process.env.NODE_ENV === 'production' ? window.token : { id: '1524361-1', email: 'teemu@rnd.works' };

let { id, email } = TOKEN
const tokenCache: Record<string, null | typeof window.token> = {}
const tokenPromiseGenerator = async (token: string) => {
  if(token === null) return
  if (tokenCache[token]) {
    return tokenCache[token]
  }

  tokenCache[token] = null

  try {
    const res = await axios.get(`/decryptToken?t=${token}`)

    const { id, email } = await res.data

    tokenCache[token] = res.data

    return { id, email }
  } catch (e: any) {
    throw e
  }
}

const useInitState = () => {
  
  
  try {
  
    tokenPromiseGenerator(shareString as string).then((data: any) => {
      id = data?.id
      email = data?.email
    })
  } catch (e) {
    console.error('Failed token promise', e)
  }

  const { getVainuData } = useFetchVainuData()
  const [state, dispatch] = useStateValue()
  const [loading, setLoading] = useState(false)

  const getLTNews = () =>
    fetchLtNews()
      .then(response => {
        dispatch(setLTNews(response.data))
      })
      .catch(error => console.log('Get LT news error', error))

  useEffect(() => {
    setLoading(true)
    dispatch({type:'SET_CURRENT_YEAR', payload:{ year: parseInt(moment().format('YYYY'),10) }})

    if (share) {
      //SHARING
      getShareData(encodeURIComponent(share))
        .then(response => {
          dispatch(setShareData(response.data))
        })
        .finally(() => setLoading(false))
    } else if (isTesting) {
      // TESTING
      dispatch(setAreaCompany(areaCompanyId))
      dispatch(setTesting(true))
      if (isShortForm) {
        dispatch(setShortForm())
      }
      if (!state.identity.fetched && state.identity.id) {
        // TODO: what is going on here?
        getVainuData(id)
          .then(() => getLTNews())
          .finally(() => setLoading(false))
      } else {
        getLTNews().finally(() => setLoading(false))
      }
    } else {
      // BASE CASE
      if (!shareString) {
        return
      }
      if (isShortForm) {
        dispatch(setShortForm())
      }
      checkFormStatus(shareString)
        .then(result => {
          if (result?.data) {
            dispatch(restoreFormState(result.data))
            if(state.finances.latest.year !== parseInt(moment().format('YYYY'))-1){
              
            }

            //  dispatch(updateIdentity({ id, email, shareString })); // ????
            // dispatch(setAreaCompany(areaCompanyId)); // ????
            getLTNews()
          } else {
            getVainuData(id).then(() => {
              // TODO: what if getVainuData fails?
              dispatch(updateIdentity({ id, email, shareString }))
              dispatch(setAreaCompany(areaCompanyId))

              checkIsFormFinished(id, email, shareString)
                .then(response => {
                  dispatch(formFinished(response.data.finished))
                })
                .catch(error => console.log('Check is form finished error', error))

              checkCurrentPage(id, email, shareString)
                .then(response => {
                  dispatch(setCurrentPage(response.data.page))
                })
                .catch(error => console.log('Check current page error', error))

              getLTNews()
            })
          }
        })
        .finally(() => setLoading(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, getLTNews }
}

export default useInitState
