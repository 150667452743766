import { DuetCheckboxEvent } from '@duetds/components/lib/types/components/duet-checkbox/duet-checkbox';
import { DuetButton, DuetCheckbox, DuetChoice, DuetChoiceGroup, DuetGrid, DuetGridItem, DuetInput, DuetSelect, DuetSpacer, DuetStep } from '@duetds/react';
import { DuetLabel } from '@duetds/react/commonjs';
import React, { useState } from 'react';
import { Delivery as DeliveryType } from './types';

type Props={
    nextStep: () => void;
    selectStep: () => void;
    name: string;
    emails: string[];
    addEmail: () => void
    changeEmail: (index:number) =>(email:string) => void
    automaticDelivery?: boolean
    immediateDelivery?: boolean,
    // immediateDeliveryChange: (delivery:DeliveryType)=>void,
    delivery: DeliveryType
    changeDelivery: (delivery:DeliveryType)=>void
    changeName: (name:string) => void
    removeEmail: (idx:number) => () => void
}

const Options=[
    {label:"Maanantai", value: "0"},
    {label:"Tiistai", value: "1"},
    {label:"Keskiviikko", value: "2"},
    {label:"Torstai", value: "3"},
    {label:"Perjantai", value: "4"},
    {label:"Lauantai", value: "5"},
    {label:"Sunnuntai", value: "6"},
];

function Delivery({delivery, changeDelivery}:{delivery: DeliveryType, changeDelivery:(delivery:DeliveryType)=>void}){
   
    const onChange = (e:CustomEvent<any>)=>{
        changeDelivery({...delivery, frequency: e.detail.value})  
    }

    return (
        <>
        <DuetChoiceGroup labelHidden margin="none" value={"weekly"} >
            <DuetChoice 
                label="Päivittäin"
                value='daily'
                onDuetChange={onChange}
            />
            <DuetChoice
                label="Kerran viikossa"
                value='weekly'
                onDuetChange={onChange}
            />
            <DuetChoice
                label="Kerran kuukaudessa"
                value="monthly"
                onDuetChange={onChange}
            />
        </DuetChoiceGroup>
        <DuetSelect label="Viikonpäivä" items={Options}  />
        </>
    )
}

export default function CustomerSegment({
    nextStep,
    selectStep,
    name,
    emails,
    automaticDelivery,
    immediateDelivery,
    addEmail,
    changeEmail,
    changeName,
    delivery,
    changeDelivery,
    removeEmail,
    }:Props){

   
    const handleAutomaticSendDelivery=(event:CustomEvent<DuetCheckboxEvent>)=>{
        changeDelivery({...delivery, checked: Boolean(event.detail.checked)})
    }

    const handleImmediateDeliveryChange = (event:CustomEvent<DuetCheckboxEvent>)=>{
        changeDelivery({...delivery, immediatly: Boolean(event.detail.checked)})
    }
    const handeEmailChange=(id:number)=>(event:CustomEvent<any>)=>{
        changeEmail(id)(event.detail.value)
    }

    const handleNameChange=(event: CustomEvent<any>) => {
        changeName(event.detail.value)

    }
    return(
        <DuetStep
            onDuetStepClick={selectStep}
            heading="Asiakassegmentin tiedot"
            headingLevel="h2">
        <DuetInput label="Asiakassegmentin nimi" value={name} expand onDuetChange={handleNameChange} />
        <DuetSpacer size="x-small" />
        <DuetLabel>Sähköpostiosoitteet</DuetLabel>
        <DuetGrid  style={{width: '100%'}} direction="vertical"  >
            {emails.map((email,i)=>(<DuetGridItem key={i} style={{ padding: 0 }}>
            <div style={{display:'flex ', width: '100%'}}><DuetInput margin="none"  expand labelHidden type="email"  value={email ?? ''} onDuetChange={handeEmailChange(i)}/>
            {(i===emails.length-1  && i !==0) &&<DuetButton style={{margin: "0 0 0 2rem"}} variation="destructive-secondary" onClick={removeEmail(i)}>Poista</DuetButton> }
            </div>
            </DuetGridItem>))}
        </DuetGrid>
        <DuetSpacer />

        <DuetButton
            variation="secondary"
            icon="action-add-circle"
            size="small"
            onClick={addEmail}
        >
            Lisää uusi
        </DuetButton>

        <DuetSpacer size="large" />
        
        <DuetCheckbox
            label="Lähetä lista välittömästi"
            checked={immediateDelivery}
            onDuetChange={handleImmediateDeliveryChange}
        />
        <DuetSpacer size="large" />
        <DuetCheckbox
            label="Lähetä lista automaattisesti"
            checked={automaticDelivery}
            onDuetChange={handleAutomaticSendDelivery}
        />
        <div>
        {delivery.checked ? <Delivery delivery={delivery} changeDelivery={changeDelivery}/>: null

        }
        </div>
        <DuetSpacer />
        <DuetButton
            onClick={nextStep}
            variation="primary">
                Jatka
        </DuetButton>
    </DuetStep>
    )
}