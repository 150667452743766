import { DuetButton, DuetCard, DuetHeading, DuetSpacer } from "@duetds/react";
import React, { useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colorGray } from "@duetds/tokens"
import useWindowSize from "../../hooks/useWindowSize";
import usePieData from "../../hooks/usePieData";
import { PieTitle } from "../../utils/Descriptions";
import { getPieColor } from "../../utils/pieColors";

type Props = {
    title?: PieTitle;
    group?: 'A' | 'B';
    selectTitle: (title: PieTitle | undefined) => void;
    disableToolTip?: boolean
};

export default function PieCard({ title, group, selectTitle, disableToolTip}: Props) {
    const ref = useRef<HighchartsReact.RefObject>(null);
    const { isMobile } = useWindowSize();
    const { getPieData, pieTitles, getValidPieTitlesGroupA } = usePieData();
    const validTitlesGroupA = getValidPieTitlesGroupA();
    const [selectedTitle, setSelectedTitle] = useState<PieTitle | undefined>(group === 'A' ? (validTitlesGroupA[0] ?? undefined) : 'digitality');

    const outerDiameter = !isMobile ? 220 : 280;
    const innerDiameter = !isMobile ? 150 : 190;
    const displayValuePositionY = !isMobile ? 100 : 130;

    const pieTitle = title ?? selectedTitle as PieTitle;

    const { value, percentage, unit } = getPieData(pieTitle);

    if (value === 0) return null;

    let data = [{
        dataLabels: {
            enabled: false,
        },
        y: percentage ? 100 - percentage : 100,
        color: percentage ? colorGray : 'lightblue',
    },
    ];

    if (percentage) {
        data = [{
            dataLabels: {
                enabled: false,
            },
            y: percentage,
            color: getPieColor(pieTitle, percentage),
        }].concat(data)
    }

    const displayValue = value
        ? `${(value / 1000).toFixed(1)} t ${unit}`
        : percentage !== undefined
            ? unit
                ? `${Math.round(percentage)}${unit}`
                : `${Math.round(percentage / 10)}/10`
            : '';

    const options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        
        chart: {
            type: 'pie',
            width: outerDiameter,
            height: outerDiameter,
            // TODO use style to better center align (in mobile) ???
            // style: {}
        },
        title: {
            text: displayValue,
            floating: true,
            x: 0,
            y: displayValuePositionY,
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
            }
        },
        series: [{
            name: title,
            type: 'pie',
            data,
            innerSize: innerDiameter,
        }],
        tooltip: {
            enabled: !disableToolTip,
            formatter: function () {
                 return `${Math.round(this.y)}%`;
            },
        },
    };

    const handleClick = () => {
        selectTitle(title ?? selectedTitle);
    };

    const mobileButtonTitles: PieTitle[] = group === 'A' ?
        validTitlesGroupA : pieTitles.slice(5, 8);

       
    return (
        <DuetCard padding="none" className="pie-card" variation="plain">
            {isMobile && mobileButtonTitles && (
                <div>
                    {mobileButtonTitles.map((buttonTitle) => (
                        <DuetButton
                            key={buttonTitle}
                            variation={buttonTitle === selectedTitle ? 'primary' : 'secondary'}
                            onClick={() => {
                                setSelectedTitle(buttonTitle);
                                selectTitle(undefined);
                            }}
                            size="small"
                            className="mobile-toggle-buttons"
                        >
                            {getPieData(buttonTitle).fin}
                        </DuetButton>
                    ))}
                </div>
            )}
            {isMobile && <DuetSpacer direction="horizontal" size="small" />}
            <DuetHeading level="h6" weight="semibold">{getPieData(pieTitle).fin}</DuetHeading>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={ref}
            />
            <DuetButton variation="plain" icon="messaging-info" onClick={handleClick} size="medium">Lisätietoa</DuetButton>
        </DuetCard>
    );
}