import React, { Fragment, useState } from "react"
import { DuetCard, DuetLayout, DuetButton, DuetHeading, DuetInput, DuetParagraph, DuetSpacer } from "@duetds/react"

import Header from "./Header"
import { useStateValue } from "../../state/state"
import { setUser } from '../../state/actions'
import axios, { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"

type AdminLoginResponse = {
    user: {
        _id:string
            local:{
                username: string
                areaCompanyId: number
        }
    }
}

type AdminLoginFailedResponse = {
    error: string
}

const isLoginSuccesful = (result: AdminLoginResponse | AdminLoginFailedResponse): result is AdminLoginResponse => (result as AdminLoginResponse).user !== undefined

const attemptAdminlogin = async (adminLoginData: FormData):Promise<AdminLoginResponse | AdminLoginFailedResponse> => {

    try {
        const response = await axios.post<AdminLoginResponse>("/api/admin/login",{
                username: adminLoginData.get('username'),
                password: adminLoginData.get('password'),
        })
        return response.data
    } catch (error){
        const e = error as AxiosError
        const { response } = e
        if (response) {
          // If we have a response, the request didn't fail. It was just erroneous.
        return {error: 'Login failed'}
        } else {
          // No response, the request failed outright without reaching the server.    
        return {error: 'Network Error'}}
        }
    }


export default function AdminLogin() {
    const headingStyle = { textAlign: "center" }
    const textStyle = { ...headingStyle, marginBottom: "60px" }
    const [formState, setFormState] = useState<"idle" | "submitting" | "done" | "error">('idle');
    const [formMessages, setFormMessages] = useState< Record<string, string> | null>(null);

    const navigate = useNavigate()
    const [state, dispatch] = useStateValue();
    
    if(state.user){
        navigate('/admin/search')
    }

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const resOrError = await attemptAdminlogin(new FormData(e.currentTarget))
        if(isLoginSuccesful(resOrError)){
            console.log('login success',resOrError.user);
            dispatch(setUser(resOrError.user._id,resOrError.user.local.username, resOrError.user.local.areaCompanyId )); //todo need areacompanyID
        }else{
            setFormState('error')
        }

}

    return (
    <Fragment>

        <DuetLayout middle={true} style={{ minHeight: "100%" }}  className="duet-bg-gradient">
        <div slot="main">
        <DuetHeading level="h1" style={headingStyle}>
            Kirjaudu
        </DuetHeading>
        <DuetCard>
            {formState === "error" ? (
                <DuetParagraph>{formMessages?.error ?? "Kirjautuminen epäonnistui!"}</DuetParagraph>
            ) : null}

            {formState === "done" ? (
                <div>
                    <DuetParagraph margin="none">{formMessages?.success}</DuetParagraph>
                </div>
            ) : (
            <form onSubmit={submit}>
                <DuetInput
                    label="Käyttäjätunnus"
                    name="username"
                    expand

                />
                <DuetInput
                    label="Salasana"
                    type="password"
                    name="password"
                    expand
                />

                <DuetSpacer size="xx-large" />
                <DuetButton submit variation="primary" disabled={formState === "submitting"}>
                    Kirjaudu
                </DuetButton>
            </form>
            )}
        </DuetCard>
        </div>
    </DuetLayout>
    </Fragment>
)
}