import React, { createContext, useContext, useReducer } from "react";
import { initialState } from './initialstate'
import { Action } from "./actions";
import { State } from "../types/state";

export const StateContext = createContext<[State, React.Dispatch<Action>]>([initialState, () => initialState])

export const StateProvider: React.FC<StateProviderProps> = ({ reducer, children }: StateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>
}

export type StateProviderProps = {
  reducer: React.Reducer<State, Action>
  children: React.ReactElement
}

export const useStateValue = () => useContext(StateContext)
