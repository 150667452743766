import { DuetCard, DuetGrid, DuetHeading, DuetLink, DuetParagraph, DuetSpacer, DuetTable } from '@duetds/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Identity } from '../../types/state';
import { getAreaCompanyById } from '../../utils/areaCompanies';
import PieCharts from '../Report/PieCharts';
// import AdminColumnCharts from './AdminColumnCharts';
import QuestinaireAnswers from './QuestinaireAnswers';
import CompanyDetails from './CompanyDetails';
import ColumnCharts  from '../Report/ColumnCharts';
import { useStateValue } from '../../state/state';
import useFetchVainuDataAdmin from '../../hooks/useFetchVainuDataAdmin'

type Data = {
        businessId: string;
        finished: boolean;
        createdAt: string;
        data:{
            identity:Omit<Identity,'id'>
            finances: any
        },
}

const companyDetails=(data: Data) => [
    ['Nimi',data.data.identity.name ?? '-'],
    ['Y-tunnus', data.businessId  ?? '-'],
    ['Sähköposti',data.data.identity.email ?? '-'],
    ['Merkitty alueyhtiö', getAreaCompanyById(data?.data?.identity?.areaCompanyId)?.name ?? 'ei määritelty'],
    ['Kyselyyn vastauspäivä', new Date(data.createdAt).toLocaleDateString()],
    ['Kysely täytetty loppuun', data.finished ? 'Kyllä' :'Ei'] 
] as [string,string][]

export function CompanyReport(){

    const { id } = useParams();
    const [invalidBusinessId, setInvalidBusinessId] = useState<string|undefined>(undefined)
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    
    useEffect(()=>{
        if (id) getData()}
        ,[id])
    
    const [companyData,setCompanyData] = useState<any|undefined>(undefined);
    const [notFound, setNotFound] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const getData=()=>{
        if(id){
            setNotFound(undefined);
            setLoading(true);
                axios.get(`/api/admin/find/${id}`,{withCredentials: true})
                    .then((result)=>{
                        setCompanyData(result.data?.[0])

                        if(result?.data?.[0]===undefined){
                            setNotFound('Yrityksen tietoja ei löytynyt')
                            
                        }else{
                            dispatch({
                                type: 'SET_DIGITALIZATION',
                                payload: result.data?.[0]?.data.digitalization
                        })
                        dispatch({
                            type: 'SET_FINANCES',
                            payload: result.data?.[0]?.data?.finances
                        })
                        dispatch({
                            type: 'SET_FORM',
                            payload: result.data?.[0]?.data.formAnswers
                        })

                        }
                    })
                    .catch((error)=>console.log('Error loading company',error))
                    .finally(()=>setLoading(false))
        }else{
            setInvalidBusinessId('Virheellinen y-tunnus')
            setCompanyData(undefined)
        }
    }

    const back=(e:any)=>{
        e.preventDefault()
        navigate('/admin/search')
    }

    return(
        <>
    <DuetSpacer size="xxx-large" />
    <DuetLink url="/admin/search" onClick={back}>Takaisin</DuetLink>
    <DuetSpacer size="xxx-large" />
    <DuetHeading level='h2'  style={{justifyContent: 'center'}}>Yritysraportti</DuetHeading>
    <DuetSpacer size="xxx-large" />
            {companyData &&(
                <>
                <CompanyDetails details={companyDetails(companyData)} businessId={id!} id={id!}/>
                    <QuestinaireAnswers businessId={id!} /><div className="span-2">
                    <ColumnCharts />
                    <PieCharts />
                </div></>)}
    </>
    )
}