import axios, { AxiosError } from "axios"

export const attemptLogin = async (LoginFormData: FormData) => {
  try {
    const response = await axios({
      method: "post",
      url: "/email-login",
      data: {
        businessId: LoginFormData.get("businessId"),
        email: LoginFormData.get("email")
      }
    })

    return response
  } catch (error) {
    const e = error as AxiosError
    const { response } = e

    if (response) {
      // If we have a response, the request didn't fail. It was just erroneous.

      return response
    } else {
      // No response, the request failed outright without reaching the server.
      console.error("Request failed", e.toJSON())

      return e
    }
  }
}
