import { number } from 'yargs'
import { State } from '../types/state'

const windowLocationSearch = new URLSearchParams(window.location.search)
let tokenString = windowLocationSearch.get('t')

export const initialState: State = {
  page: 2,
  shortForm: false,
  finished: false,
  testing: false,
  share: false,
  // shareLoading: false,
  shareMessage: null,
  // shareError: false,
  // error: {
  //     status: false,
  //     message: null
  // },
  error: undefined,
  identity: {
    id: null,
    formInstanceId: null,
    email: null,
    inputEmail: null,
    name: null,
    industryCode: null,
    industryHumanReadable: null,
    fetched: false,
    areaCompanyId: 0,
  },
  finances: {
    // These are picked from Vainu data based on current year
    previous: {
      equityToAssetsRatio: null,
      netIncome: null,
      profit: null,
      staff: null,
      turnOver: null,
      year: null,
      document: null
    },
    latest: {
      equityToAssetsRatio: null,
      netIncome: null,
      profit: null,
      staff: null,
      turnOver: null,
      year: null,
      document: null,
      liquidity: null,
      returnOnEquity: null,
      returnOnInvestment: null,
      value: null
    },
    industry: {
      netIncome: null,
      profit: null,
      staff: null,
      turnOver: null,
      year: null,
      industryStatistics: null
    },
    stats: {
      // LT provides these?
      equityToAssetsRatio: null, // We get this from Vainu, but lets add it here also for clearer structure when doing pdf
      liquidity: null,
      returnOnEquity: null,
      returnOnInvestment: null,
      value: null
    }
  },
  estimates:{
    expectedTurnOver: {
      percentage: null,
      calculated: null,
      changed: false
    },
   expectedProfit: {
      percentage: null,
      calculated: null,
      changed: false,
    },
    staff:{
      amount: null,
      changed: false
    },
    lastFinances: {
      year: null,
      turnOver: null,
      netIncome: null,
      staff: null
  },
  },
  digitalization: {
    digitality: null,
    sociality: null,
    marketability: null
  },
  formAnswers: {
    wishesContact: false,
    currentYearMissing: false,
    currentYearPartial: true,
    lastFinances: {
      year: undefined,
      turnOver: undefined, 
      staff: undefined,
      netIncome: undefined,
    },
    expectedTurnOver: {
      percentage: null,
      calculated: null,
      changed: false
    },
    expectedProfit: {
      percentage: null,
      calculated: null,
      changed: false
    },
    risks: [],
    phone: [],
    sendInfoBy: [],
    events: [],
    staff: {
      amount: null,
      changed: false
    },
    turnOver: {
      value: null,
      changed: false
    },
    netIncome: {
      value: null,
      changed: false
    }
  },
  newsLeads: {
    target: null,
    news: [],
    newsNotFound: false
  },
  ltNews: [],
  shareString: tokenString ?? ''
}

if (process.env.NODE_ENV === 'production' && window.token) {
  if (window.token.m) {
    window.token.email = window.token.m
  }
  initialState.identity.id = window.token.id || null
  initialState.identity.email = window.token.email || null
}

// initialState was originally 'business'
