import { DuetCard, DuetGrid, DuetGridItem, DuetLayout, DuetLink, DuetListItem, DuetParagraph } from '@duetds/react';
import { DuetHeading, DuetList } from '@duetds/react/commonjs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Economy from './Economy';
import { toNumberPresentation }  from './utils';;

export type VainuFinancialStatement = {
    currency_code: string
    equity_to_assets_ratio: string
    net_income_euro: string
    net_income_local: string
    profit: string
    result_before_taxes_euro: string
    result_before_taxes_local: string
    return_on_equity: number
    return_on_investment: number
    total_assets: number
    total_equity: number
    turn_over_eur: string
    turn_over_is_estimate: boolean
    turn_over_local: string
    staff_number: number
    
    year: number
}

export type Contact = {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    title: string;
}

export type VainuResponse = {
    address: string;
    postal: string;
    city: string;
    visiting_address?: string;
    visiting_postal?: string;
    visiting_city: string;
    business_id: string;
    company_name: string;
    title: string;
    contacts: Contact[];
    financial_statements: VainuFinancialStatement[];
    status:string
    registration_date: string;
}




/*
    account_period_end: "2016-12-31"
    account_period_start: "2016-01-01"
    currency_code: "EUR"
    equity_to_assets_ratio: "37.05"
    net_income_euro: "49773"
    net_income_local: "49773"
    profit: "10.78"
    quick_ratio_percent: null
    result_before_taxes_euro: "62282"
    result_before_taxes_local: "62282"
    return_on_equity: 95.22
    return_on_investment: 44.18752657539886
    staff_number_is_estimate: null
    total_assets: 141084.43
    total_equity: 52273.56
    turn_over_eur: "578326"
    turn_over_is_estimate: false
    turn_over_local: "578326"
    year: 2016
*/
/*
type FinancialStatement = {
    accountPeriodEnd: string
    accountPeriodStart: "2016-01-01"
    currency_code: "EUR"
    equity_to_assets_ratio: "37.05"
    net_income_euro: "49773"
    net_income_local: "49773"
    profit: "10.78"
    quickRatioPercent: null
    resultBeforeTaxesEuro: "62282"
    resultBeforeTaxesLocal: "62282"
    returnOnEquity: 95.22
    return_on_investment: 44.18752657539886
    staff_number_is_estimate: null
    total_assets: 141084.43
    total_equity: 52273.56
    turn_over_eur: "578326"
    turn_over_is_estimate: false
    turn_over_local: "578326"
    year: 2016
}

type vainuResult = {
    address: string;
    "business_id": string;
    "company_name": string;
    active?: null|boolean;
    postal?: null|string;
    status?: null|string;
    "staff_number"?: number|null;
    "turn_over"?: number|null;
    "financial_statements": any[];
}


*/

type HoldingsProps={
    data?:{first_name:string; last_name:string; title:string}[]
}
function Holdings({data=[]}:HoldingsProps){
    return(
        <DuetCard heading="Yhteystiedot">
            <DuetList variation="striped">
                {data.map(({first_name,last_name, title},i)=>(<DuetListItem key={`${i}`}>
                    <span slot="label">{title}</span>
                    <span slot="value">{first_name} {last_name}</span>
                </DuetListItem>))}
            </DuetList>

        </DuetCard>
    )
}
export default function VainuView(){

    const {id} = useParams()
    const [vainuResult, setVainuResult ] = useState<VainuResponse|undefined>(undefined)


    useEffect(()=>{
        axios.post<VainuResponse>('/vainu/all',{businessId: id})
            .then((result)=>{
                console.log('VAinu', result.data)
                setVainuResult(result.data);
            })
            .catch((err)=>{
                console.log('Vainu ' ,err)
            })

    },[id])
    
    const sortFin=(a:VainuFinancialStatement,b:VainuFinancialStatement)=>{
        if(a.year<b.year) return -1
        if(a.year>b.year) return 1
        return 0
    }
    const {year, turn_over_eur, profit,staff_number,} = vainuResult?.financial_statements.sort(sortFin)[vainuResult?.financial_statements.length - 1] || {}
    const {
        status,
        registration_date,
        address,
        visiting_address,
    
    } = vainuResult || {}

    if(vainuResult=== undefined) return null;

   
    const reg= vainuResult?.financial_statements.slice(-2)
    return(
        <DuetLayout>
            <div slot="main">
                    <DuetLink>Takaisin</DuetLink>
                    <DuetHeading>{vainuResult?.company_name}</DuetHeading>
                    <DuetParagraph>
                        Yritystiedot tarjoaa <DuetLink>Vainu.io</DuetLink>
                    </DuetParagraph>
                    <div className='grid grid-columns-3'>
                            <DuetCard>
                                <DuetHeading level='h4'>Liikevaihto {year}</DuetHeading>
                                <div className="bigtext">{toNumberPresentation(turn_over_eur)}€</div>
                            </DuetCard>
                    
                            <DuetCard>
                                <DuetHeading level='h4'>Liikevoitto {year}</DuetHeading>
                                <div className="bigtext">{profit} %</div>
                            </DuetCard>
                            <DuetCard>
                                <DuetHeading level='h4'>Henkilöstö {year}</DuetHeading>
                                <div className="bigtext">{staff_number}</div>
                            </DuetCard>
                    </div>
                    
                    <DuetCard heading="Yrityksen tiedot">
                        <DuetList variation="striped">
                            <DuetListItem>
                                <span slot="label">Y-tunnus</span>
                                <span slot="value">{id}</span>
                            </DuetListItem>
                            <DuetListItem>
                                <span slot="label">Perustettu</span>
                                <span slot="value">{registration_date?.split('-')?.[0]} {status}</span>
                            </DuetListItem>
                            <DuetListItem>
                                <span slot="label">Käyntiosoite</span>
                                <span slot="value">{ visiting_address || address}</span>
                            </DuetListItem>
                        </DuetList>
                    </DuetCard>
                    <Economy financialStatements={vainuResult?.financial_statements}/>
                    <Holdings data={vainuResult?.contacts}/>
                    <DuetCard heading="Henkilöstö">
                        <DuetList variation="striped" margin="none">
                            <DuetListItem>
                                <span slot="label">Henkilöstömäärä</span>
                                <span slot="value">{staff_number}</span>
                            </DuetListItem>
                            <DuetListItem>
                                <span slot="label">Rekrytöi</span>
                                <span slot="value">+ 15 %</span>
                            </DuetListItem>
                        </DuetList>

                    </DuetCard>
            </div>
        </DuetLayout>
    )
}