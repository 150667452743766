import React from "react";
import { DuetSpacer, DuetGrid, DuetGridItem, DuetButton, DuetHeading, DuetParagraph } from "@duetds/react";
import { useStateValue } from "../../state/state";
import useForm from "../../hooks/useForm";

export default function Heading() {
    const [state] = useStateValue();
    const { refillFormAndGotoPreviousPage } = useForm();

    return (
        <>
            <DuetSpacer size="xx-large" />
            <DuetGrid alignment="center" direction="vertical">
                <DuetGridItem>
                    {!state.share && (
                        <DuetButton
                            variation="plain"
                            icon="action-arrow-left-small"
                            onClick={refillFormAndGotoPreviousPage}
                        >
                            Täytä kysely uudestaan
                        </DuetButton>
                    )}
                </DuetGridItem>
                <DuetGridItem>
                <DuetSpacer size="small" />
                    <DuetHeading level="h2">{state.identity.name}</DuetHeading>
                </DuetGridItem>
                <DuetGridItem>
                    <DuetParagraph>Y-tunnus: {state.identity.id}</DuetParagraph>
                </DuetGridItem>
            </DuetGrid>
            <DuetSpacer size="xx-large" />
        </>
    );
}