import { DuetButton, DuetCard, DuetGrid, DuetGridItem, DuetHeading, DuetList, DuetListItem, DuetParagraph, DuetSpacer } from '@duetds/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAreaCompanyById } from '../../utils/areaCompanies'
import { DateTime } from 'luxon'


type Props = {
    info:{
        _id: string
        businessId: string
        areaCompanyId: number,
        page: number
        createdAt: string
        data: any
    }
}
export function LoadMore({info} :Props){
    const navigate = useNavigate()

    const onClick=(event:any)=>{
        event.preventDefault()
        navigate(`/admin/search/${info._id}`)
    }

    const dateString = DateTime.fromISO(info.createdAt).toFormat('dd.MM.yyyy')
    return(
        <DuetGrid alignment="stretch" direction="vertical">
            <DuetCard onClick={onClick} accessible-label="company name" url={`/admin/search/${info.businessId}`} >
                <DuetGrid responsive alignment='center'>
                        <DuetGridItem fill margin="none">
                        <DuetHeading level='h3' margin="none">{info?.data?.identity?.name}</DuetHeading>
                        <DuetGrid alignment="stretch" distribution="space-between" >
                            <DuetGridItem margin="none" fill>
                                <DuetList
                                    margin="none"
                                >
                                    <DuetListItem>
                                        <span slot="label">Y-tunnus</span>
                                        <span slot="value">{info.businessId}</span>
                                    </DuetListItem>
                                    <DuetListItem>
                                            <span slot="label">Alueyhtiö</span>
                                            <span slot="value">{getAreaCompanyById(info?.data?.identity?.areaCompanyId)?.name ?? 'ei määritelty'}</span>
                                        </DuetListItem>
                                </DuetList>
                            </DuetGridItem>
                            <DuetGridItem  margin="none" fill>
                                <DuetList margin="none">
                                <DuetListItem>
                                        <span slot="label">Pvm</span>
                                        <span slot="value">{dateString}</span>
                                    </DuetListItem>
                                    <DuetListItem>
                                        <span slot="label">Täytetty loppuun</span>
                                        <span slot="value">{info.page>2 ? 'Kyllä' : 'Ei'}</span>
                                    </DuetListItem>
                                </DuetList>
                            </DuetGridItem>
                        </DuetGrid>
                    </DuetGridItem>
                    <DuetGridItem  margin="none" >
                                
                                <DuetButton  margin="none" variation="primary">Näytä tiedot</DuetButton>
                                
                                
                    </DuetGridItem>
                </DuetGrid>
            </DuetCard>
        </DuetGrid>
    )
    /*
    return(
        <DuetGrid alignment="center" direction="vertical">
            <DuetGridItem max-width="920px" fill>
                <DuetCard onClick={onClick} accessible-label="company name" url={`/admin/search/${info.businessId}`} padding="large">
                
                   <DuetGrid>
                        <DuetGridItem fill>
                            <DuetHeading level="h2" visual-level="h3">{info?.data?.identity?.name}</DuetHeading>
                            <DuetSpacer size="xx-small"></DuetSpacer>
                            <DuetList padding="none" margin="none" >
                                <DuetListItem>
                                    <span slot="label">Y-tunnus</span>
                                    <span slot="value">{info.businessId}</span>
                                </DuetListItem>
                                <DuetListItem>
                                    <span slot="label">Alueyhtiö</span>
                                    <span slot="value">{getAreaCompanyById(info?.data?.identity?.areaCompanyId)?.name ?? 'ei määritelty'}</span>
                                </DuetListItem>
                            </DuetList >
                            <DuetGridItem>
                                <DuetList>
                                <DuetListItem>
                                        <span slot="label">Täytetty loppuun</span>
                                        <span slot="value">{info.page>2 ? 'Kyllä' : 'Ei'}</span>
                                    </DuetListItem>
                                </DuetList>
                            </DuetGridItem>
                    </DuetGridItem>
                    <DuetGridItem  fill margin="none">
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 0 0 auto'}}>
                            <DuetButton
                                variation="primary"
                                wrapping="none" margin="none"
                                >
                                    Näytä tiedot
                            </DuetButton>
                            </div>

                    </div>
                    </DuetGridItem>
                    </DuetGrid>
                </DuetCard>
            </DuetGridItem>
        </DuetGrid>
    )
    */
}