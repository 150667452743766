import { DuetButton, DuetGrid, DuetGridItem, DuetHeader, DuetHeading, DuetLayout, DuetModal, DuetParagraph } from "@duetds/react";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FilterDetails from './FilterDetails'

import Filter from './Filter'
import axios from "axios";
import { result } from "lodash";
import EditFilter from "./EditFilter";
import { DuetSpacer } from "@duetds/react/commonjs";
import Footer from "../Footer";


type Segment = {
    _id: string;
    name: string;
    createdAt: string;
}
export function FilterList(){
    const navigate = useNavigate()

    const [segments, setSegments] = useState<Segment[]>([]);
    const [deleteId, setDeleteID ] = useState<string|undefined>();
    const active = !!deleteId;

    const remove = (id:string)=>()=>setDeleteID(id);
    const edit = (id:string)=>()=>navigate(`edit/${id}`);

    const removeName = segments.find(({_id})=>_id === deleteId)?.name

    const deleteSegment = ()=>axios.post('/api/admin/segment/delete',{ segment:{_id: deleteId }},{withCredentials: true})
        .then(re=>{
            axios.get('/api/admin/segment/segments',{withCredentials: true}).then(
                (result)=>{
                setSegments(result.data)
            }
            ).catch(error=>console.log('Error',error))
        })
        .catch(err=>console.log('error',err))

    useEffect(()=>{
        axios.get('/api/admin/segment/segments',{withCredentials: true}).then(
            (result)=>{
            setSegments(result.data)
        }
        ).catch(error=>console.log('Error',error))
    },[])
    return(
        <>
        <DuetLayout className="duet-bg-gradient layout" margin="none">
        <div slot="main">
            <DuetHeading className="center-text">
                Suodata vastauksia
            </DuetHeading>
            <DuetModal
                heading="Oletko varma"
                active={active}
                onDuetClose={()=>setDeleteID(undefined)}
            >
                <DuetParagraph>että haluat poistaa säännön</DuetParagraph>
                <DuetParagraph>{removeName}</DuetParagraph>
                <DuetGrid distribution="space-evenly">
                    <DuetGridItem>
                <DuetButton
                    variation="destructive-primary"
                    onClick={()=>{
                        deleteSegment()
                            .catch(err=>console.log('error'))
                            .finally(()=>setDeleteID(undefined))
                    }}
                >
                    Kyllä
                </DuetButton>
                </DuetGridItem>
                <DuetGridItem>
                    <DuetButton variation="secondary" onClick={()=>setDeleteID(undefined)}>Ei</DuetButton>
                </DuetGridItem>
                </DuetGrid>
            </DuetModal>
            <DuetParagraph className="center-text" >
                Suodata SYKE-kyselyyn vastanneiden yritysten tietoja valitsemiesi hakuehtojen perusteella
            </DuetParagraph>
            <DuetSpacer size="xxx-large" />
            <div style={{display: 'flex', justifyContent:'center'}}>
            <DuetButton
                variation="primary"
                onClick={()=>navigate('new')}
            >
                Luo uusi suodatusehto
            </DuetButton>
            </div>
            <DuetSpacer size="xxx-large" />
            {segments.map(({_id, name, createdAt}) => <Filter key={_id} name={name} created={new Date(createdAt)}  remove={remove(_id)} edit={edit(_id)}/>)}
        </div>
        </DuetLayout>
        <Footer />
        </>
    )
}
export default function Filters(){
    return(
        <Routes>
            <Route path="new" element={<EditFilter newFilter />}/>
            <Route path="edit/:id" element={<EditFilter />}/>
            <Route index element={<FilterList/>} />
        </Routes>
    )
}