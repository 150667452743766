import axios from "axios";
import { State } from "../types/state";

export const saveForm = async (state: State) => axios.post('/save', {...state, error: undefined })

export const sendReport = (businessId: string, email: string, inputEmail: string | null, shareString: string | null) => axios.post('/send', {
    businessId,
    email,
    inputEmail,
    shareString,
    // headers: {
    // 'Content-Type':  'application/json',
    // 'X-XSRF-TOKEN': window.csrf
    // },
    // credentials: 'include', 
});

export const sendShareLink = (shareString: string, email: string) => axios.post('/share', {
    shareString,
    email,
    // headers: {
    //     'Content-Type': 'application/json',
    //     'X-XSRF-TOKEN': window.csrf
    // }
});

export const checkIsFormFinished = async (businessId: string, email: string, shareString: string | null) => axios.post('/finished', {
    businessId,
    email,
    shareString,
    // headers: new Headers({
    //     'Content-Type': 'application/json',
    //     'X-XSRF-TOKEN': window.csrf
    // }),
    // credentials: 'include'
});

export const checkCurrentPage = (businessId: string, email: string, shareString: string | null) => axios.post('/page', {
    businessId,
    email,
    shareString,
    // headers: new Headers({
    //     'Content-Type': 'application/json',
    //     'X-XSRF-TOKEN': window.csrf
    // }),
    // credentials: 'include'
});

export const fetchLtNews = () => axios.get(`/lt/news`);

export const getShareData = (shareString: string | null) => {
    return axios.get('/share', {
        params: {
            share: shareString,
        }
    })
};

export const generateToken = (businessId:string, email: string, areacompany: number) => axios.post('/api/admin/generateToken', {
    businessId,
    email,
    areacompany
});

export const checkFormStatus = (shareString: string) => axios.get(`/formStatus?token=${encodeURIComponent(shareString)}`);