import React from 'react';
import { DuetCard, DuetHeading, DuetLayout, DuetParagraph, DuetSpacer } from '@duetds/react/commonjs';
import Header from './Header';
import Footer from './Footer';

export default function ErrorPage() {
    return (<>
    <Header contact="Ota yhteyttä" region="Syke" />
    <DuetLayout margin="none" center>
    
    <div slot="main" className="duet-text-center">
        <DuetSpacer />
        <img src="https://cdn.duetds.com/api/assets/illustrations/lt-error.png"
            alt="Kuvitus" style={{maxWidth: "70%", width:"375px", height:"auto"}}/>
        <DuetSpacer size="large" className='vertical large duet-spacer'/>
        <DuetHeading level="h1">Jokin meni pieleen, pahoittelemme</DuetHeading>
        <DuetCard variation="plain" padding="x-small">
        <DuetParagraph variation="intro">
            Palvelussamme on juuri nyt häiriö, yritäthän myöhemmin uudelleen.
            Voit olla myös yhteydessä asiakaspalveluumme.
        </DuetParagraph>
        </DuetCard>
        <Footer />
    </div>
    </DuetLayout>
    </>
    )
}
