import axios from "axios";

export const fetchVainuData = (businessId: string) => axios.post('/vainu/all', {
    businessId, //  businessId: id.replace(/-/g, '')
    // headers: {
    //     'Content-Type': 'application/json',
    //     'X-XSRF-TOKEN': window.csrf
    // },
    // credentials: 'include', 
});

export const getTargetGroups = () => axios.get('/vainu/targetGroups');

export const getTargetGroupLeads = (id: string) => axios.post('/vainu/targetGroupLeads/', {
    target_group_id: id.replace(/-/g, ''),
});