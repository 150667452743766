import { useStateValue } from "../state/state";
import { updateVainuData, setTargetGroup, setTargetGroupLeads } from "../state/actions";
import { parseVainuData } from "../utils/vainuUtils";
import { getTarget } from "../utils/utils";
import { fetchVainuData, getTargetGroups, getTargetGroupLeads } from "../api/vainu";

const useFetchVainuData = () => {
    const [{ identity, finances, digitalization, formAnswers }, dispatch] = useStateValue();

    const fetchTargetGroupLeads = (industryCode: string) => getTargetGroups().then((targetGroupData) => {
        const target = getTarget(targetGroupData, industryCode);
        dispatch(setTargetGroup(target));
        return target.id;
    }).then((targetId) => {
        //getTargetGroupLeads(targetId).then((response) => {
         //   console.log('GET TARGET GROUP LEADS', response.data);

         //   console.log('response setTArgetGroupLeads', response.data)
        //    dispatch(setTargetGroupLeads(response.data));

        // Use hardcoded news, since for some reason api provides somewhat odd leads 
        dispatch(setTargetGroupLeads([
            {
                id:1,
                title: 'Korkojen nousu huolestuttaa etenkin suurvelallisia – LähiTapiolan ekonomisti: ”Suosittu suojautumiskeino korkojen nousulle voi osoittautua liian tehokkaaksi”',
                link: 'https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet/uutiset/uutinen/1509577804756',
                
            },
            {
                id:2,
                title: 'Rajuilmat ja myrskyt aiheuttavat mittavia vahinkoja vuosittain – näin tunnistat herkästi kaatuvan puun ja suojaat omaisuutesi ',
                link: 'https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet/uutiset/uutinen/1509577777555',
                
            },
            {
                id:3,
                title: 'Poikkeukselliset ajat autokaupassa – kokosimme vinkkejä auton hankintaa harkitsevalle”',
                link: 'https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet/uutiset/uutinen/1509577903102',
                
            },
            {
                id:4,
                title: 'Yrittäjien Arjen katsaus -kysely: Joka toinen yrittäjä huolissaan tulevan eläkkeensä pienuudesta',
                link:'https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet/uutiset/uutinen/1509577012548',
            },
            {
                id:5,
                title: 'Yrittäjä: ”Säästäminen olisi kannattanut aloittaa parikymmentä vuotta sitten eikä vasta nyt”',
                link: 'https://www.lahitapiola.fi/tietoa-lahitapiolasta/uutishuone/uutiset-ja-tiedotteet/uutiset/uutinen/1509576899467',
                
            }
        ]));
    });

    const getVainuData = (businessId: string) => fetchVainuData(businessId)
        .then(({ data }) => {
            const parsedVainuData = parseVainuData({ identity, finances, digitalization, formAnswers }, data);
            dispatch(updateVainuData(parsedVainuData));
            return parsedVainuData.identity.industryCode;;
        }).then((industryCode) => {
            if (industryCode) {
                fetchTargetGroupLeads(industryCode);
            }
        }).catch((error) => console.log(error));

    return { getVainuData, fetchTargetGroupLeads };
};

export default useFetchVainuData;
