import React from "react";
import { DuetCard } from "@duetds/react";
import useWindowSize from "../../hooks/useWindowSize";
import ReportCard from "./ReportCard";
import { useStateValue } from "../../state/state";

// TODO: fix chart mounting problem 
export default function ColumnCharts() {
    const { isMobile } = useWindowSize();
    const [state] = useStateValue();

    const name = state.identity.name ?? undefined
    return (
        <DuetCard padding="small" heading="Vertailu toimialaan">
            {!isMobile ? <div className="grid">
                <ReportCard title="Liikevaihto" name={name}/>
                <ReportCard title="Liiketulos"  name={name}/>
                <ReportCard title="Liikevoittoprosentti"  name={name}/>
                <ReportCard title="Henkilöstö"  name={name}/>
            </div> : <ReportCard />}
        </DuetCard>
    );
}