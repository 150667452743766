const risks = {
    'N': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.3 päivää vuodessa/ työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Sekä paperisten että sähköisten tietovarastojen merkitys korostuu alallasi ja näiden tietovarastojen palautuskustannukset voivat olla suuria. Erityisesti pilvipalveluiden käyttöön ja tietojen varmistukseen on siis kiinnitettävä huomiota.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia.Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    'M': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 7.6 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Sekä paperisten että sähköisten tietovarastojen merkitys korostuu alallasi ja näiden tietovarastojen palautuskustannukset voivat olla suuria. Erityisesti pilvipalveluiden käyttöön ja tietojen varmistukseen on siis kiinnitettävä huomiota.',
        'Huomioithan, että sopimusten kautta voi tulla suuriakin vastuita, jopa tunnistamatta, jos sopimusten hallinta ei ole kunnossa.',
        'Asiakkaan kaikki toimialat, joita konsultoidaan ja joille tuotetaan palveluja, on selvitettävä huolella'
    ],
    '74909': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 7.6 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Sekä paperisten että sähköisten tietovarastojen merkitys korostuu alallasi ja näiden tietovarastojen palautuskustannukset voivat olla suuria. Erityisesti pilvipalveluiden käyttöön ja tietojen varmistukseen on siis kiinnitettävä huomiota.',
        'Huomioithan, että sopimusten kautta voi tulla suuriakin vastuita, jopa tunnistamatta, jos sopimusten hallinta ei ole kunnossa.',
        'Asiakkaan kaikki toimialat, joita konsultoidaan ja joille tuotetaan palveluja, on selvitettävä huolella'
    ],
    'Q': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Sairauspoissaolojen määrä on toimialalla keskimääräistä suurempi. Toimialasi sairaus- ja tapaturmapoissaolot ovat 13.1 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Alallasi korostuu lainsäädännön muutokset ja niiden vaikutukset yritystoimintaan.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Väestön ikääntyminen ja esimerkiksi Sote-uudistus voivat luoda uusia mahdollisuuksia.'
    ],
    '7021': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.0 päivää vuodessa/työntekijä.',
        'Sekä paperisten että sähköisten tietovarastojen merkitys korostuu alallasi ja näiden tietovarastojen palautuskustannukset voivat olla suuria. Erityisesti pilvipalveluiden käyttöön ja tietojen varmistukseen on siis kiinnitettävä huomiota.',
        'Toimialalla aineettomien oikeuksien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Toimialalla on käynnissä sisäinen rakennemuutos, kun digitalisaatio ja globalisaatio tuovat uusia haasteita ja mahdollisuuksia.'
    ],
    'G': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '45': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46692': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46210': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46499': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46901': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46492': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46739': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46432': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46390': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46521': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46442': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46741': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46412': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '46770': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '4648': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '465': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '4652': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    '4674': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 8.7 päivää vuodessa/työntekijä.',
        'Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius.',
        'Muistathan vakuuttaa myös mm. mahdolliseen liiketilaan tehdyt parannustyöt.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'On tärkeää mitoittaa varasto oikealle tasolle kysyntään nähden.',
        'Huomioithan toimialaasi liittyvät riskit, jotka liittyvät mm. erilaisiin sopimuksiin ja laitteiden rikkoutumisiin.'
    ],
    'H': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 11.3 päivää vuodessa/työntekijä.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Huomioithan alaasi liittyvät erilaiset riskit. Näihin riskeihin kuuluu mm. koneiden rikkoutumiset ja siitä aiheutuvat toiminnan keskeytymiset. Toiminnan sujuvuus voikin olla riippuvainen yhdestä tai useammasta avainkoneesta.',
        'Alalla varastoihin on usein sidottu pääomaa, joka olisi tuottavampaa vapauttaa muuhun tarpeeseen. Varastonhallintajärjestelmillä on suuri merkitys toiminnassa.'
    ],
    '52100': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 11.3 päivää vuodessa/työntekijä.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Huomioithan alaasi liittyvät erilaiset riskit. Näihin riskeihin kuuluu mm. koneiden rikkoutumiset ja siitä aiheutuvat toiminnan keskeytymiset. Toiminnan sujuvuus voikin olla riippuvainen yhdestä tai useammasta avainkoneesta.',
        'Alalla varastoihin on usein sidottu pääomaa, joka olisi tuottavampaa vapauttaa muuhun tarpeeseen. Varastonhallintajärjestelmillä on suuri merkitys toiminnassa.'
    ],
    '521': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 11.3 päivää vuodessa/työntekijä.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Huomioithan alaasi liittyvät erilaiset riskit. Näihin riskeihin kuuluu mm. koneiden rikkoutumiset ja siitä aiheutuvat toiminnan keskeytymiset. Toiminnan sujuvuus voikin olla riippuvainen yhdestä tai useammasta avainkoneesta.',
        'Alalla varastoihin on usein sidottu pääomaa, joka olisi tuottavampaa vapauttaa muuhun tarpeeseen. Varastonhallintajärjestelmillä on suuri merkitys toiminnassa.'
    ],
    '5210': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 11.3 päivää vuodessa/työntekijä.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Huomioithan alaasi liittyvät erilaiset riskit. Näihin riskeihin kuuluu mm. koneiden rikkoutumiset ja siitä aiheutuvat toiminnan keskeytymiset. Toiminnan sujuvuus voikin olla riippuvainen yhdestä tai useammasta avainkoneesta.',
        'Alalla varastoihin on usein sidottu pääomaa, joka olisi tuottavampaa vapauttaa muuhun tarpeeseen. Varastonhallintajärjestelmillä on suuri merkitys toiminnassa.'
    ],
    '55909': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '55209': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '5510': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '55': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '551': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '559': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '5590': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '552': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '5520': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '5610': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '561': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '56': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '562': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Työssä saattaa kohdata väkivalta- ja uhkatilanteita, joiden varalta tulisi olla toimintavalmius. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Muistathan vakuuttaa myös mm. mahdolliseen toimitilaan tehdyt parannustyöt.'
    ],
    '85530': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85599': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85320': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85592': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85312': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85200': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85311': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85420': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85100': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8541': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8542': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8510': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '85': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '855': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8552': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8559': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '853': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8532': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '852': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '8520': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '854': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '851': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 9.1 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.',
        'Muun muassa väestön ikääntyminen voi luoda uusia mahdollisuuksia.'
    ],
    '42110': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '42130': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '42999': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '42910': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    'F': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '42120': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '412': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '4120': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '41': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '42': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '422': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '4291': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '4212': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '4299': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '429': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '4213': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialalla työpaikkatapaturmataajuus on hyvin korkea. Toimialasi sairaus- ja tapaturmapoissaolot ovat 14,0 päivää vuodessa/työntekijä. Laadukas työturvallisuustyö maksaa itsensä takaisin.',
        'Alasi yleisimpiä vahinkoja ovat vastuuvahingot, henkilökeskeytysvahingot, oikeusturvavahingot, murto- ja ilkivaltavahingot ja palovahingot.',
        'Alalla on yleistä pitää hallussa toisten omaisuutta, joka jää normaalin vastuuvakuutuksen ulkopuolelle. Myös työkohteen ja ympäröivän omaisuuden riskit tulee selvittää.',
        'Alihankintaverkoston johtaminen ja verkoston osana toimiminen on monesti haasteellista, jolloin sopimusriskien hallinta korostuu.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    'C': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen. Toimialasi sairaus- ja tapaturmapoissaolot ovat 12.3 päivää vuodessa/työntekijä.',
        'On hyvä huomioida, että työntekijöiden toiminnasta asiakkaalle aiheutuvat taloudelliset vahingot saattavat muodostua suuriksi.',
        'Huomioithan alaasi liittyvät erilaiset riskit. Näihin riskeihin kuuluu mm. koneiden rikkoutumiset ja siitä aiheutuvat toiminnan keskeytymiset. Toiminnan sujuvuus voikin olla riippuvainen yhdestä tai useammasta avainkoneesta.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '90010': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '9001': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '90040': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '7410': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '741': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '90030': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '9003': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '90': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    '900': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ],
    'OTHER': [
        'On ensiarvoisen tärkeää panostaa työhyvinvointiin ja henkilöstön sitouttamiseen.',
        'Alallasi korostuu projekti- ja tarjouskannan hallinta sekä toisaalta hinnoittelun onnistuminen.',
        'On hyvin tärkeää tunnistaa keskeisimmät sidosryhmät ja näiden merkitys yrityksen toiminnalle.',
        'Mahdollisia muutostilanteita varten on tärkeää tehdä suunnitelmia. Valmiin suunnitelman avulla voit varautua paremmin esimerkiksi tilanteeseen, jossa yrityksesi tarvitsee lisärahoitusta.'
    ]
};

export const getRisks = (industryCode: string) => {
    return risks[industryCode as keyof typeof risks] || risks['OTHER'];
};
