import { DuetButton, DuetLayout, DuetStepper } from '@duetds/react';
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import CustomerSegment from './CustomerStep';
import FilterRules from './FilterRules';
import Filters from './Filters';
import { FilterDataType, Rule, SubRule, NumberInput, CheckBoxInput, isNumberInput, Delivery} from './types';


const  getFilter = (id:string) => axios.post('/api/admin/segment/find',{segment: id},{withCredentials: true})

export default function EditFilter({newFilter}:{newFilter?:boolean}){
    const [step,setStep ] = useState<number>(0)
    const navigate = useNavigate();

    const increment = ()=> setStep(step +1)
    let { id } = useParams();

    const [segmentFilter, setFilter]=useState<FilterDataType|undefined>(undefined);

        
    if(newFilter){
    

        if(segmentFilter === undefined){

        /*
 _id: string;
    name: string;
    createdAt: string;
    modified: string;
    emails: string[];
    delivery: Delivery;
    rules: Rule[];
    areaCompanyId: number;
        */
        setFilter({
            _id: 'new',
            name: '',
            emails: [],
            modified: '',
            createdAt: '',
            rules:([] as Rule[]),
            delivery: {
                checked: false,
                frequency: 'monthly',
                day: 1,
            } as Delivery,
            areaCompanyId: 0
        })
    }
    }

    const filterRu=(rules: Rule[], rule:Rule | SubRule) => {
        return rules.filter((r=> rule !== r))
                            .map(r=>({
                                ...r,
                                subRules: r.subRules.filter(sr=>sr !==rule)
                            }));
    }


    const removeRule=(rule: Rule | SubRule) => {

        if(segmentFilter=== undefined) return

        /*const rules = segmentFilter.rules.filter((r=> rule !== r))
                            .map(r=>({
                                ...r,
                                subRules: r.subRules.filter(sr=>sr !==rule)
                            }));
        */
        const rules = filterRu(segmentFilter.rules, rule)
        const rr=rules.map(r=>r.name === 'or' ? {...r, subRules: r.subRules.filter(s=>s !== rule)}: r)
        const fixed = rr.filter(r=> !(r.name==="or" && r.subRules.length == 0))

        // TODO remove empty or-blocks
        setFilter({
            ...segmentFilter,
            rules: fixed,
        });
    }
    useEffect(() =>{
        if(id===undefined) return
        if(newFilter === undefined)
        getFilter(id)
            .then(result=>setFilter(result.data));
    },[id]);



    const save=()=>{
        console.log('SAVE')
        if(newFilter){
            axios.post('/api/admin/segment/create',{segment: segmentFilter},{withCredentials: true})
            .then(result=>{
                navigate('/admin/filter')
            })
            .catch(error=>console.log('error',error))
        }else{
            axios.post('/api/admin/segment/save',{segment: segmentFilter},{withCredentials: true})
                .then(result=>result)
                .catch(error=>console.log('error',error))
        }
    }

    const reload=()=>{
        if(id===undefined) return;
            
        getFilter(id)
        .then(result=>setFilter(result.data));
    };


    type InputEvent={
        value: string
    }

    type CheckBoxEvent = {
        checked: boolean | null
    }

    function isInputEvent(event: CustomEvent<InputEvent|CheckBoxEvent>):event is CustomEvent<InputEvent>{
        const k = event as unknown as CustomEvent<Partial<InputEvent & CheckBoxEvent>>;
        return !!k.detail.value
    }
    function isCheckBoxEvent(event: CustomEvent<InputEvent|CheckBoxEvent>):event is CustomEvent<CheckBoxEvent>{
        const k = event as unknown as CustomEvent<Partial<InputEvent & CheckBoxEvent>>;
        return !k.detail.value
    }

    const findInput=(input:NumberInput| CheckBoxInput)=> {
        const ruleIndex = segmentFilter?.rules.findIndex(r=>r.subRules.some(sr=>sr.inputs.some(ip=>ip===input)))
        if(ruleIndex===undefined) return undefined
        const subRuleIndex = segmentFilter?.rules[ruleIndex].subRules.findIndex(sr=>sr.inputs.some(ip=>ip===input))
        if(subRuleIndex===undefined) return undefined

        const inputIndex = segmentFilter?.rules[ruleIndex].subRules[subRuleIndex].inputs.findIndex(ip=>ip===input);
        if(inputIndex===undefined) return undefined
        return { ruleIndex, subRuleIndex, inputIndex }
    }

    const changeInput = (   input: NumberInput | CheckBoxInput, value: string | boolean | null) =>{
        const inputLoc = findInput(input)
        if(inputLoc){
            const rules=[...segmentFilter!.rules]
            if(isNumberInput(input) && typeof(value)==="string"){
                const oldIn= rules[inputLoc.ruleIndex].subRules[inputLoc.subRuleIndex].inputs[inputLoc.inputIndex] as NumberInput
                rules[inputLoc.ruleIndex].subRules[inputLoc.subRuleIndex].inputs[inputLoc.inputIndex]={...oldIn, value}
            }else if(typeof(value)!=="string"){
                const oldIn= rules[inputLoc.ruleIndex].subRules[inputLoc.subRuleIndex].inputs[inputLoc.inputIndex] as CheckBoxInput
                rules[inputLoc.ruleIndex].subRules[inputLoc.subRuleIndex].inputs[inputLoc.inputIndex]={...oldIn, value}
            }

            setFilter({
                ...segmentFilter!,

            })
        }
    }

    const addOrBlock=(subRule?:SubRule)=>{

        if(segmentFilter === undefined) return;
        if(subRule === undefined) return


        const orBlock:Rule= {
            name: 'or',
            text: 'tai',
            label: 'tai',
            subRules: subRule ? [subRule] : [],
            inputs: []
        } 

        setFilter({
            ...segmentFilter,
            rules:[...(segmentFilter.rules),orBlock]
        })
    }

    const addSubRule=(rule: Rule) =>(b?: SubRule )=>()=>{
      
        
        const ruleIndex = segmentFilter?.rules.findIndex(r=>r===rule)
        if((ruleIndex !== undefined) && ruleIndex >=0){
            const rules = segmentFilter!.rules;
            rules[ruleIndex].subRules=[...rules[ruleIndex].subRules, b!]
            setFilter({
                ...segmentFilter!,
                rules
            })
        }
    }

    const onInputChange=(input:NumberInput|CheckBoxInput)=>(event:CustomEvent<InputEvent| CheckBoxEvent>)=>{


        if(isNumberInput(input) && isInputEvent(event))
        {
            const { detail:{ value }} =event;

        }else
        {
            if(isCheckBoxEvent(event)){
            const { detail:{ checked }} = event
            }
        }
    
    }

    const addEmail = ()=> {
        if(segmentFilter === undefined) return
        setFilter({
            ...segmentFilter,
        emails: [...segmentFilter.emails,'']
        }
        )
    }
    const removeEmail = (id:number) => () => {
        if(segmentFilter === undefined) return
        const filteredEmails = segmentFilter.emails.filter((_,i) => i !== id)

        setFilter({
            ...segmentFilter,
        emails: filteredEmails
        })
    }

    const changeEmail=(index:number)=>(email:string) =>{
        if(segmentFilter === undefined) return
        const emails = segmentFilter.emails
        emails[index]=email
        setFilter({
            ...segmentFilter,
            emails
        })
    }

    const changeName=(name:string) => {
        if(segmentFilter === undefined) return
    
        setFilter({
            ...segmentFilter,
            name
        })
    }

    const changeDelivery=(delivery:Delivery)=>{
        if(segmentFilter === undefined) return
        setFilter({
            ...segmentFilter,
            delivery,

        })

    }
    if(segmentFilter===undefined) return null
    return(
        <DuetLayout>
        <div slot="main">
            
            <DuetStepper selected={step} className="customer-segment">
                <CustomerSegment
                    nextStep={increment}
                    selectStep={()=>setStep(0)}
                    name={segmentFilter?.name || ''}
                    emails={segmentFilter?.emails || []}
                    automaticDelivery={segmentFilter?.delivery.checked}
                    immediateDelivery={segmentFilter?.delivery?.immediatly}
                    addEmail={addEmail}
                    changeEmail={changeEmail}
                    delivery={segmentFilter.delivery}
                    changeDelivery={changeDelivery}
                    changeName={changeName}
                    removeEmail={removeEmail}
                />
                    <FilterRules
                        next={increment}
                        selectStep={()=>{
                        setStep(1)}}
                        rules={segmentFilter?.rules}
                        removeRule={removeRule}
                        reload={reload}
                        save={save}
                        onInputChange={onInputChange}
                        addOrBlock={addOrBlock}
                        changeInput={changeInput}
                        addSubRule={addSubRule}
                        newRule={newFilter}
                    />
            </DuetStepper>
        </div>
        </DuetLayout>
    )
}