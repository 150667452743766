import React from "react";
import { DuetCard, DuetParagraph, DuetRangeSlider } from "@duetds/react";
import { DuetNumberInput } from "@duetds/react/commonjs";

type Props = {
    lastYearMissing?:boolean
    value: number;
    setValue: (value:number) => void;
    lastYearValue: number;
    setLastYearValue: (value:number) => void;
};

export default function Staff({ value, setValue, lastYearMissing,lastYearValue, setLastYearValue }: Props) {

    return (
        <DuetCard padding="large" className="questionnaire-card" margin="none" heading="Kuinka monta henkeä yrityksesi työllistää" headingLevel="h5">
             {lastYearMissing ? (<>
             <DuetParagraph size="small">
                Yrityksesi toteutunut työntekijöiden määrä edellisenä vuotena
              </DuetParagraph>
             <DuetNumberInput
                labelHidden
                expand
                step={1}
                value={String(lastYearValue)}
                unit="henkilöä"
                onDuetChange={(event) => {
                    if(event.detail.valueAsNumber !== null)
                        setLastYearValue(event.detail.valueAsNumber);
                }}
            />
            <DuetParagraph size="small">
                Arvioi yrityksesi henkilöstömäärää kuluvana vuonna
              </DuetParagraph>
              </>): null}
            <DuetRangeSlider
                expand
                label="Työntekijöiden määrä"
                min={0}
                max={1000}
                step={1}
                unit=" henkilöä"
                debounce={500}
                value={value}
                onDuetChange={(event) => {
                    setValue(event.detail.value);
                }}
            />
            <DuetNumberInput
                labelHidden
                expand
                step={1}
                value={String(value)}
                unit="henkilöä"
                onDuetChange={(event) => {
                    if(event.detail.valueAsNumber !== null)
                        setValue(event.detail.valueAsNumber);
                }}
            />
        </DuetCard>
    );
};