import { useCallback, useEffect, useState } from "react";
import { useStateValue } from "../state/state";
import { saveForm, sendReport, sendShareLink } from "../api/pulssi";
import { CustomInput, formFinished, pageChange, updateForm, updateVainuData, setError } from "../state/actions";
import { validateEmail } from "../utils/utils";

const useForm = () => {
    const [state, dispatch] = useStateValue();
    const { formAnswers } = state;
    const [errors, setErrors] = useState(false);
    const [shareEmail, setShareEmail] = useState('');
    const [shareEmailError, setShareEmailError] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailSendFailed, setEmailSendFailed] = useState(false)
    const [emailSendSuccess, setEmailSendSuccess] = useState(false)

    const setForm = (field: string, value: any) => {
        const updatedForm = { ...formAnswers, [field]: value };
        dispatch(updateForm(updatedForm));
        saveForm({ ...state, formAnswers: updatedForm }).catch((err)=> dispatch(setError('saveForm failed')))
    };

    const setInputEmail = (email: string) => {
        const updatedIdentity = {
            ...state.identity,
            inputEmail: email,
        };
        dispatch(updateVainuData({
            ...state,
            identity: updatedIdentity,
        }));
        saveForm({ ...state, identity: updatedIdentity });
    };

    const setMissingVainuData = ({ id, value }: CustomInput) => {
        let updatedForm;
        if (id === 'turnOver') {
            updatedForm = {
                ...state.formAnswers,
                turnOver: {
                    value,
                    changed: true
                },
                expectedTurnOver: {
                    ...state.formAnswers.expectedTurnOver,
                    // calculated: ((formAnswers.expectedTurnOver.percentage ?? 0) / 100) * Math.abs(value) + value, // (1 + percentage / 100) * value 
                }
            }
        } else {
            updatedForm = {
                ...state.formAnswers,
                netIncome: {
                    value,
                    changed: true
                },
                expectedProfit: {
                    ...state.formAnswers.expectedProfit,
                    // calculated: ((formAnswers.expectedProfit.percentage ?? 0) / 100) * Math.abs(value) + value,
                }
            }
        }

        dispatch(updateForm(updatedForm));
        saveForm({ ...state, formAnswers: updatedForm });
    };

    const setFormFinishedAndGotoNextPage = () => {
        dispatch(formFinished(true));
        dispatch(pageChange('next'));
        saveForm({ ...state, finished: true, page: 3 });
    };

    const refillFormAndGotoPreviousPage = () => {
        dispatch(formFinished(false));
        dispatch(pageChange('previous'));
        saveForm({ ...state, finished: false, page: 2 });
    };

    const validateForm = useCallback(() => {
        const { risks, events } = state.formAnswers;
        return (state.finances.latest.turnOver || state.formAnswers.turnOver.value)
            && (state.finances.latest.netIncome || state.formAnswers.netIncome.value)
            && risks.length > 0
            && events.length > 0
            && ((state.identity.inputEmail && validateEmail(state.identity.inputEmail)) || !state.identity.inputEmail)
    }, [state.finances.latest.netIncome, state.finances.latest.turnOver, state.formAnswers, state.identity.inputEmail]);

    const handleSubmit = () => {
        if (validateForm()) {
            setFormFinishedAndGotoNextPage();
            if (state.identity.id && state.identity.email) {
                sendReport(state.identity.id, state.identity.email, state.identity.inputEmail, state.shareString)
            }
        } else {
            setErrors(true);
        }
    };

    useEffect(() => {
        if (errors) {
            if (validateForm()) {
                setErrors(false);
            }
        }
    }, [errors, state.formAnswers, state.identity.inputEmail, validateForm]);

    const handleShareReport = () => {
        if (validateEmail(shareEmail)) {
            if (state.shareString) {
                setSendingEmail(true)
                sendShareLink(state.shareString, shareEmail)
                    .then(()=>setEmailSendSuccess(true))
                    .catch(()=>setEmailSendFailed(true))
                    .finally(() =>setSendingEmail(false))
            }
        } else {
            setShareEmailError(true);
        }
    };

    useEffect(() => {
        if (shareEmailError) {
            if (validateEmail(shareEmail)) {
                setShareEmailError(false);
            }
        }
    }, [shareEmail, shareEmailError]);

    return {
        formAnswers, setForm, setInputEmail, setMissingVainuData,
        setFormFinishedAndGotoNextPage, refillFormAndGotoPreviousPage,
        validateForm, errors, handleSubmit, shareEmail, setShareEmail,
        shareEmailError, handleShareReport,sendingEmail,emailSendFailed,
        emailSendSuccess, setEmailSendFailed,setEmailSendSuccess,
    };
};

export default useForm;

