import React, { Fragment, useState } from "react"
import { DuetCard, DuetLayout, DuetButton, DuetHeading, DuetInput, DuetParagraph, DuetSpacer } from "@duetds/react"
import axios from "axios"

import Header from "./Header"
import { FormMessages, FormState } from "../types/client-email-login"
import { attemptLogin } from "../api/client-email-login"

export default function ClientEmailLogin({ title, text }: { title: string; text: string }) {
  const headingStyle = { textAlign: "center" }
  const textStyle = { ...headingStyle, marginBottom: "60px" }

  const [formState, setFormState] = useState<FormState>("idle")
  const [formMessages, setFormMessages] = useState<FormMessages>(null)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormState("submitting")

    const resOrErr = await attemptLogin(new FormData(e.currentTarget))

    if (axios.isAxiosError(resOrErr)) {
      const err = resOrErr
      // I've shortcircuited the axios error handling so that it only returns AxiosError when the request
      // downright fails. That happens only when the user has no internet or the server is down.
      setFormState("error")
      setFormMessages({
        error: err.message
      })

      return
    }

    const response = resOrErr
    const { messages } = response.data
    setFormMessages(messages)

    if (response.status === 200) {
      setFormState("done")
    } else {
      setFormState("error")
    }
  }

  return (
    <Fragment>
      <Header contact="Ota yhteyttä" region="Syke" />

      <DuetLayout middle={true} style={{ minHeight: "100%" }} className="duet-bg-gradient">
        <div slot="main">
          <DuetHeading level="h1" style={headingStyle}>
            {title}
          </DuetHeading>

          <DuetParagraph style={textStyle}>{text}</DuetParagraph>

          <DuetCard>
            {formState === "error" ? (
              <DuetParagraph>{formMessages?.error ?? "Kirjautuminen epäonnistui!"}</DuetParagraph>
            ) : null}

            {formState === "done" ? (
              <div>
                <DuetParagraph margin="none">{formMessages?.success}</DuetParagraph>
              </div>
            ) : (
              <form onSubmit={submit}>
                <DuetInput
                  label="Y-tunnus"
                  name="businessId"
                  expand
                  error={formMessages?.businessId}
                  value={process.env.NODE_ENV === "development" ? undefined : undefined}
                />
                <DuetInput
                  label="Sähköposti"
                  type="email"
                  error={formMessages?.email}
                  name="email"
                  icon="form-email"
                  expand
                  value={process.env.NODE_ENV === "development" ? undefined : undefined}
                />

                <DuetSpacer size="xx-large" />
                <DuetButton submit variation="primary" disabled={formState === "submitting"}>
                  Aloita
                </DuetButton>
              </form>
            )}
          </DuetCard>
        </div>
      </DuetLayout>
    </Fragment>
  )
}
