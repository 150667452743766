import React from "react";
import { DuetSpacer, DuetCard, DuetLink } from "@duetds/react";
import { useStateValue } from "../../state/state";
import useWindowSize from "../../hooks/useWindowSize";
import { getRisks } from "../../utils/Risks";

export default function Risks() {
    const [state] = useStateValue();
    const { isMobile } = useWindowSize();
    return (
        <>
            <DuetSpacer size="large" />
            <DuetCard
                padding="small"
                heading="Lähitapiolan näkemyksiä toimintaan liittyvistä yleisistä riskeistä"
            >
                {state.identity.industryCode && <ul className={isMobile ? 'mobile-list-container' : 'grid-columns-3'}>
                    {getRisks(state.identity.industryCode).map((risk) => <li key={risk} className="risks-grid-item">{risk}</li>)}
                </ul>}
                <DuetLink url="https://www.lahitapiola.fi/yritys/palvelut/yrityksen-riskienhallinta" external className="risks-link">
                    Lue lisää
                </DuetLink>
            </DuetCard>
        </>
    );
}