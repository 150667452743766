const AreaCompanies = [
    {
        id: 0,
        name: 'Ei määritelty',
        signatureName: ''
    },
    {
        id: 1,
        name: 'Lappi',
        signatureName: 'Lappi'
    },
    {
        id: 2,
        name: 'Kainuu-Koillismaa',
        signatureName: 'Kainuu-Koillismaa'
    },
    {
        id: 3,
        name: 'Pohjoinen',
        signatureName: 'Pohjoinen'
    },
    {
        id: 4,
        name: 'Pohjanmaa',
        signatureName: 'Pohjanmaa'
    },
    {
        id: 5,
        name: 'Etelä-Pohjanmaa',
        signatureName: 'Etelä-Pohjanmaa'
    },
    {
        id: 6,
        name: 'Keski-Suomi',
        signatureName: 'Keski-Suomi'
    },
    {
        id: 7,
        name: 'Itä',
        signatureName: 'Itä'
    },
    {
        id: 8,
        name: 'Savo',
        signatureName: 'Savo'
    },
    {
        id: 9,
        name: 'Savo-Karjala',
        signatureName: 'Savo-Karjala'
    },
    {
        id: 10,
        name: 'Kaakkois-Suomi',
        signatureName: 'Kaakkois-Suomi'
    },
    {
        id: 11,
        name: 'Vellamo',
        signatureName: 'Vellamo'
    },
    {
        id: 12,
        name: 'Pirkanmaa',
        signatureName: 'Pirkanmaa'
    },
    {
        id: 13,
        name: 'Satakunta',
        signatureName: 'Satakunta'
    },
    {
        id: 14,
        name: 'Lännen',
        signatureName: 'Lännen'
    },
    {
        id: 15,
        name: 'Varsinais-Suomi',
        signatureName: 'Varsinais-Suomi'
    },
    {
        id: 16,
        name: 'Loimi-Häme',
        signatureName: 'Loimi-Häme'
    },
    {
        id: 17,
        name: 'Etelä',
        signatureName: 'Etelä'
    },
    {
        id: 18,
        name: 'Etelärannikko',
        signatureName: 'Etelärannikko'
    },
    {
        id: 19,
        name: 'Uusimaa',
        signatureName: 'Uusimaa'
    },
    {
        id: 20,
        name: 'Pääkaupunkiseutu',
        signatureName: 'Pääkaupunkiseutu'
    },
    {
        id: 21,
        name: 'Suuryritykset',
        signatureName: 'Suuryritykset'
    },
    {
        id: 22,
        name: 'LähiTapiola 1',
        signatureName: ''
    },
    {
        id: 23,
        name: 'LähiTapiola 2',
        signatureName: ''
    }
];

export const getAreaCompanyById = (id:number)=>AreaCompanies[id] 


export default AreaCompanies;