import React from 'react';
import { DuetCard, DuetGrid, DuetHeading, DuetIcon } from '@duetds/react';
import { getDescription, PieTitle } from '../../utils/Descriptions';
import useWindowSize from '../../hooks/useWindowSize';
import { DuetSpacer } from '@duetds/react/commonjs';
import usePieData from '../../hooks/usePieData';

type Props = {
    selectedTitle?: PieTitle;
    selectTitle: (title: PieTitle | undefined) => void;
    className?: string;
    value?: number;
};

export default function PieInfoBox({ selectedTitle, selectTitle, className }: Props) {
    const { isMobile } = useWindowSize();
    const { getPieData } = usePieData();

    if (!selectedTitle) return null;

    const { value, percentage, image, fin } = getPieData(selectedTitle);

    const description = getDescription(selectedTitle, value ?? percentage);

    let descriptionLinesA = description
        ? description.split('.').slice(0, -1).map((line: string) => line.concat('.'))
        : [];

    let descriptionLinesB: string[] = [];

    const linesLength = descriptionLinesA.length;

    if (linesLength > 2 && !isMobile && !image) {
        const firstHalf = Math.round(linesLength / 2);
        descriptionLinesB = descriptionLinesA.slice(firstHalf, linesLength);
        descriptionLinesA = descriptionLinesA.slice(0, firstHalf);
    }

    const InfoImage = () => {
        if (!image) return null;

        const { mobile, desktop, alt } = image;
        const { src, width } = isMobile ? mobile : desktop;

        return (
            <div>
                <DuetSpacer size={isMobile ? 'medium' : 'small'} />
                <DuetGrid distribution="center">
                    <img style={{ width }} src={src} alt={alt} />
                </DuetGrid>
            </div>
        );
    };

    return (
        <DuetCard onClick={() => selectTitle(undefined)} className={className} id="pie-selectedTitle-box">
            <DuetGrid>
            
                <DuetHeading level="h4">{fin}</DuetHeading>
                <DuetIcon
                    size="small"
                    name="navigation-close-small"
                    color="gray"
                    onClick={() => selectTitle(undefined)}
                />
            </DuetGrid>
            <div className={isMobile ? 'mobile-list-container ' : 'grid'}>
                <ul>
                    {descriptionLinesA.map((line) => <li key={line}>{line}</li>)}
                </ul>
                {!isMobile && <InfoImage />}
                {descriptionLinesB.length > 0 && <ul>
                    {descriptionLinesB.map((line) => <li key={line}>{line}</li>)}
                </ul>}
            </div>
            {isMobile && <InfoImage />}
        </DuetCard>
    );
}