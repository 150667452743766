import { DuetCard, DuetLink, DuetList, DuetListItem } from '@duetds/react/commonjs';
import React from 'react';
import { useStateValue } from '../../state/state';

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "http://localhost:8080"

type Props = {
    // answers:[label:string,value:string][]
    businessId: string;
}



export default function CompanyDetails({businessId}:Props){

    const [state] = useStateValue();

    const turn_over = state.formAnswers.turnOver.value
    const turnOverChangePercentage = state.formAnswers.expectedTurnOver.percentage ?
        `${state.formAnswers.expectedTurnOver.percentage} %` : 'Ei määritelty'

    const profit = state?.formAnswers?.netIncome?.value ? `${state?.formAnswers?.netIncome?.value} €` : 'Ei määritelty'
    const expectedProfit = state.formAnswers.expectedProfit.percentage
    const staff = state.formAnswers.staff.amount
    const staffChange = ((state.finances.latest.staff !==null) && (state.formAnswers.staff.amount !== null)) ?
         `${state.formAnswers.staff.amount - state.finances.latest.staff  } henkilöä` : 'Ei määritelty'

    const risks = state.formAnswers.risks.map(risk=><div>{risk}</div>)

    const answers2 =[
        ['Liikevaihto',`${turn_over} €`],
        ['Liikevaihdon kehitys',turnOverChangePercentage],
        ['Liiketulos',profit],
        ['Liiketuloksen kehitys',`${expectedProfit ?? 0} %`],
        ['Henkilöstömäärä',`${staff ?? 0}`],
        ['Henkilöstömäärän muutos',staffChange],
        ['Riskit',<>{risks}</>],
    ] as [string,string][]
     

    return(<DuetCard
        heading="Kyselyn vastaukset"
    >
        <DuetList variation="striped" labelWidth='50'>
                {answers2.map(([label,value]) =>(
                    <DuetListItem>
                    <span slot="label">
                    {label}
                    </span>
                    <span slot="value">
                        {value}
                    </span>
                    </DuetListItem>
                ))}
        </DuetList>
        {/* <DuetLink url={`${BASE_URL}/api/admin/report?businessId=${businessId}`}>
            Lataa raportti PDF-muodossa
        </DuetLink> */}
    </DuetCard>)
}