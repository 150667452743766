import React, { useEffect, useRef } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { colorPrimary, colorPrimaryDark, colorSecondary, fontFamilyText, fontSizeMedium } from '@duetds/tokens';



type Datum = {
    year: string;
    value: number;
}

type Props = {
    title: string;
    data: Datum[]
}

const compare=(a:Datum,b:Datum)=>{
    if(a.year<b.year) return -1
    if(a.year>b.year) return 1
    return 0
}
export function SalesAndProfit({title, data}: Props){
    const ref = useRef<HighchartsReact.RefObject>(null);
    useEffect(() => {
        window.customElements.whenDefined('duet-card').then(() => {
            // console.log('duet-card is defined ')
            document.querySelector('duet-card')?.componentOnReady()?.then(() => {
                if (ref !== null) {
                    ref.current?.chart.reflow();
                };
            })
        })
    }, []);

    const options: Highcharts.Options = {
        credits:{
            enabled: false,
        },
        plotOptions:{
            column: {
                grouping: false,
                shadow: false
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: data.sort(compare).map(({ year })=>year),
            title:{
                text:'Vuosi',
            style: {
                color: colorSecondary,
                fontSize: fontSizeMedium,
                fontFamily: fontFamilyText,
            }
        },
            labels:{
                style:{
                    fontFamily: fontFamilyText,
                    color: colorSecondary,
                    fontSize: fontSizeMedium
                }
            }
        },
        legend:{
            enabled: true,
            align: 'left',
        },
        // TODO: yAxis titles needed / style?
        yAxis: {
            title: {
                text: title,
                style: {
                    fontSize: fontSizeMedium,
                    fontFamily: fontFamilyText,
                    color: colorSecondary,
            }
        },
        labels:{
            style:{
                fontFamily: fontFamilyText,
                fontSize: fontSizeMedium,
                color: colorSecondary,
                }
            }
        },
        series: [{
            type: 'column',
                name: title,
        color: colorPrimary,
            data: data.sort(compare).map(({value})=>value),
        }],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={ref}
    />
    )
}