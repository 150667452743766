// @ts-check
import React from "react";
import { DuetHeader } from "@duetds/react/commonjs";

const contactItems = [
  // { label: "Lähetä viesti", href: "#" },
 //  { label: "Avaa chat", href: "#" },
  { label: "Yhteystiedot", href: "#" },
];

type Props = {
  contact?: string;
  region?: string;
}

export default function Header(props: Props) {
  return (
    <DuetHeader
      logoHref="https://lahitapiola.fi/yritys"
      contactItems={contactItems}
      {...props}
    />
  );
}
