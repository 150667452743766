import React from "react";
import { DuetCard } from "@duetds/react";
import { DuetInput, DuetParagraph } from "@duetds/react/commonjs";
import { useStateValue } from "../../state/state";
import { validateEmail } from "../../utils/utils";

type Props = {
    email: string;
    setEmail: (email: string) => void;
    errors: boolean;
}

export default function Contact({ email, setEmail, errors }: Props) {
    const [state] = useStateValue();
    const handleDuetChange = ((event: any) => {
        setEmail(event.detail.value)
    })

    const inputEmail = state.identity.inputEmail ?? '';
    const inputEmailError = (errors && inputEmail && !validateEmail(inputEmail)) ? 'Virheellinen sähköpostiosoite' : '';

    return (
        <DuetCard padding="large" className="questionnaire-card" margin="none" heading="Yhteistiedot tulosten lähettämistä varten" headingLevel="h5">
            <DuetParagraph size="small">
                Lähetämme kyselyn tulokset osoitteeseen {email}.
            </DuetParagraph>
            <DuetParagraph size="small">
                Jos haluat vastaan ottaa kyselyn tulokset johonkin
                muuhun sähköpostiosoitteeseen, kirjoita alla olevaan
                kenttään haluamasi sähköpostiosoite.
            </DuetParagraph>
            <DuetInput
                onDuetChange={handleDuetChange}
                value={state.identity.inputEmail ?? ''}
                label="Sähköpostiosoite" expand
                name="email"
                type="email"
                placeholder="nimi@email.com"
                error={inputEmailError}
            />
        </DuetCard>
    );
}