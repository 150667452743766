import { useEffect, useState } from "react";
import useScreenOrientation from "./useScreenOrientation";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{ [key: string]: number | undefined }>({
        width: undefined,
        height: undefined,
    });
    const orientation = useScreenOrientation();

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        handleResize();
    }, [orientation])

    const isMobile = windowSize.width && windowSize.width < 800;
    const isPad = windowSize.width && windowSize.width >= 800 && windowSize.width < 1200;
    const isDesktop = windowSize.width && windowSize.width >= 1200;
    const screenWidth = windowSize.width ?? 0;

    return { isMobile, isPad, isDesktop, screenWidth };
};

export default useWindowSize;