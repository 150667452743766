import { DuetButton, DuetCheckbox, DuetDivider, DuetGrid, DuetGridItem, DuetHeading, DuetInput, DuetSelect, DuetSpacer, DuetStep } from '@duetds/react'
import React, { useState } from 'react'
import { Rule, RuleType, isNumberInput, SubRule, NumberInput, CheckBoxInput, } from './types'
import { SubRuleList } from './utils'


const selectOptions = SubRuleList.map(({name, text })=>({label: text, value: name })) as {label: string, value: string}[]

type Props = {
    selectStep: ()=>void
    next?: ()=>void
    rules?: Rule[]
    removeRule: (rule:Rule | SubRule)=>void
    reload: ()=> void;
    save: ()=> void;
    onInputChange:(input:any)=>(event:any)=>void
    addOrBlock?: (subrule?:SubRule)=>void
    addSubRule: (a:Rule)=>(b?:SubRule)=>()=>void//(rule:Rule)=>(subRule?:SubRule)=>void
    changeInput: (input: NumberInput |CheckBoxInput, value: string | boolean |null)=>void
    newRule?: boolean
}

export function RuleConnector(){

    return(
        <div className="ruleccc">
        <div className="line"></div>
        <div className="rule-or">
            TAI
        </div>
        <div className="line"></div>
    </div>
    )
}

export function RuleAddConnector(){

    return(
        <div className="rule-and__container">
        <div className="line-vertical"></div>
        <div className="rule-and">
            JA
        </div>
        <div className="line-vertical"></div>
    </div>
    )
}

type RuleBlockPros = {
    rule: Rule;
}
export function RuleBlock({rule}:RuleBlockPros){

    const normal = rule.name !== 'or';
    return(<>
        {normal ?   <div>Normal</div> : <div>or</div>}
        </>
    )
}
type OrBlockProps={
    rule: Rule;
    removeSubRule: (rule:Rule|SubRule)=>void;
    onInputChange: (a:any)=>(b:any)=>void;
    addSubRule: (subRule?:SubRule)=>()=>void
    changeInput: (input: NumberInput |CheckBoxInput, value: string | boolean |null)=>void
}

const m={
    min: 'Minimi',
    max: 'Maksimi'
}
export function OrBlock({rule, removeSubRule, onInputChange, addSubRule, changeInput}:OrBlockProps){
    const lastSubRule = rule.subRules.length -1

    const [selector, setSelector] = useState(false)
    const [selectedRule,setSelectedRule] = useState<string|undefined>(undefined)

    const handleClick=()=>{
        setSelector(true)
        //addSubRule()()
    }
    const handleChange=(event:CustomEvent<any>)=>setSelectedRule(event.detail.value);

    const add=()=>{
        const rule = SubRuleList.find(r=>r.name===selectedRule);
        if(rule){
            addSubRule(rule)()
            setSelector(false)
            setSelectedRule(undefined)
        }
        return
    }

    const inputChange=(input:NumberInput|CheckBoxInput)=>(event:any)=>{
        if(isNumberInput(input)){
            changeInput(input,event.detail.value);
        }else{
            changeInput(input,event.detail.checked);
        }
    }
    return(
        <>
        {rule.subRules.map((subRule,i) => {
            const {name, text, inputs} = subRule
            return(<div className="rule paddig">
            <DuetGrid distribution="space-between">
            <DuetGridItem>
            <DuetHeading level='h5'>{text}</DuetHeading>
            </DuetGridItem>
            <DuetGridItem>
            <DuetButton variation="destructive-secondary" size="small" onClick={()=>removeSubRule(subRule)}>Poista sääntö</DuetButton>
            </DuetGridItem>
            </DuetGrid>
                {inputs.map((input,i) => {
                    if(isNumberInput(input)){
                        return <DuetInput key={`${i}`} label={m[input.name]} expand value={input.value} onDuetChange={inputChange(input)}/>
                    }else{
                        return <DuetCheckbox key={`${i}`} label={input.name} checked={!!input.value} onDuetChange={inputChange(input)}/>
                    }
                })}
            <DuetSpacer/>
            {i<lastSubRule && <RuleConnector/>}
            {i===lastSubRule && (<>
            {selector ? <><DuetSelect
                    label="Valitse sääntö"
                    items={selectOptions}
                    onDuetChange={handleChange}
        />
            <DuetButton variation='primary'  onClick={add} disabled={selectedRule===undefined}>Lisää</DuetButton>
            <DuetButton  variation='secondary' onClick={()=>{setSelector(false); setSelectedRule(undefined)}}>peruuta</DuetButton>
        </> :
            <DuetButton variation="secondary" size="small" onClick={handleClick}>Lisää TAI-ehto</DuetButton>
        }</>)}
        </div>
        )
        })}
        </>
    )
}

export function NormalBlock({rule, removeRule, onInputChange}:{rule:Rule, removeRule: ()=>void , onInputChange: (a:any)=>(b:any)=>void}){
    const {text, inputs} = rule;

    
    return(<>
    <div className="rule paddig">
            <DuetGrid distribution="space-between">
            <DuetGridItem>
            <DuetHeading>{text}</DuetHeading>
            </DuetGridItem>
            <DuetGridItem>
            <DuetButton variation="destructive-secondary" size="small" onClick={removeRule}>Poista sääntö</DuetButton>
            </DuetGridItem>
            </DuetGrid>
            {inputs.map((input,i) => {
                    if(isNumberInput(input)){
                        return <DuetInput key={`${i}`} label={m[input.name]} expand value={input.value} onDuetChange={onInputChange(input)}/>
                    }else{
                        return <DuetCheckbox key={`${i}`} label={input.name} checked={!!input.value} onDuetChange={onInputChange(input)} />
                    }
                })}
            <DuetSpacer/>
    </div>

    </>)
}
export default function FilterRules({newRule, selectStep, next, rules=[], removeRule, reload, save, onInputChange, addOrBlock,addSubRule, changeInput}:Props){

    const [selectedRule, setSelectedRule] = useState<string|undefined>(undefined);

    const selectChange=(event: CustomEvent<any>)=>{
        setSelectedRule(event.detail.value);
    }
    const addRule=()=>{
        const rule = SubRuleList.find(r=>r.name===selectedRule);
        if(rule){
            addOrBlock && addOrBlock(rule)
        }
    }

    
    return(
        <DuetStep
        heading="Suodatussäännöt"
        headingLevel="h3"
        onDuetStepClick={()=>selectStep()}
    > 
    <DuetSpacer size="xx-large" />
        <div className='rule-container'>
            {rules.map((rule,i)=> rule.name === 'or' ?
                <><OrBlock key={`${i}`} rule={rule} removeSubRule={removeRule} addSubRule={addSubRule(rule)} onInputChange={onInputChange} changeInput={changeInput}/> {rules.length -1  > i ? <RuleAddConnector/> : null} </>:
                <NormalBlock key={`${i}`} rule={rule} removeRule={()=>removeRule(rule)} onInputChange={onInputChange}/>)}
        </div>


        <DuetSpacer size="xx-large" />
        <DuetSelect
            label="Lisää uusi sääntö"
            items={selectOptions}
            onDuetChange={selectChange}
        />
        <DuetButton onClick={addRule}>Lisää sääntö</DuetButton>
        <DuetSpacer />
        <DuetButton variation="primary" onClick={save}>{newRule ? 'Lisää' : 'Tallenna' }</DuetButton>
        <DuetButton variation="secondary" onClick={reload}>Peruuta</DuetButton>
        </DuetStep>
    )
}