import React, { useEffect, useRef, useState } from "react";
import { DuetButton, DuetCard, DuetGrid, DuetGridItem, DuetHeading } from "@duetds/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useChartData from "../../hooks/useChartData";
import useWindowSize from "../../hooks/useWindowSize";
import { colorData12, colorPrimary, colorPrimaryDark, fontSizeMedium, fontWeightSemiBold, colorSuccess, colorDanger, fontSizeSmall, fontFamilyText, colorSecondary, colorPrimaryLight, colorData08 } from "@duetds/tokens"
import { DuetParagraph } from "@duetds/react/commonjs";
import { useStateValue } from "../../state/state";

type Data = {
    year: number | null,
    value: number | null,
};

type PredictionData = {
    value: number;
    unit: string;
} | null

type Props = {
    title?: string;
    name?: string
};

export default function ReportCard({ title="Liikevaihto", name = "Yritys"  }: Props) {
    const ref = useRef<HighchartsReact.RefObject>(null);
    const { isMobile, isPad } = useWindowSize();
    const [selectedTitle, setSelectedTitle] = useState(title)
    const [state] = useStateValue();

    const {
        currentYear, turnOverDataReport, turnOverPrediction, profitDataReport, profitPrediction,
        profitPercentageData, profitPercentagePrediction, staffData, staffPrediction,
        missingLastYear,
        lastFinances,
        turnOverEstimate,
        profitEstimate,
        profitPercentageEstimate,
        estimates
    } = useChartData();
    useEffect(() => {
        window.customElements.whenDefined('duet-card').then(() => {
            // console.log('duet-card is defined ')
            document.querySelector('duet-card')?.componentOnReady()?.then(() => {
                if (ref !== null) {
                    ref.current?.chart.reflow();
                };
            })
        })
    }, []);

    const reportDataTable: { [key: string]: [Data[], PredictionData, string] } = {
        'Liikevaihto': [turnOverDataReport, turnOverPrediction, 'Liikevaihto  €'],
        'Liiketulos': [profitDataReport, profitPrediction, 'Liiketulos  €'],
        'Liikevoittoprosentti': [profitPercentageData, profitPercentagePrediction, 'Liikevoitto  €'],
        'Henkilöstö': [staffData, staffPrediction, ''],
    };

    
    const predictionTable: { [key: string]: number | null | undefined } = {
        'Liikevaihto' : estimates.expectedTurnOver.calculated ?? turnOverEstimate,
        'Liiketulos' : estimates.expectedProfit.calculated ??  profitEstimate,
        'Liikevoittoprosentti': (profitPercentagePrediction?.value ?? 0) * 100,  //estimates.expectedProfit.percentage ?? profitPercentageEstimate, 
        'Henkilöstö': staffPrediction?.value ?? estimates.staff.amount
    }
    const tableTitle = title ?? selectedTitle as keyof typeof reportDataTable;

    const [data, prediction, sideTitle] = reportDataTable[tableTitle];
   
    const years = data ? data.map(({ year }) => String(year)) : []
    const columnData = (data && data.length > 0) ? data.map((datum) => datum.value) : [];

    const lastFinancesData = {
        'Liikevaihto': (lastFinances?.turnOver ?? null) ?? estimates.lastFinances?.turnOver ?? null,
        'Liiketulos': (lastFinances?.netIncome ?? null) ?? estimates.lastFinances?.netIncome ?? null,
        //'Liikevoittoprosentti': ((lastFinances?.netIncome !== undefined) && (lastFinances?.turnOver !== undefined) )  ? (lastFinances!.netIncome / lastFinances!.turnOver)*100 : null,
        
        'Liikevoittoprosentti': ((lastFinances?.netIncome !== undefined) && (lastFinances?.turnOver !== undefined) )  ? (lastFinances!.netIncome / lastFinances!.turnOver)*100 : null
        ?? ((estimates.lastFinances?.netIncome ?? 0 ) / (estimates.lastFinances?.turnOver ?? 1) * 100),
        'Henkilöstö':  state?.formAnswers?.lastFinances?.staff ?? state?.estimates?.lastFinances?.staff ?? null//(staffData[-1]?.value ?? null) ?? estimates.staff ??  null,
    }

    const f=(missing:boolean):any[]=>missing ? [
        {
            type:'column',
            name: `${name} (arvio)`,
            color: colorData12,
            //@ts-expect-error
            data: [null, null ,lastFinancesData[tableTitle] ?? null, predictionTable[title],columnData[2]]
        },
       
        {
            type: 'column',
            name: 'Toimiala',
            color: colorPrimaryDark,
            data: [null, null, null,null,Math.round(columnData.slice(-1)[0] ?? 0)]
        }

    ]:[
        {
            type: 'column',
            name: 'Toimiala',
            color: colorPrimaryDark,
            data: [null, null, null,Math.round(columnData.slice(-1)[0] ?? 0)]
        }
    ]

    const addMissingYear = (years:string[]):string[] => {  
        const temp:string[] = years.reduce((acc,year)=>{
            const lastYear=Number(acc.slice(-1)?.[0])
            if(Number.isInteger(lastYear) && Number(year)-lastYear > 1){
                
                return [...acc,`${Number(year) -1}`,year]
            }
            return [...acc, year]
        }, [] as string[])

        return temp
    }

    const options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            //TODO
            categories: missingLastYear ? [...addMissingYear(years),'toimiala'] : [...addMissingYear(years),'toimiala'] ,
            labels: {
                style: {
                    fontFamily: fontFamilyText,
                    fontSize: fontSizeMedium,
                    color: colorSecondary,
                },
            }
        },
        yAxis: {
            title: {
                text: sideTitle,
                style: {
                    fontFamily: fontFamilyText,
                    fontSize: fontSizeMedium,
                    color: colorSecondary,
                },
            },
            labels: {
                style: {
                    fontFamily: fontFamilyText,
                    fontSize: fontSizeMedium,
                    color: colorSecondary, //'#00294d',
                }
            }
        },
        plotOptions: {
            series: {
                allowPointSelect: true
            },
            column: {
                grouping: false,
                shadow: false
            }
        },
        legend: {
            enabled: true,
            align: 'left',
        },
        series: [{
            type: 'column',
            name: name,
            color: colorPrimary,
            data: missingLastYear ? [...columnData.slice(0, 2), null,null,null] : [...columnData.slice(0, 2), null],
        },
       /* {
            type: 'column',
            name: `${name} (arvio)`,
            color: colorData12,
            data: [null, null, predictionTable[title]],
            // data: [null, null, columnData[2]],

        },
        /*{
            type:'column',
            visible: false,
            name: `${name} (arvio)`,
            color: colorPrimaryLight,
            data: [null, null ,10e5,null]
        },
        {
            type: 'column',
            name: 'Toimiala',
            color: colorPrimaryDark,
            data: missingLastYear ? [null, null, null,null,Math.round(columnData.slice(-1)[0] ?? 0)] : [null, null,null,Math.round(columnData.slice(-1)[0] ?? 0)],
        }*/
        ...f(missingLastYear)
    
    ]

    };

    const Prediction = () => {
        if (!prediction) return null;

        const { value, unit } = prediction;
        const displayUnit = unit === 'henkilö' ? ` ${unit}${Math.abs(value) !== 1 ? 'ä' : ''}` : unit;

        const fontStyles = {
            fontSize: isPad ? fontSizeMedium : fontSizeSmall,
            fontWeight: fontWeightSemiBold,
        };

        return (
            <DuetGridItem>
                <DuetParagraph style={fontStyles}>
                    <span style={fontStyles}> Ennuste {currentYear}</span>
                    {value === 0
                        ? <span style={{ color: colorPrimary, marginRight: '1rem', ...fontStyles }}>{` ${value}${displayUnit}, ei muutettu`}</span>
                        : <span style={{ color: value > 0 ? colorSuccess : colorDanger, marginRight: '1rem', ...fontStyles }}>{` ${value > 0 ? '' : ''}${Math.round(value * (displayUnit==='%' ? 100 : 1))}${displayUnit}`}</span>}
                </DuetParagraph>
            </DuetGridItem>
        )
    };

    return (
        <DuetCard padding="none" className="report-card" variation="plain">
            {isMobile && Object.keys(reportDataTable).map((key) => (
                <DuetButton
                    key={key}
                    variation={key === selectedTitle ? 'primary' : 'secondary'}
                    onClick={() => {
                        setSelectedTitle(key);
                    }}
                    size="small"
                    className="mobile-toggle-buttons"
                >
                    {key}
                </DuetButton>
            ))}
            <DuetGrid distribution="space-between">
                <DuetGridItem>
                    <DuetHeading level="h5">{title}</DuetHeading>
                </DuetGridItem>
                {!isMobile && <Prediction />}
            </DuetGrid>
            {isMobile && <Prediction />}
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={ref}
            />
        </DuetCard>
    );
}