import { DuetButton, DuetCard, DuetGrid, DuetGridItem, DuetHeading, DuetSpacer } from "@duetds/react"
import { DuetParagraph } from "@duetds/react/commonjs";
import React from "react"

type Props={
    name: string;
    created: Date;
    remove?: () => void;
    edit?: () => void;
}
export default function Filter({name, created, remove, edit}:Props){
    return(
        <DuetCard margin="auto"  padding="x-small">
            <DuetGrid  alignment="center" responsive breakpoint="medium">
                <DuetGridItem margin="none" fill>
                    <DuetHeading level="h4" margin="none"> {name}</DuetHeading>
                    <DuetGrid style={{marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto'}}>
                        <DuetGridItem >
                            <DuetHeading level="h5" margin="none"  >Luotu</DuetHeading>
                    </DuetGridItem>
                    <DuetGridItem margin="none">
                        <DuetParagraph>{created.toLocaleDateString('fi-Fi')}</DuetParagraph>
                    </DuetGridItem>
                    </DuetGrid>
            </DuetGridItem >
            <DuetGridItem margin="none">
                <DuetGrid distribution="space-between" responsive>
                    <DuetGridItem margin="none">
                        <DuetButton margin="none" variation="primary"  onClick={edit} >Muokkaa</DuetButton>
                    </DuetGridItem>
                    <DuetGridItem>
                        <DuetSpacer size="xx-small" />
                    </DuetGridItem>
                    <DuetGridItem margin="none">
                        <DuetButton margin="none" variation="destructive-secondary"  onClick={remove}>Poista</DuetButton>
                    </DuetGridItem>
                </DuetGrid>
                </DuetGridItem>
            </DuetGrid>
        </DuetCard>
    )
}