import _ from "lodash";
import { NewsLeadsTarget } from "../types/state";

export const getTarget = (targetGroupData: { data: NewsLeadsTarget[] }, industryCode: string) => {
    let target = _.find(targetGroupData.data, (tg) => {
        const nums = tg.name.replace(/\D/g, '');
        const numberToTwoNumSequences = industryCode && industryCode.match(/.{1,2}/g);
        const letrs = tg.name.replace(/\d+/g, '');

        const targetGroupMatches = numberToTwoNumSequences && numberToTwoNumSequences.some((n: any) => {
            if (n.length === 1) {
                return false;
            }
            return nums.includes(n);
        });

        return targetGroupMatches || (letrs && industryCode.includes(letrs));
    });

    if (!target) {
        target = _.find(targetGroupData.data, (tg) => {
            return tg.name === 'Muu';
        });
    }

    return target as NewsLeadsTarget; // TODO: fix types, target might still be undefined
};

export const validateEmail = (email: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email);